import { useSelector } from "react-redux";
import { DataGrid, Modal } from "../../components";
import {
  getLocalizedErrors,
  getLocalizedSliders,
} from "../../redux/selectors/language-selectors";
import { languagesSelector } from "../../redux/selectors/system-selectors";
import { SliderDetailModel } from "../../types/models/sliders/slider-object";
import { getLanguageDtosValues } from "../../utils/helpers";
import {
  addSliderUrl,
  deleteSliderUrl,
  getSliderDetailsUrl,
  getSlidersUrl,
  updateSliderUrl,
} from "../../utils/urls";

import CreateForm from "./create";
import EditForm from "./edit";
import DetailsForm from "./details";
import { useRef, useState } from "react";
import { GroupItem, RequiredRule, SimpleItem } from "devextreme-react/form";
import { Form } from "devextreme-react/data-grid";

function SlidersPage() {
  const strings = useSelector(getLocalizedSliders);
  const languages = useSelector(languagesSelector);
  const [visible, setVisible] = useState(false);
  const errorStrings = useSelector(getLocalizedErrors);
  const dataRef = useRef<{
    value: number;
  }>({ value: 23 });
  const columns = [
    {
      dataField: "title",
      caption: strings.getString("title"),
    },
    {
      dataField: "description",
      caption: strings.getString("description"),
    },
  ];

  return (
    <>
      <Modal
        visible={visible}
        onHiding={() => setVisible(false)}
        title={strings.getString("setTime")}
        height={"50%"}
        validationGroup="sliderTimeValidation"
        buttonTitle={strings.getString("add")}
        onSubmitClick={() => console.log()}
      >
        <Form
          labelLocation="top"
          formData={dataRef.current}
          width={"100%"}
          height={"100%"}
          showColonAfterLabel={false}
          validationGroup="sliderTimeValidation"
        >
          <GroupItem>
            <SimpleItem
              editorType={"dxNumberBox"}
              dataField={"value"}
              label={{
                text: strings.getString("time"),
              }}
            >
              <RequiredRule message={errorStrings.getString("required")} />
            </SimpleItem>
          </GroupItem>
        </Form>
      </Modal>
      <DataGrid
        gridButtons={[
          {
            buttonText: strings.getString("setTime"),
            icon: "edit",
            type: "default",
            onclick: () => setVisible(true),
          },
        ]}
        url={getSlidersUrl}
        columns={columns || []}
        refObject={
          {
            SliderImage: "",
            SliderTranslates: getLanguageDtosValues(languages),
          } as SliderDetailModel
        }
        details={{
          height: "65%",
          getByIdUrl: getSliderDetailsUrl,
          title: strings.getString("slider_details"),
          component: DetailsForm,
        }}
        edit={{
          height: "65%",
          editUrl: updateSliderUrl,
          getByIdUrl: getSliderDetailsUrl,
          title: strings.getString("edit_slider"),
          component: EditForm,
          validationGroup: "sliderEditFormValidation",
        }}
        create={{
          height: "65%",
          createUrl: addSliderUrl,
          title: strings.getString("add_slider"),
          component: CreateForm,
          validationGroup: "sliderCreateFormValidation",
        }}
        remove={{ url: deleteSliderUrl }}
      />
    </>
  );
}
export default SlidersPage;
