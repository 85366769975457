import { useRef, useEffect, useCallback } from "react";
import "./style.scss";

type MapsSearchBoxProps = {
  maps: any;
  onPlacesChanged: any;
  placeholder: any;
};

const MapsSearchBox = ({
  maps,
  onPlacesChanged,
  placeholder,
}: MapsSearchBoxProps) => {
  const input = useRef(null);
  const searchBox = useRef(null);

  const handleOnPlacesChanged = useCallback(() => {
    if (onPlacesChanged) {
      // @ts-ignore
      onPlacesChanged(searchBox.current.getPlaces());
    }
  }, [onPlacesChanged, searchBox]);

  useEffect(() => {
    if (!searchBox.current && maps) {
      searchBox.current = new maps.places.SearchBox(input.current);
      // @ts-ignore
      searchBox.current.addListener("places_changed", handleOnPlacesChanged);
    }

    return () => {
      if (maps) {
        searchBox.current = null;
        maps.event.clearInstanceListeners(searchBox);
      }
    };
  }, [maps, handleOnPlacesChanged]);

  return (
    <input
      className="map-search-box-input"
      ref={input}
      placeholder={placeholder}
      type="text"
    />
  );
};

export default MapsSearchBox;
