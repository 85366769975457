import Form, { GroupItem, SimpleItem } from "devextreme-react/form";
import { SliderDetailModel } from "../../types/models/sliders/slider-object";
import { useSelector } from "react-redux";
import { getLocalizedSliders } from "../../redux/selectors/language-selectors";
import { disabled } from "../../utils/helpers";
import { MultiLanguageInput, Modal } from "../../components";
import { imageUrl } from "../../utils/urls";
import { useState } from "react";

const EditForm = ({ data }: { data: SliderDetailModel }) => {
  const strings = useSelector(getLocalizedSliders);
  const [showImage, setShowImage] = useState<boolean>(false);
  return (
    <form>
      <Form
        key="id"
        labelLocation="top"
        formData={data}
        showColonAfterLabel={true}
        validationGroup="editionFormValidation"
      >
        <GroupItem>
          <SimpleItem
            dataField="SliderImage"
            label={{
              text: strings.getString("slider_image"),
            }}
            editorOptions={disabled}
          >
            <div className="centerer-div">
              <img
                src={imageUrl + data.SliderImage}
                width="50%"
                style={{ cursor: "pointer" }}
                alt=""
                onClick={() => setShowImage(true)}
              />
            </div>
            <Modal
              visible={showImage}
              onHiding={() => setShowImage(false)}
              title={strings.getString("slider_image")}
              height={"80%"}
              width={"95%"}
              onSubmitClick={() => console.log("")}
              showButton={false}
              coverModal
            >
              <div className="centerer-div full-element">
                <img
                  src={imageUrl + "/" + data.SliderImage}
                  className="image-style"
                  alt=""
                />
              </div>
            </Modal>
          </SimpleItem>
          <SimpleItem>
            <MultiLanguageInput
              disabled
              data={data}
              languageObject={{
                mainKey: "SliderTranslates",
                objectKeys: [
                  { title: strings.getString("title"), key: "Title" },
                  {
                    title: strings.getString("description"),
                    key: "Description",
                  },
                ],
              }}
            />
          </SimpleItem>
        </GroupItem>
      </Form>
    </form>
  );
};

export default EditForm;
