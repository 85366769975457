import { Method } from "axios";
import notify from "devextreme/ui/notify";
import { BodyType, createRequest } from "../redux/actions";
import { ApiResponse, verfiyResponse } from "../types/models/base-model";

class BaseService {
  sendRequest = async (
    url: string,
    method: Method = "GET",
    token: string,
    language: string,
    data?: {},
    bodyType?: BodyType
  ) => {
    const response: ApiResponse = await createRequest({
      url,
      method,
      token,
      lang: language,
      body: data,
      bodyType,
    });
    if (verfiyResponse(response)) return response.Result;
    else notify(response.Message, "error");
  };
  sendRequestWithNotify = async (
    url: string,
    method: Method = "GET",
    token: string,
    language: string,
    data?: {},
    bodyType?: BodyType
  ) => {
    const response: ApiResponse = await createRequest({
      url,
      method,
      token,
      lang: language,
      body: data,
      bodyType,
    });
    if (verfiyResponse(response)) return notify(response.Message, "success");
    else notify(response.Message, "error");
  };
  sendRequestApiResponse = async (
    url: string,
    method: Method = "GET",
    token: string,
    language: string,
    data?: {},
    bodyType?: BodyType
  ) => {
    const response: ApiResponse = await createRequest({
      url,
      method,
      token,
      lang: language,
      body: data,
      bodyType,
    });
    if (verfiyResponse(response)) return response;
    else notify(response.Message, "error");
  };
}

export default BaseService;
