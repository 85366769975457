import { forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import Form, {
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from "devextreme-react/form";
import { ITextBoxOptions } from "devextreme-react/text-box";

import { languagesSelector } from "../../redux/selectors/system-selectors";
import { Button } from "devextreme-react";
import {
  getLocalizedComponents,
  getLocalizedErrors,
} from "../../redux/selectors/language-selectors";
import { textEditorOptions } from "../../utils/helpers";

type MultiLanguageInputProps = {
  data: any;
  languageObject: {
    mainKey: string;
    objectKeys: {
      title: string;
      key: string;
      editorType?: string;
      required?: boolean;
    }[];
  };
  disabled?: boolean;
  validationGroup?: string;
  getLanguageObject?: any;
};

const MultiLanguageInput = forwardRef<any, MultiLanguageInputProps>(
  (
    {
      data,
      languageObject,
      disabled = false,
      validationGroup,
      getLanguageObject,
    },
    ref
  ) => {
    const strings = useSelector(getLocalizedComponents);
    const languages = useSelector(languagesSelector);
    const [multiLanguage, setMultiLanguage] = useState<boolean>(false);
    const errorStrings = useSelector(getLocalizedErrors);

    // useEffect(() => {
    //   return () => {
    //     data[languageObject.mainKey] = getLanguageDtosValues<Type>(
    //       languages,
    //       getLanguageObject
    //     );
    //   };
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
      <Form
        ref={ref}
        key="id"
        labelLocation="top"
        formData={data}
        showColonAfterLabel={true}
        validationGroup={validationGroup}
      >
        {languageObject.objectKeys.map((keyObject, i) => (
          <SimpleItem
            key={i}
            dataField=""
            colSpan={2}
            label={{
              text: keyObject.title,
            }}
            visible={!multiLanguage}
            editorType={keyObject.editorType}
            editorOptions={
              keyObject.editorType === "dxHtmlEditor"
                ? ({
                    disabled,
                    ...textEditorOptions,
                    value: data[languageObject.mainKey][0][keyObject.key],
                    onValueChanged: (e) =>
                      data[languageObject.mainKey].forEach(
                        (language: any, i: number) => {
                          data[languageObject.mainKey][i][keyObject.key] =
                            e.value;
                        }
                      ),
                  } as ITextBoxOptions)
                : ({
                    disabled,
                    value: data[languageObject.mainKey][0][keyObject.key],
                    onValueChanged: (e) =>
                      data[languageObject.mainKey].forEach(
                        (language: any, i: number) => {
                          data[languageObject.mainKey][i][keyObject.key] =
                            e.value;
                        }
                      ),
                  } as ITextBoxOptions)
            }
          >
            {keyObject.required && (
              <RequiredRule message={errorStrings.getString("required")} />
            )}
          </SimpleItem>
        ))}
        <TabbedItem visible={multiLanguage}>
          <TabPanelOptions deferRendering={false} animationEnabled />
          {languages?.map((language) => {
            let findIndex: number;
            if (data)
              findIndex = data[languageObject.mainKey].findIndex(
                (translate: any) =>
                  translate.LanguageID.toUpperCase() ===
                  language.guid.toUpperCase()
              );
            else findIndex = -1;
            if (findIndex > -1) {
              return (
                <Tab key={language?.guid} title={language?.language}>
                  {languageObject.objectKeys.map((keyObject, i) => (
                    <SimpleItem
                      key={i}
                      dataField={`${languageObject.mainKey}[${findIndex}.${keyObject.key}]`}
                      label={{
                        text: keyObject.title,
                      }}
                      editorType={keyObject.editorType}
                      editorOptions={
                        keyObject.editorType === "dxHtmlEditor"
                          ? { disabled, ...textEditorOptions }
                          : { disabled }
                      }
                    >
                      {keyObject.required && (
                        <RequiredRule
                          message={errorStrings.getString("required")}
                        />
                      )}
                    </SimpleItem>
                  ))}
                </Tab>
              );
            } else return <Tab key={language.guid} />;
          })}
        </TabbedItem>
        <SimpleItem>
          <div style={{ textAlign: "end" }}>
            <Button
              stylingMode="text"
              onClick={() => setMultiLanguage(!multiLanguage)}
            >
              {multiLanguage
                ? strings.getString("customize_for_single")
                : strings.getString("customize_for_multi")}
            </Button>
          </div>
        </SimpleItem>
      </Form>
    );
  }
);
export default MultiLanguageInput;
