import { LoadPanel, ScrollView } from "devextreme-react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { images } from "../../assets";
import "./style.scss";
import moment from "moment";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import {
  getApiParams,
  userSelector,
} from "../../redux/selectors/auth-selectors";
import { useSelector } from "react-redux";

import "./chat.scss";
import {
  ChatDto,
  VehicleChatMessageReceiveObject,
  VehicleChatSendObject,
  ContractMessage,
  formatChatMessageReceive,
  VehicleUserInfoDto,
  VehicleOpportunityDto,
} from "./type";
import BaseService from "../../services";
import { color } from "../../utils/default-user";
import { chatUrl } from "../../utils/urls";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { getLocalizedAuth } from "../../redux/selectors/language-selectors";
import ChatCard from "./chat-card";
const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};

const HUB_ENDPOINT = chatUrl + "api/vehiclechat";
const VehicleChat = () => {
  const strings = useSelector(getLocalizedAuth);
  const baseService = new BaseService();
  const { token, lang } = useSelector(getApiParams);
  const search = useLocation().search;
  console.log(search, "search");
  const [list, setList] = useState<ChatDto[]>([]);
  const [opportunity, setOpportunity] = useState<VehicleOpportunityDto>();
  const [userInfo, setUserInfo] = useState<VehicleUserInfoDto>();
  const [loader, setLoader] = useState(false);
  const chatActiveRef = useRef(false);
  const [selected, setSelected] = useState<ChatDto>();
  const [messages, setMessages] = useState<ContractMessage[]>();
  const user = useSelector(userSelector);
  const connectionRef = useRef<HubConnection | null>(null);
  const history = useHistory();
  const location = useLocation();
  const [connectionStatus, setConnectionStatus] = useState<
    "offline" | "connecting" | "connected" | "failed"
  >("offline");
  const getList = async () => {
    //@ts-ignore
    var result: ChatDto[] = await baseService.sendRequest(
      "Portal/GetVehicleChats",
      "GET",
      token,
      lang
    );
    if (result) {
      setList(result);
      if (search) {
        const id = search.replace("?chatId=", "");
        const destinationId = id.includes("&&refresh")
          ? id.replace("&&refresh", "")
          : id;
        const current = result.find(
          (x) => x.vehicle_opp_destination__c === destinationId
        );
        if (current) {
          var currenctChat = await baseService.sendRequestApiResponse(
            "Portal/GetVehicleChats/" + current.id,
            "GET",
            token,
            lang
          );
          //@ts-ignore
          if (currenctChat?.SubResult && currenctChat?.SubResult.Truck) {
            //@ts-ignore
            setOpportunity(currenctChat.SubResult.Truck);
          }
          //@ts-ignore
          if (currenctChat?.SubResult && currenctChat?.SubResult.UserInfo) {
            //@ts-ignore
            setUserInfo(currenctChat.SubResult.UserInfo);
          }
          setSelected(current);
          setMessages(JSON.parse(current.messagejson__c));
          connectSignal(current.id, current);
        }
      }
    }
  };
  const getChatList = async () => {
    //@ts-ignore
    var result: ChatDto[] = await baseService.sendRequest(
      "Portal/GetVehicleChats",
      "GET",
      token,
      lang
    );
    if (result) {
      setList(result);
    }
  };
  const getChatListEffect = async () => {
    console.log(location.search.includes("refresh"), "11111111111111");
    if (location.search.includes("refresh")) {
      //@ts-ignore
      var result: ChatDto[] = await baseService.sendRequest(
        "Portal/GetVehicleChats",
        "GET",
        token,
        lang
      );
      if (result) {
        setList(result);
        const search = location.search;
        search.includes("chatId")
          ? history.push("vehiclechat" + search.replace("&&refresh", ""))
          : history.push("vehiclechat");
      }
    }
  };
  useEffect(() => {
    if (location.search.includes("refresh")) {
      getChatListEffect();
    }
    // eslint-disable-next-line
  }, [location]);
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, []);

  const connectSignal = (id: string, current: ChatDto) => {
    console.log("CHATACTIVE START", chatActiveRef.current);
    if (connectionRef.current !== undefined && token && lang && current) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(HUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => token,
        })
        .configureLogging(LogLevel.Debug)
        .build();
      connection.serverTimeoutInMilliseconds = 3600000;
      console.log(`Trying to connect to ${HUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`CHAT Connected to ${HUB_ENDPOINT}`);
          setConnectionStatus("connected");
          connectionRef.current = connection;
          chatActiveRef.current = false;
          setLoader(false);
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(`Disconnected from ${HUB_ENDPOINT}`);
        // console.log("CHATACTIVE", chatActiveRef.current);
        // if (!chatActiveRef.current) {
        //   console.log(`Trying connect from ${HUB_ENDPOINT}`);
        //   connectSignal(id, current);
        // }
      });
      connection.on(
        current.vehicle_opp_destination__c + current.contact__c,
        function (payload: VehicleChatMessageReceiveObject) {
          console.log("ReceiveMessage", id, payload);
          setMessages((state) => [
            //@ts-ignore
            ...state,
            formatChatMessageReceive(payload),
          ]);
        }
      );
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
  };
  const onMessageSend = async (
    values: any,
    { resetForm }: { resetForm: any }
  ) => {
    if (connectionRef.current && selected) {
      try {
        const serverObject: VehicleChatSendObject = {
          vehicle_opp_destination__c: selected.vehicle_opp_destination__c,
          contact__c: selected.contact__c,
          user__c: selected.user__c,
          Message: values.message,
          id: selected.id,
        };
        const result = await connectionRef.current.invoke(
          "SendMessage",
          serverObject
        );
        console.log("SendMessage", result);
        resetForm();
      } catch (err: any) {
        console.log(
          `Error sending ${values.message} from${user?.NameSurname} : ${err.message} ${connectionStatus}`
        );
      }
    }
  };
  const returnUserName = () => {
    if (messages) {
      const lastMessageFilter = messages.filter(
        (x) => x.UserId !== user?.AdvisorId
      );
      const lastMessageOwner = lastMessageFilter[lastMessageFilter.length - 1];
      return lastMessageOwner.UserName;
    } else {
      return "";
    }
  };
  return (
    <div>
      <div className="chat">
        <div className="chat-list-outer-div">
          <div className="chat-list-header">
            <img src={images.profile5} width={45} height={45} alt="" />
            <p>
              <p>Rapid Admin Chat</p>
              <p style={{ color: "#74788d7a" }}>{user?.NameSurname}</p>
            </p>
          </div>
          <div className="chat-title">
            <p className="chat-title-nav">Chat</p>
          </div>
          <div style={{ width: "100%" }}>
            <ScrollView className="scroll-list">
              <ul className="list-unstyled chat-list">
                {list.map((chat) => {
                  let messages: ContractMessage[] = JSON.parse(
                    chat.messagejson__c
                  );
                  const lastMessageFilter = messages.filter(
                    (x) => x.UserId !== user?.AdvisorId
                  );
                  const lastMessage = messages[messages.length - 1];
                  const lastMessageOwner =
                    lastMessageFilter[lastMessageFilter.length - 1];
                  return (
                    <li
                      key={chat.id}
                      onClick={async () => {
                        getChatList();
                        chatActiveRef.current = true;
                        history.push(
                          "?chatId=" + chat.vehicle_opp_destination__c
                        );
                        setLoader(true);
                        try {
                          connectionRef.current?.stop().then(() => {
                            console.log("CONNECTION STOPPED", selected?.id);
                            connectionRef.current = null;
                          });
                        } catch (e) {
                          console.log("ERROR UNMOUNT", e);
                        }
                        var result = await baseService.sendRequestApiResponse(
                          "Portal/GetVehicleChats/" + chat.id,
                          "GET",
                          token,
                          lang
                        ); //@ts-ignore
                        if (result?.SubResult && result?.SubResult.Truck) {
                          //@ts-ignore
                          setOpportunity(result.SubResult.Truck);
                        }
                        if (
                          result?.SubResult &&
                          //@ts-ignore
                          result?.SubResult.UserInfo
                        ) {
                          //@ts-ignore
                          setUserInfo(result.SubResult.UserInfo);
                        }
                        setSelected(chat);
                        setMessages(
                          //@ts-ignore
                          JSON.parse(result?.Result[0].messagejson__c)
                        );
                        connectSignal(chat.id, chat);
                      }}
                      className={selected?.id === chat.id ? "active-item" : ""}
                    >
                      <Link className="link" to="#">
                        <div className="chat-card-outer">
                          <div className="chat-card-img flex-shrink-0 user-img online align-self-center me-3">
                            <img
                              src={images.profile1}
                              className="avatar-sm"
                              alt=""
                              style={{ marginBlockStart: 3 }}
                            />
                          </div>
                          <div className="chat-card-info flex-grow-1 overflow-hidden">
                            <h5
                              className="text-truncate"
                              style={{ fontSize: 12, fontWeight: 600 }}
                            >
                              {chat.destinationname
                                ? chat.destinationname
                                : "-"}{" "}
                              / {lastMessageOwner?.UserName}
                            </h5>

                            <p
                              className="text-truncate"
                              style={{ fontSize: 11, color: "#7a788d" }}
                            >
                              {lastMessage?.Message}
                              {!lastMessage.Read &&
                                selected?.id !== chat.id &&
                                lastMessage.UserId !== user?.AdvisorId && (
                                  <>
                                    <br />
                                    <span
                                      style={{
                                        backgroundColor: " #37A75B",
                                        color: "white",
                                        paddingInline: 3,
                                        border: "1px solid #37A75B",
                                        borderRadius: 6,
                                      }}
                                    >
                                      {strings.getString("New")}
                                    </span>
                                  </>
                                )}
                            </p>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: 12,
                              color: "#7a788d",
                            }}
                          >
                            {moment(lastMessage?.CreateTime).format(
                              "DD/MM/YY HH:mm:ss"
                            )}
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </ScrollView>
          </div>
        </div>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          onHiding={() => setLoader(false)}
          visible={loader}
        />
        {!loader && (
          <div className="chat-content">
            {selected ? (
              <>
                <div
                  style={{
                    borderBottom: "1px solid",
                    borderColor: color.bgSecondary,
                    padding: 5,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 700,
                  }}
                >
                  {opportunity && userInfo && selected && (
                    <ChatCard
                      chat={selected}
                      userInfo={userInfo}
                      opportunity={opportunity}
                      username={returnUserName()}
                    />
                  )}
                </div>
                <ScrollView
                  className="scroll-list-chat"
                  style={{ marginBlock: 10 }}
                >
                  <ul className="message-list">
                    {messages?.map((message, i) => {
                      let messagePosition =
                        message.UserId === user?.AdvisorId
                          ? "chatApp__convMessageItem--right"
                          : "chatApp__convMessageItem--left";
                      return (
                        <div style={{ marginBlock: 20 }}>
                          <div
                            className={
                              "chatApp__convMessageItem " +
                              messagePosition +
                              " clearfix"
                            }
                          >
                            <div className="chatApp__convMessageValue">
                              {message.Message}
                              <p
                                style={{
                                  width: "100%",
                                  textAlign: "right",
                                  fontSize: 10,
                                }}
                              >
                                {moment(message?.CreateTime).format(
                                  "DD/MM/YY HH:mm:ss"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </ul>
                  <AlwaysScrollToBottom />
                </ScrollView>
                <Formik
                  initialValues={{
                    message: "",
                  }}
                  onSubmit={onMessageSend}
                >
                  {({ handleSubmit, setFieldValue, values }) => {
                    return (
                      <>
                        <input
                          value={values.message}
                          type="text"
                          className={"chatApp__convInput"}
                          tabIndex={0}
                          onChange={(e) =>
                            setFieldValue("message", e.target.value)
                          }
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              handleSubmit();
                            }
                          }}
                        />
                        <div
                          className={"chatApp__convButton"}
                          onClick={(e) => {
                            handleSubmit();
                          }}
                        >
                          <img
                            src={images.send}
                            alt=""
                            width={10}
                            style={{ marginBlockStart: 4 }}
                          />
                        </div>
                      </>
                    );
                  }}
                </Formik>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {strings.getString("Select User to start Chat")}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default VehicleChat;
