import { call } from "@redux-saga/core/effects";
import { BodyType, createRequestSaga } from "./request";
import { ApiCallback, ApiResponse, verfiyResponse } from "../types/saga";

export interface PostSagaGenericParams<Type> {
  type: string;
  payload: ApiCallback<Type>;
  body: object;
  url: string;
  id?: string;
  bodyType?: any;
}
export function* postwithApiResponseSaga<Type>({
  body,
  payload,
  url,
}: PostSagaGenericParams<Type>) {
  //yield put(loader.actions.showLoader());
  try {
    const response: ApiResponse<any> = yield call(createRequestSaga, {
      method: "POST",
      url: url || "",
      body: body,
      bodyType: BodyType.formdata,
    });
    if (verfiyResponse(response))
      //@ts-ignore
      payload.onSuccess(response.Message, response);
    else {
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log("ERROR postSaga", e);
  } finally {
    //yield put(loader.actions.hideLoader());
  }
}
export function* postSaga<Type>({
  body,
  payload,
  url,
  bodyType,
}: PostSagaGenericParams<Type>) {
  // yield put(loader.actions.showLoader());
  try {
    const response2: ApiResponse<Type> = yield call(createRequestSaga, {
      method: "POST",
      url: url || "",
      body: body,
      bodyType: bodyType ? bodyType : BodyType.formdata,
    });
    //@ts-ignore
    const response = JSON.parse(response2);
    if (verfiyResponse(response))
      payload.onSuccess(response.Message, response.Result);
    else {
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log("ERROR postSaga", e);
  } finally {
    //   yield put(loader.actions.hideLoader());
  }
}

export function* putSaga<Type>({
  body,
  payload,
  url,
}: PostSagaGenericParams<Type>) {
  // yield put(loader.actions.showLoader());
  try {
    const response: ApiResponse<Type> = yield call(createRequestSaga, {
      method: "PUT",
      url: url || "",
      body: body,
      bodyType: BodyType.formdata,
    });
    if (verfiyResponse(response))
      payload.onSuccess(response.Message, response.Result);
    else {
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log("ERROR putSaga", e);
  } finally {
    // yield put(loader.actions.hideLoader());
  }
}
export function* putwithApiResponseSaga<Type>({
  body,
  payload,
  url,
}: PostSagaGenericParams<Type>) {
  //  yield put(loader.actions.showLoader());
  try {
    const response: ApiResponse<any> = yield call(createRequestSaga, {
      method: "PUT",
      url: url || "",
      body: body,
      bodyType: BodyType.formdata,
    });
    if (verfiyResponse(response))
      //@ts-ignore
      payload.onSuccess(response.Message, response);
    else {
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log("ERROR postSaga", e);
  } finally {
    //  yield put(loader.actions.hideLoader());
  }
}

export function* postSagaBodyRaw<Type>({
  body,
  payload,
  url,
}: PostSagaGenericParams<Type>) {
  // yield put(loader.actions.showLoader());
  try {
    const response2: ApiResponse<Type> = yield call(createRequestSaga, {
      method: "POST",
      url: url || "",
      body: body,
      bodyType: BodyType.raw,
    }); //@ts-ignore
    const response = JSON.parse(response2);

    if (verfiyResponse(response))
      payload.onSuccess(response.Message, response.Result);
    else {
      console.log(response, "ssssssss");
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log(1111, "ssssssss");
    console.log("ERROR postSaga", e);
  } finally {
    //   yield put(loader.actions.hideLoader());
  }
}
