import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import * as T from "./type";

export const getTenderChats = createAction(
  "GET_TENDER_CHATS",
  ({ payload }: SagaActionParams<T.TenderChatDto[]>) => ({
    payload,
    url: "/Portal/GetTenderChats",
  })
);
export const getTenderChatDetailswithSfid = createAction(
  "GET_TENDER_CHAT_DETAILS_WITH_SFID",
  ({ payload, id }: SagaActionParams<T.TenderChatDto[]>) => ({
    payload,
    url: "/Portal/GetTenderChatSfid/" + id,
  })
);
export const getTenderChatDetails = createAction(
  "GET_TENDER_CHAT_DETAILS",
  ({ payload, id }: SagaActionParams<T.TenderChatDto[]>) => ({
    payload,
    url: "/Portal/GetTenderChats/" + id,
  })
);
export const getAuctionDetails = createAction(
  "GET_AUCTION_DETAILS",
  ({ payload, id }: SagaActionParams<T.AuctionDetailDto[]>) => ({
    payload,
    url: "Portal/GetByTender?sfid=" + id,
  })
);

export const getIncDescAuctionDetails = createAction(
  "GET_INC_DESC_AUCTION_DETAILS",
  ({ payload, id }: SagaActionParams<T.IncDescAuctionDetailDto>) => ({
    payload,
    url: "Portal/GetRaiseReductionTenderById?sfid=" + id,
  })
);
export const getAuctionDetailsPriceList = createAction(
  "GET_AUCTION_DETAILS_PRICE_LIST",
  ({ payload, id }: SagaActionParams<T.AuctionPriceDto[]>) => ({
    payload,
    url: "Portal/GetTenderDetails?sfid=" + id,
  })
);
export const sendIncDescTransferTender = createAction(
  "SEND_INC_DESC_TRANSFER_TENDER",
  ({ payload, body }: SagaActionParams<T.AuctionDetailDto[]>) => ({
    payload,
    url: "Portal/SendRaiseReductionTenderSalesforce",
    body: body,
  })
);
export const sendTransferTender = createAction(
  "SEND_TRANSFER_TENDER",
  ({ payload, body }: SagaActionParams<T.AuctionDetailDto[]>) => ({
    payload,
    url: "Portal/SendTenderTransfer",
    body: body,
  })
);

export const getIncDescTenderPriceList = createAction(
  "GET_INC_DESC_TENDER_PRICE_LIST",
  ({ payload, id }: SagaActionParams<T.IncDescTenderPriceDto[]>) => ({
    payload,
    url: "Portal/GetRaiseReductionTenderDetailById?sfid=" + id,
  })
);
const auctionSaga = [
  takeLatest(
    getIncDescTenderPriceList.toString(),
    (payload: SagaGenericParams<T.TenderChatDto[]>) =>
      getListSaga<T.TenderChatDto[]>(payload)
  ),
  takeLatest(
    getTenderChatDetailswithSfid.toString(),
    (payload: SagaGenericParams<T.TenderChatDto[]>) =>
      getListSaga<T.TenderChatDto[]>(payload)
  ),
  takeLatest(
    getTenderChatDetails.toString(),
    (payload: SagaGenericParams<T.TenderChatDto[]>) =>
      getListSaga<T.TenderChatDto[]>(payload)
  ),
  takeLatest(
    getTenderChats.toString(),
    (payload: SagaGenericParams<T.TenderChatDto[]>) =>
      getListSaga<T.TenderChatDto[]>(payload)
  ),
  takeLatest(
    getAuctionDetailsPriceList.toString(),
    (payload: SagaGenericParams<T.AuctionPriceDto[]>) =>
      getListSaga<T.AuctionPriceDto[]>(payload)
  ),
  takeLatest(
    getIncDescAuctionDetails.toString(),
    (payload: SagaGenericParams<T.IncDescAuctionDetailDto[]>) =>
      getListSaga<T.IncDescAuctionDetailDto[]>(payload)
  ),
  takeLatest(
    getAuctionDetails.toString(),
    (payload: SagaGenericParams<T.AuctionDetailDto[]>) =>
      getListSaga<T.AuctionDetailDto[]>(payload)
  ),
  takeLatest(
    sendIncDescTransferTender.toString(),
    (payload: PostSagaGenericParams<T.AuctionDetailDto[]>) =>
      postSaga<T.AuctionDetailDto[]>(payload)
  ),
  takeLatest(
    sendTransferTender.toString(),
    (payload: PostSagaGenericParams<T.AuctionDetailDto[]>) =>
      postSaga<T.AuctionDetailDto[]>(payload)
  ),
];

export default auctionSaga;
