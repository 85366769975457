import { Button } from "devextreme-react";
import { useSelector } from "react-redux";
import { getLocalizedComponents } from "../../redux/selectors/language-selectors";
import { GridButtonType } from "./type";

type ButtonGroupProps = {
  gridButtons?: GridButtonType[];
  gridInstance: any;
  create: boolean;
  onCreate: () => void;
  refresh: boolean;
  exportExcel: boolean;
  columnChooser: boolean;
  clearFilters: boolean;
};

const ButtonGroup = ({
  gridButtons,
  gridInstance,
  create,
  onCreate,
  refresh,
  exportExcel,
  columnChooser,
  clearFilters,
}: ButtonGroupProps) => {
  const strings = useSelector(getLocalizedComponents);

  return (
    <div
      style={{
        width: "100%",
        marginBlockEnd: 10,
        display: "flex",
        flexDirection: "row-reverse",
      }}
    >
      {create && (
        <Button
          className="datagrid-action-button"
          text={strings.getString("new")}
          icon="add"
          type="success"
          stylingMode="contained"
          onClick={onCreate}
        />
      )}
      {refresh && (
        <Button
          className="datagrid-action-button"
          hint={strings.getString("refresh_grid")}
          icon="refresh"
          type="default"
          stylingMode="contained"
          onClick={() => gridInstance?.refresh()}
        />
      )}
      {exportExcel && (
        <Button
          className="datagrid-action-button"
          hint={strings.getString("export_as_excel")}
          icon="export"
          type="default"
          stylingMode="contained"
          onClick={() => gridInstance?.exportToExcel()}
        />
      )}
      {columnChooser && (
        <Button
          className="datagrid-action-button"
          hint={strings.getString("column_chooser")}
          icon="columnchooser"
          type="default"
          stylingMode="contained"
          onClick={() => gridInstance?.showColumnChooser()}
        />
      )}
      {clearFilters && (
        <Button
          className="datagrid-action-button"
          hint={strings.getString("delete_filters")}
          icon="clear"
          type="danger"
          stylingMode="contained"
          onClick={() => gridInstance?.state(null)}
        />
      )}
      {gridButtons?.map((button) => (
        <Button
          className="datagrid-action-button"
          text={button.buttonText}
          icon={button.icon}
          type={button.type}
          stylingMode="contained"
          onClick={button.onclick}
        />
      ))}
    </div>
  );
};

export default ButtonGroup;
