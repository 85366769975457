import produce from "immer";
import { AuthReducer } from "./reducerTypes/auth-reducer";
import { setUser, logoutAction } from "../actions/auth-actions";

export const initialState: AuthReducer = {
  user: undefined,
};

const authReducer = produce((draft: AuthReducer, action: any) => {
  switch (action.type) {
    case setUser.toString():
      draft.user = action.payload;
      break;
    case logoutAction.toString():
      draft.user = initialState.user;
      break;
  }
}, initialState);

export default authReducer;
