import { forwardRef, useEffect, useState } from "react";
import { FileUploader } from "../../components";
import Form, {
  GroupItem,
  SimpleItem,
  RequiredRule,
} from "devextreme-react/form";
import { useSelector } from "react-redux";
import {
  getLocalizedErrors,
  getLocalizedUsers,
} from "../../redux/selectors/language-selectors";
import {
  RapidAdvisorDto,
  UserModel,
} from "../../types/models/users/user-model";
import BaseService from "../../services";
import { getApiParams } from "../../redux/selectors/auth-selectors";
import { BodyType } from "../../redux/actions";
import { ILookupOptions } from "devextreme-react/lookup";
import { DropDownOptions } from "devextreme-react/autocomplete";

const CreateUserForm = forwardRef<any, { data: UserModel }>(({ data }, ref) => {
  const strings = useSelector(getLocalizedUsers);
  const userTypes = [
    { id: "MT" },
    { id: "AT" },
    { id: "MT-AT" },
    { id: "Yönetici" },
  ];
  const baseService = new BaseService();
  const [lookup, setLookup] = useState<RapidAdvisorDto[]>([]);
  const { token, lang } = useSelector(getApiParams);
  const errorStrings = useSelector(getLocalizedErrors);
  const getLookupdata = async () => {
    const response: any | null = await baseService.sendRequest(
      "/Portal/GetAdvisorList",
      "GET",
      token,
      lang,
      BodyType.formdata
    );
    response && setLookup(response);
  };
  useEffect(() => {
    getLookupdata(); // eslint-disable-next-line
  }, []);
  return (
    <form>
      <Form
        ref={ref}
        key="id"
        defaultFormData={data}
        labelLocation="top"
        formData={data}
        width={"100%"}
        height={"100%"}
        showColonAfterLabel={false}
        validationGroup="userCreateFormValidation"
      >
        <GroupItem>
          <SimpleItem
            dataField={"NameSurname"}
            label={{
              text: strings.getString("name_surname"),
            }}
          >
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>
          <SimpleItem
            dataField={"Email"}
            label={{
              text: strings.getString("email"),
            }}
          >
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>
          <SimpleItem
            dataField={"Password"}
            label={{
              text: strings.getString("password"),
            }}
          >
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>
          <SimpleItem
            dataField="AdvisorId"
            editorType="dxLookup"
            label={{
              text: "Advisor",
            }}
            editorOptions={
              {
                searchEnabled: true,
                items: lookup,
                displayExpr: "name",
                valueExpr: "sfid",
              } as ILookupOptions
            }
          >
            <RequiredRule message={"Zorunlu"} />
            <DropDownOptions closeOnOutsideClick showTitle={false} />
          </SimpleItem>
          <SimpleItem
            dataField="UserType"
            editorType="dxLookup"
            label={{
              text: "User Type",
            }}
            editorOptions={
              {
                searchEnabled: true,
                items: userTypes,
                displayExpr: "id",
                valueExpr: "id",
              } as ILookupOptions
            }
          >
            <RequiredRule message={"Zorunlu"} />
            <DropDownOptions closeOnOutsideClick showTitle={false} />
          </SimpleItem>
          <SimpleItem
            dataField="ProfileImage"
            label={{
              text: strings.getString("profile_image"),
            }}
          >
            <FileUploader
              buttonText={strings.getString("select_photo")}
              //@ts-ignore
              onValueChanged={(values) => (data.ProfiileImage = values[0])}
            />
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>
        </GroupItem>
      </Form>
    </form>
  );
});

export default CreateUserForm;
