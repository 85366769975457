import moment from "moment";
import { useSelector } from "react-redux";
import { images } from "../../assets";
import { getLocalizedComponents } from "../../redux/selectors/language-selectors";
import { color } from "../../utils/default-user";
import { getCurrency } from "../../utils/helpers";
import { salesforceUrl } from "../../utils/urls";
import { ChatDto, VehicleOpportunityDto, VehicleUserInfoDto } from "./type";

const ChatCard = ({
  opportunity,
  username,
  userInfo,
  chat,
}: {
  chat: ChatDto;
  opportunity: VehicleOpportunityDto;
  username: string;
  userInfo: VehicleUserInfoDto;
}) => {
  const strings = useSelector(getLocalizedComponents);

  return (
    <div
      style={{
        width: `100% `,
        border: "1px solid",
        borderColor: color.borderSide,
        borderRadius: 6,
        boxShadow: "20px 20px 50px #0047A412",
        paddingInline: 30,
        paddingBlock: 10,
        fontSize: 12,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: 50,
          width: "100%",
        }}
      >
        <div style={{ width: 67, height: 67, borderRadius: 67 }}>
          <img
            className="imageStyles"
            style={{ borderRadius: 67 }}
            src={images.profile1}
            alt=""
          />
        </div>
        <div
          className="smallHeadingStyle"
          style={{
            fontWeight: 700,
            color: color.textHeader,
            marginBlockEnd: 5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p
              className="mediumHeadingStyle"
              style={{ color: color.textHeader }}
            >
              {userInfo.FirstName} {userInfo.LastName} / {userInfo.Account.name}
            </p>
            <p
              onClick={() => {
                window.open(salesforceUrl + opportunity.id + "/view");
              }}
              style={{
                color: color.primary,
                backgroundColor: color.bgSoft,
                textDecoration: "none",
                cursor: "pointer",
                fontSize: 12,
                fontWeight: 700,
                borderColor: color.bgSoft,
                padding: 5,
                borderRadius: 10,
              }}
            >
              Salesforce↗
            </p>
          </div>
          <span
            className="smallText"
            style={{ marginBlockEnd: 7, marginBlockStart: -5 }}
          >
            <span
              style={{
                color: color.textHeader + color.fiftyOpacity,
              }}
            >
              {strings.getString("Communication Language")}:{" "}
            </span>
            {userInfo.CommunicationLanguage}
          </span>
          <div style={{ width: "100%", height: 40, display: "flex" }}>
            <div>
              <span
                className="smallText"
                style={{
                  color: color.textHeader + color.fiftyOpacity,
                }}
              >
                {strings.getString("Destination Number")}
              </span>
              <p>{opportunity?.filenumber}</p>
            </div>
            <div
              style={{
                height: "100%",
                width: 1,
                marginInline: 10,
                backgroundColor: color.bgSecondary,
              }}
            />
            <div>
              <span
                className="smallText"
                style={{
                  color: color.textHeader + color.fiftyOpacity,
                }}
              >
                {strings.getString("File Number")}
              </span>
              <p>{opportunity?.opdfilenumber}</p>
            </div>
            <div
              style={{
                height: "100%",
                width: 1,
                marginInline: 10,
                backgroundColor: color.bgSecondary,
              }}
            />
            <div>
              <span
                className="smallText"
                style={{
                  color: color.textHeader + color.fiftyOpacity,
                }}
              >
                {strings.getString("route")}
              </span>
              <p>
                <span>
                  {opportunity.loadcountrycode
                    ? opportunity.loadcountrycode
                    : "-"}
                </span>
                {">"}
                <span>
                  {opportunity.tocountrycode ? opportunity.tocountrycode : "-"}
                </span>
              </p>
            </div>
            <div
              style={{
                height: "100%",
                width: 1,
                marginInline: 10,
                backgroundColor: color.bgSecondary,
              }}
            />
            <div
              style={{
                height: "100%",
                width: 1,
                marginInline: 10,
                backgroundColor: color.bgSecondary,
              }}
            />
            <div>
              <span
                className="smallText"
                style={{
                  color: color.textHeader + color.fiftyOpacity,
                }}
              >
                {strings.getString("truck_type")}
              </span>
              <p>{opportunity.trucktypename}</p>
            </div>
            <div
              style={{
                height: "100%",
                width: 1,
                marginInline: 10,
                backgroundColor: color.bgSecondary,
              }}
            />
            <div>
              <span
                className="smallText"
                style={{
                  color: color.textHeader + color.fiftyOpacity,
                }}
              >
                {strings.getString("estimated_amount")}
              </span>
              <p>
                {opportunity?.estimatedamount
                  ? getCurrency(
                      opportunity.estimatedamount,
                      opportunity.currencyisocode
                    )
                  : "-"}
              </p>
            </div>
            <div
              style={{
                height: "100%",
                width: 1,
                marginInline: 10,
                backgroundColor: color.bgSecondary,
              }}
            />
            <div>
              <span
                className="smallText"
                style={{
                  color: color.textHeader + color.fiftyOpacity,
                }}
              >
                {strings.getString("weight")}
              </span>
              <p>{opportunity?.weight ? opportunity.weight + "t" : "-"}</p>
            </div>
          </div>
        </div>
      </div>{" "}
      <p
        className="smallText"
        style={{
          textAlign: "left",
          fontSize: 10,
          marginBlockStart: 5,
          fontWeight: 400,
          opacity: "0.8",
          marginInlineStart: 10,
        }}
      >
        {opportunity?.lastofferdate
          ? moment(opportunity?.lastofferdate).format("DD/MM/yy")
          : "-"}
      </p>
    </div>
  );
};
export default ChatCard;
