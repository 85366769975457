import DChart, {
  ArgumentAxis,
  Series,
  Label,
  Legend,
  Crosshair,
  Font,
} from "devextreme-react/chart";
import { Component } from "react";
import { ChartProps } from "../../types/components/chart";

class Chart extends Component<ChartProps> {
  render() {
    return (
      <DChart
        id="total-deal-reports-chart"
        dataSource={this.props.dataSource}
        title="Total Deal Reports"
      >
        <Series
          argumentField="name"
          valueField="value"
          color={this.props.color}
        />
        <ArgumentAxis>
          <Label wordWrap="none" overlappingBehavior={"stagger"} />
        </ArgumentAxis>
        <Legend visible={false} />
        <Crosshair enabled={true} color="#949494" width={3} dashStyle="dot">
          <Label visible={true} backgroundColor="#949494">
            <Font color="#fff" size={12} />
          </Label>
        </Crosshair>
      </DChart>
    );
  }
}

export default Chart;
