import Form, { GroupItem, SimpleItem } from "devextreme-react/form";
import { useSelector } from "react-redux";
import { ArticleDetailsModel } from "../../types/models/articles/article-model";
import { getLocalizedArticles } from "../../redux/selectors/language-selectors";
import { disabled } from "../../utils/helpers";
import { MultiLanguageInput } from "../../components";

const DetailsForm = ({ data }: { data: ArticleDetailsModel }) => {
  const strings = useSelector(getLocalizedArticles);

  return (
    <form>
      <Form labelLocation="top" formData={data} showColonAfterLabel={true}>
        <GroupItem>
          <SimpleItem
            editorOptions={disabled}
            dataField={"ArticleName"}
            label={{
              text: strings.getString("articlename"),
            }}
          />
          <SimpleItem>
            <MultiLanguageInput
              disabled
              data={data}
              languageObject={{
                mainKey: "articleTranslates",
                objectKeys: [
                  { title: strings.getString("title"), key: "Title" },
                  {
                    title: strings.getString("description"),
                    key: "Description",
                    editorType: "dxHtmlEditor",
                  },
                ],
              }}
            />
          </SimpleItem>
        </GroupItem>
      </Form>
    </form>
  );
};

export default DetailsForm;
