export interface ChatMessageReceiveObject {
  id: string;
  message: string;
  userId: string;
  createTime: string;
  profileImage: string;
  read: boolean;
  userName: string;
  opportunity_destination__c: string;
}
export interface ChatDto {
  messageData: ContractMessage[];
  opportunity_destination__c: string;
  contact__c: string;
  recordtypeid?: any;
  name?: any;
  messagetext__c?: any;
  isdeleted?: any;
  opportunity__c?: any;
  systemmodstamp?: any;
  messagejson__c: string;
  createddate?: any;
  user__c: string;
  sfid?: any;
  id: string;
  _hc_lastop: string;
  _hc_err?: any;
  destinationname: string;
}
export const formatChatMessageReceive = (
  m: TenderChatMessageReceiveObject
): ContractMessage => {
  return {
    Message: m.message,
    CreateTime: m.createTime,
    ProfileImage: m.profileImage,
    UserId: m.userId,
    UserName: m.userName,
    Read: m.read,
  };
};
export interface ContractMessage {
  Message: string;
  CreateTime: string;
  ProfileImage: string;
  UserId: string;
  UserName: string;
  Read: boolean;
}
export interface TenderChatSendObject {
  Message: string;
  opportunity__c?: string;
  user__c?: string;
  contact__c?: string;
  id: number;
}
export interface OpportunityDto {
  id: string;
  parentsfid: string;
  accountid: string;
  rapidadvisorname: string;
  rapidadvisorId: string;
  postedat: string;
  createddate: string;
  filenumber: string;
  opdfilenumber: string;
  loadtypeid: string;
  loadtype: string;
  fromcountry: string;
  fromcountryid: string;
  tocountry: string;
  tocountryid: string;
  ltlng: string;
  loadbegindate: string;
  loadenddate?: any;
  estimatedamount?: any;
  postendsat: string;
  weight: string;
  cubicmeters?: any;
  vehicletypeid: string;
  trucktype: string;
  transporttype: string;
  visibletomatched: boolean;
  visibletoall: boolean;
  firstcomegets: boolean;
  packagetypeid?: any;
  packagetype?: any;
  packagequantity?: any;
  unloadingtypeids?: any;
  loadingtypeids?: any;
  currencyisocode: string;
  shipperofferprice?: any;
  mindistance?: any;
  startLat?: any;
  startLong?: any;
  loadpoint: string;
  unloadpoint: string;
  trucktransitdeclarationtype?: any;
  seconddrivercheckbox?: any;
  thirdcountrycertificate?: any;
  thirdcountryname?: any;
  loadTrucks?: any;
  RouteTab?: any;
  DetailTab?: any;
  ExtraTab?: any;
  OtherTab?: any;

  Owner?: any;
}

export interface TenderChatMessageReceiveObject {
  opportunity__c: string;
  message: string;
  createTime: string;
  profileImage: string;
  userId: string;
  userName: string;
  read: boolean;
  sendMessageUser?: any;
  id: number;
  sfid: string;
  uniqId: string;
}

export interface OpportunityUserInfoDto {
  _localizer: Localizer;
  UserID: string;
  FirstName: string;
  LastName: string;
  Email: string;
  MobilePhone: string;
  Phone: string;
  AccountId: string;
  Account: Account;
  RapidAdvisor?: any;
  Role: string;
  CommunicationLanguage: string;
  ProposalResponsible: boolean;
  Token?: any;
  OwnerId: string;
  AccountAdmin: boolean;
  AccountApproved: boolean;
  WebApprovalStatus: string;
  ReasonForRejected: string;
  profile_information_completed__c: boolean;
  AccountClassPoint: number;
  AccountClass: string;
  ProfilePercentage: number;
  AccountType: AccountType;
}

interface AccountType {
  Code: string;
  Name: string;
}

interface Account {
  web_approval_status__c: string;
  companyemail__c: string;
  recordtypeid: string;
  billingcity?: any;
  name: string;
  availabletruck__c: boolean;
  phone: string;
  ownerid: string;
  taxnumber__c: string;
  isdeleted: boolean;
  systemmodstamp: string;
  billingpostalcode?: any;
  external_id__c: string;
  createddate: string;
  website: string;
  billingcountry?: any;
  taxoffice__c: string;
  billingstreet?: any;
  sfid: string;
  id: number;
  _hc_lastop: string;
  _hc_err?: any;
  accountsource: string;
  communicationpreference__c: string;
  origin__c?: any;
  industry: string;
  originpicklist__c: string;
  shippingstate: string;
  shippingpostalcode: string;
  shippingcountry: string;
  shippingstreet: string;
  shippingcity: string;
  accountclass__c?: any;
  reason_for_rejected__c?: any;
  profile_information_completed__c: boolean;
  accountscore__c?: any;
  reg_from_web__c: boolean;
}

interface Localizer {}
