import { LocalizedStringsMethods } from "react-localization";

export const navigation = (strings: LocalizedStringsMethods) => [
  {
    text: strings.getString("dashboard"),
    path: "/",
    icon: "mediumiconslayout",
  },

  {
    text: strings.getString("Tenders"),
    icon: "preferences",
    items: [
      {
        text: strings.getString("Tender Chat"),
        path: "/tender-chat",
      },
      {
        text: strings.getString("Waiting Tenders"),
        path: "/waiting-tenders",
      },
      {
        text: strings.getString("Completed Tenders"),
        path: "/completed-tenders",
      },
      {
        text: strings.getString("Inc Desc Waiting Tenders"),
        path: "/inc-desc-waiting-tenders",
      },
      {
        text: strings.getString("Inc Desc Completed Tenders"),
        path: "/inc-desc-completed-tenders",
      },
    ],
  },
  {
    text: strings.getString("sliders"),
    path: "/sliders",
    icon: "codeblock",
  },
  {
    text: strings.getString("blogs"),
    path: "/blogs",
    icon: "tableproperties",
  },
  {
    text: strings.getString("sss"),
    path: "/sss",
    icon: "tips",
  },
  {
    text: strings.getString("offices"),
    path: "/offices",
    icon: "toolbox",
  },
  {
    text: strings.getString("articles"),
    path: "/articles",
    icon: "verticalalignbottom",
  },
  {
    text: strings.getString("counters"),
    path: "/counters",
    icon: "clock",
  },
  {
    text: strings.getString("socialmedia"),
    path: "/socialmedia",
    icon: "globe",
  },
  {
    text: strings.getString("loadchat"),
    path: "/chat",
    icon: "box",
  },
  {
    text: strings.getString("vehiclechat"),
    path: "/vehiclechat",
    icon: "message",
  },
  {
    text: strings.getString("settings"),
    icon: "preferences",
    items: [
      {
        text: strings.getString("users"),
        path: "/users",
        icon: "user",
      },
    ],
  },
];
export const Atnavigation = (strings: LocalizedStringsMethods) => [
  {
    text: "VehicleChat",
    path: "/vehiclechat",
    icon: "message",
  },
];
export const MTnavigation = (strings: LocalizedStringsMethods) => [
  {
    text: "Load Chat",
    path: "/chat",
    icon: "box",
  },
  {
    text: strings.getString("Tender Chat"),
    path: "/tender-chat",
    icon: "box",
  },
];
export const ATMTnavigation = (strings: LocalizedStringsMethods) => [
  {
    text: "Load Chat",
    path: "/chat",
    icon: "box",
  },
  {
    text: "VehicleChat",
    path: "/vehiclechat",
    icon: "message",
  },
  {
    text: strings.getString("Tender Chat"),
    path: "/tender-chat",
    icon: "box",
  },
];
