import {
  useState,
  createContext,
  useContext,
  useEffect,
  createElement,
} from "react";

const NavigationContext = createContext<any>({});
const useNavigation = () => useContext(NavigationContext);

function NavigationProvider(props: any) {
  const [navigationData, setNavigationData] = useState({});

  return (
    <NavigationContext.Provider
      value={{ navigationData, setNavigationData }}
      {...props}
    />
  );
}

function withNavigationWatcher(Component: any) {
  return function (props: any) {
    const { path } = props.match;
    const { setNavigationData } = useNavigation();

    useEffect(() => {
      setNavigationData({ currentPath: path });
    }, [path, setNavigationData]);

    return createElement(Component, props);
  };
}

export { NavigationProvider, useNavigation, withNavigationWatcher };
