import { ReactNode } from "react";
import ScrollView from "devextreme-react/scroll-view";
import "./single-card.scss";

type SingleCardProps = {
  title?: string;
  description?: string;
  children: ReactNode;
};

export default function SingleCard({
  title,
  description,
  children,
}: SingleCardProps) {
  return (
    <ScrollView
      className={"with-footer single-card"}
      height={"max-content"}
      width={"100%"}
    >
      <div className={"dx-card content"}>
        <div className={"header"}>
          <div className={"title"}>{title}</div>
          <div className={"description"}>{description}</div>
        </div>
        {children}
      </div>
    </ScrollView>
  );
}
