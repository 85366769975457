export interface SagaGenericParams<Type> {
  type: string;
  payload: ApiCallback<Type>;
  url?: string;
}
export interface SagaActionParams<Type> {
  payload: ApiCallback<Type>;
  url?: string;
  body?: object;
  id?: any;
  params?: any;
}
export interface ApiResponse<ResultType = null> {
  ResultStatus: number;
  Message: string;
  Result: ResultType;
  Translates?: any;
  ResultDetail?: any;
  ResultDetail2?: any;
  ErrorDetails?: any;
  SubResult?: any;
  ProcessTime: string;
}
export interface ApiCallback<PayloadType = never>
  extends ApiSuccess<PayloadType>,
    ApiError {}
export interface ApiSuccess<PayloadType = never> {
  onSuccess: (
    message: string,
    payload: PayloadType,
    details?: any,
    Translates?: any,
    response?: any
  ) => void;
}
// interface RootObject {
//   Result?: any;
//   TotalCount: number;
//   Skipped: number;
//   Taken: number;
//   SubResult?: any;
//   ResultStatus: number;
//   UniqueID: number;
//   DetailID: number;
//   Message: string;
//   ProcessTime: string;
//   StatusCode: number;
// }
export interface ApiError {
  onError: (message: string) => void;
}
export interface ResultDetailDto {
  CityName: string;
  DistrictName: string;
  DistrictId: string;
  Longitude: string;
  Latitude: string;
  TotalCount: number;
  Page: number;
  NextPage: boolean;
  CurrentCount: number;
  Skip: number;
  Take: number;
}
export const verfiyResponse = (response?: ApiResponse<any>): boolean => {
  switch (response?.ResultStatus) {
    case 0: // NoRecord = 0
    case 1: //NewRecordAdded = 1
    case 3: //RecordDeleted = 3
    case 9: //QuerySuccess = 9
    case 17: //ExpiredLoginAllowed = 17
    case 19: //PasswordChanged = 19
      return true;
    case 2: //RecordUpdated = 2
    case 4: //ErrorOccured = 4
    case 5: //WrongLogin = 5
    case 6: //SystemErrorOccured = 6
    case 7: //SameRecordNotAdded = 7
    case 8: //CitizenUINValidationError = 8
    case 10: //QueryError = 10
    case 11: //Unauthorized = 11
    case 12: //CantDeleteHasSubRows = 12
    case 13: //CantDeleteImage = 13
    case 14: //CantUpdateImage = 14
    case 15: //SessionTimeout = 15
    case 16: //PackageExpired = 16
    default:
      return false;
  }
};
