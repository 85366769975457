import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { LoginResponseModel } from "../../types/models/auth/login-response";
import { LanguageModel } from "../../types/models/system/system-models";
import { getListSaga } from "../base/request/get";
import { postSagaBodyRaw, PostSagaGenericParams } from "../base/request/post";
import { BodyType } from "../base/request/request";
import { SagaActionParams } from "../base/types/saga";

export const loginAction = createAction(
  "LOGIN_ACTION",
  ({ payload, body }: SagaActionParams<LoginResponseModel>) => ({
    payload,
    body,
    bodyType: BodyType.raw,
    url: "Auth/adminauthenticate",
  })
);

export const getLanguages = createAction(
  "GET_LANGUAGES",
  ({ payload }: SagaActionParams<LanguageModel[]>) => ({
    payload,
    url: "System/GetLanguages",
  })
);
const authnewSaga = [
  takeLatest(
    loginAction.toString(),
    (payload: PostSagaGenericParams<LoginResponseModel>) =>
      postSagaBodyRaw<LoginResponseModel>(payload)
  ),
  takeLatest(
    getLanguages.toString(),
    (payload: PostSagaGenericParams<LanguageModel[]>) =>
      getListSaga<LanguageModel[]>(payload)
  ),
];

export default authnewSaga;
