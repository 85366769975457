import { Fragment } from "react";
import "./profile.scss";
import Form, { GroupItem, SimpleItem } from "devextreme-react/form";
import { useSelector } from "react-redux";
import { userSelector } from "../../redux/selectors/auth-selectors";
import { imageUrl } from "../../utils/urls";

export default function Profile() {
  const user = useSelector(userSelector);

  return (
    <Fragment>
      <h2 className={"content-block"}>Profile</h2>

      <div className={"content-block dx-card responsive-paddings"}>
        <Form
          id={"form"}
          formData={user}
          labelLocation={"top"}
          colCountByScreen={colCountByScreen}
        >
          <GroupItem colCount={3}>
            <SimpleItem>
              <div className={"form-avatar"}>
                <img alt={""} src={imageUrl + user?.ProfileImage} />
              </div>
            </SimpleItem>
            <GroupItem>
              <SimpleItem
                editorOptions={{ disabled: true }}
                dataField={"NameSurname"}
              />
              <SimpleItem
                editorOptions={{ disabled: true }}
                dataField={"Email"}
              />
            </GroupItem>
          </GroupItem>
        </Form>
      </div>
    </Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
};
