const Error404 = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div>
        <h1>NOT FOUND</h1>
        <h1 style={{ textAlign: "center" }}>404</h1>
      </div>
    </div>
  );
};

export default Error404;
