import { useSelector } from "react-redux";
import { DataGrid } from "../../components";
import { getLocalizedOffices } from "../../redux/selectors/language-selectors";

import { OfficeDetailModel } from "../../types/models/offices/office-model";
import {
  addOfficeUrl,
  deleteOfficeUrl,
  getOfficeDetailsUrl,
  getOfficesUrl,
  updateOfficeUrl,
} from "../../utils/urls";

import CreateForm from "./create";
import DetailsForm from "./details";
import EditForm from "./edit";
import { getLanguageDtosValues } from "../../utils/helpers";
import { languagesSelector } from "../../redux/selectors/system-selectors";

function OfficesPage() {
  const strings = useSelector(getLocalizedOffices);
  const languages = useSelector(languagesSelector);
  const columns = [
    {
      dataField: "title",
      caption: strings.getString("office_name"),
    },

    {
      dataField: "flag",
      caption: strings.getString("flag"),
    },
    {
      dataField: "email",
      caption: strings.getString("email"),
    },
    {
      dataField: "phone",
      caption: strings.getString("phone"),
    },
    {
      dataField: "latitude",
      caption: strings.getString("latitude"),
    },
    {
      dataField: "longitude",
      caption: strings.getString("longitude"),
    },
  ];
  return (
    <DataGrid
      url={getOfficesUrl}
      columns={columns || []}
      refObject={
        {
          Flag: "",
          Email: "",
          Phone: "",
          Latitude: "",
          Longitude: "",
          OfficeTranslates: getLanguageDtosValues(languages, {
            Address: "",
            OfficeName: "",
          }),
        } as OfficeDetailModel
      }
      details={{
        height: "88%",
        getByIdUrl: getOfficeDetailsUrl,
        title: strings.getString("office_details"),
        component: DetailsForm,
      }}
      create={{
        height: "88%",
        createUrl: addOfficeUrl,
        title: strings.getString("add_office"),
        component: CreateForm,
        validationGroup: "createOfficeValidation",
      }}
      edit={{
        height: "88%",
        getByIdUrl: getOfficeDetailsUrl,
        editUrl: updateOfficeUrl,
        title: strings.getString("edit_office"),
        component: EditForm,
        validationGroup: "officeEditFormValidationGroup",
      }}
      remove={{ url: deleteOfficeUrl }}
    />
  );
}
export default OfficesPage;
