import { Button, LoadPanel } from "devextreme-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLocalizedTenders } from "../../../redux/selectors/language-selectors";
import {
  getIncDescAuctionDetails,
  getIncDescTenderPriceList,
  sendIncDescTransferTender,
} from "../../../redux/tenders/saga";
import {
  IncDescAuctionDetailDto,
  IncDescTenderPriceDto,
} from "../../../redux/tenders/type";
import DetailTab from "./Detail";
import IncPriceTab from "./PriceTab";
import { confirm } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import "../style.scss";
const IncDescAuctionDetailPage = () => {
  const dispatch = useDispatch();
  const t = useSelector(getLocalizedTenders);
  const [loader, setLoader] = useState(false);
  const { id }: { id: string } = useParams();
  const [active, setActive] = useState("1");
  const [prices, setPrices] = useState<IncDescTenderPriceDto[]>([]);
  const [auction, setAuction] = useState<IncDescAuctionDetailDto>();
  const toggleMenu = (tab: string) => {
    setActive(tab);
  };
  useEffect(() => {
    setLoader(true);
    dispatch(
      getIncDescAuctionDetails({
        payload: {
          onSuccess: (m, payload) => {
            setLoader(false);
            setAuction(payload);
          },
          onError: () => {
            setLoader(false);
          },
        },
        id: id,
      })
    );
    dispatch(
      getIncDescTenderPriceList({
        payload: {
          onSuccess: (m, payload) => {
            setPrices(payload);
          },
          onError: () => {
            setLoader(false);
          },
        },
        id: id,
      })
    );
  }, [dispatch, id]);

  return (
    <React.Fragment>
      <LoadPanel visible={loader} />
      {auction && (
        <div style={{ backgroundColor: "white", padding: "10px 30px" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {auction.transfer && (
              <Button
                icon="redo"
                type="success"
                stylingMode="contained"
                text={t.getString("Transfer")}
                onClick={() => {
                  var confirmResp = confirm(
                    t.getString("Transfer Question"),
                    t.getString("Transfer")
                  );
                  confirmResp.then((result) => {
                    if (result) {
                      setLoader(true);
                      dispatch(
                        sendIncDescTransferTender({
                          payload: {
                            onSuccess: (m, payload) => {
                              notify(
                                t.getString("Transfer successed"),
                                "success",
                                5000
                              );
                              dispatch(
                                getIncDescAuctionDetails({
                                  payload: {
                                    onSuccess: (m, payload) => {
                                      setLoader(false);
                                      setAuction(payload);
                                    },
                                    onError: () => {
                                      setLoader(false);
                                    },
                                  },
                                  id: id,
                                })
                              );
                            },
                            onError: () => {},
                          },
                          body: {
                            sfid: auction.id,
                          },
                        })
                      );
                    } else {
                      setLoader(false);
                    }
                  });
                }}
              />
            )}
          </div>
          <div className="mainWidth auctionPage">
            <div className="">
              <div className="headingStyle" style={{ marginBlockEnd: 5 }}>
                {auction.opdtender_no__c} / {auction.transportertendertype__c}
              </div>
            </div>
            <div className="row">
              <div className="col2">
                <div
                  className={
                    " auctionMenuItem" +
                    (active === "1" ? " activeMenuItem" : "")
                  }
                  onClick={() => toggleMenu("1")}
                  style={{ textAlign: "center" }}
                >
                  <span className="auctionMenuItemTitle">
                    {t.getString("Tender Informations")}
                  </span>
                </div>
              </div>
              <div className="col2">
                <div
                  className={
                    "auctionMenuItem" +
                    (active === "2" ? " activeMenuItem" : "")
                  }
                  onClick={() => toggleMenu("2")}
                  style={{ textAlign: "center" }}
                >
                  <span className="auctionMenuItemTitle">
                    {t.getString("Price Table")}
                  </span>
                </div>
              </div>
            </div>

            {active === "1" && <DetailTab auction={auction} />}
            {active === "2" && (
              <IncPriceTab auction={auction} prices={prices} />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default IncDescAuctionDetailPage;
