import { useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";

import "./login-form.scss";
import { setUser } from "../../redux/actions/auth-actions";
import { isLoading } from "../../redux/selectors/app-selectors";
import {
  getLocalizedAuth,
  getLocalizedErrors,
} from "../../redux/selectors/language-selectors";
import { setLanguages } from "../../redux/actions/system-actions";
import { getLanguages, loginAction } from "../../redux/auth/saga";
import notify from "devextreme/ui/notify";

function LoginForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const strings = useSelector(getLocalizedAuth);
  const errorStrings = useSelector(getLocalizedErrors);
  const loading = useSelector(isLoading);
  const formData = useRef<{ email: string; password: string }>({
    email: "",
    password: "",
  });
  const emailEditorOptions = {
    stylingMode: "filled",
    placeholder: strings.getString("email"),
    mode: "email",
    defaultValue: "fgungor@komutteknolojisi.com",
  };
  const passwordEditorOptions = {
    stylingMode: "filled",
    placeholder: strings.getString("password"),
    mode: "password",
    defaultValue: "1q2w3E**",
  };
  const rememberMeEditorOptions = {
    text: strings.getString("remember_me"),
    elementAttr: { class: "form-text" },
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      dispatch(
        loginAction({
          payload: {
            onSuccess: (message, payload) => {
              dispatch(setUser(payload));
              dispatch(
                getLanguages({
                  payload: {
                    onSuccess: (m, paylaod) => {
                      dispatch(setLanguages(paylaod));
                    },
                    onError: () => {},
                  },
                })
              );
              if (
                (payload && payload.UserType && payload.UserType === "AT") ||
                payload.UserType === "MT-AT"
              ) {
                history.push("/vehiclechat");
              } else if (
                payload &&
                payload.UserType &&
                payload.UserType === "MT"
              ) {
                history.push("/chat");
              } else {
                history.push("/");
              }
            },
            onError: (message) => {
              notify(errorStrings.getString("loginFailed"), "error", 5000);
            },
          },
          body: {
            userName: formData.current.email,
            password: formData.current.password,
          },
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // const onCreateAccountClick = useCallback(() => {
  //   history.push("/create-account");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [history]);

  return (
    <form className={"login-form"} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={"email"}
          editorType={"dxTextBox"}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message={errorStrings.getString("required")} />
          <EmailRule message={errorStrings.getString("email")} />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message={errorStrings.getString("required")} />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"rememberMe"}
          editorType={"dxCheckBox"}
          editorOptions={rememberMeEditorOptions}
        >
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                strings.getString("signin")
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={"link"}>
            <Link to={"/reset-password"}>
              {strings.getString("forgot_password")}
            </Link>
          </div>
        </Item>
        {/* <ButtonItem>
          <ButtonOptions
            text={"Create an account"}
            width={"100%"}
            onClick={onCreateAccountClick}
          />
        </ButtonItem> */}
      </Form>
    </form>
  );
}

export default LoginForm;
