import { useSelector } from "react-redux";
import { nameof } from "ts-simple-nameof";
import { DataGrid } from "../../components";
import { getLocalizedUsers } from "../../redux/selectors/language-selectors";
import {
  UserCreateModel,
  UserModel,
} from "../../types/models/users/user-model";
import {
  addPortalUsersUrl,
  deletePortalUsersUrl,
  getPortalUsersUrl,
  updatePortalUsersUrl,
} from "../../utils/urls";
import CreateUserForm from "./create";
import EditUserForm from "./edit";

function UsersPage() {
  const strings = useSelector(getLocalizedUsers);
  const columns = [
    {
      dataField: nameof<UserModel>((p) => p.namesurname),
      caption: strings.getString("name_surname"),
    },
    {
      dataField: nameof<UserModel>((p) => p.email),
      caption: strings.getString("name_surname"),
    },
  ];

  return (
    <DataGrid
      url={getPortalUsersUrl}
      columns={columns || []}
      refObject={
        {
          NameSurname: "",
          Email: "",
          Password: "",
          ProfiileImage: "",
          AdvisorId: "",
          UserType: "",
          profileimage: "",
        } as UserCreateModel
      }
      create={{
        height: "500",
        createUrl: addPortalUsersUrl,
        title: strings.getString("add_user"),
        component: CreateUserForm,
        validationGroup: "userCreateFormValidation",
      }}
      edit={{
        height: "700",
        editUrl: updatePortalUsersUrl,
        title: strings.getString("users"),
        getByIdUrl: "undefined",
        component: EditUserForm,
        validationGroup: "userEditFormValidation",
      }}
      remove={{ url: deletePortalUsersUrl }}
    />
  );
}
export default UsersPage;
