import Form, {
  GroupItem,
  SimpleItem,
  RequiredRule,
} from "devextreme-react/form";
import { useSelector } from "react-redux";
import {
  getLocalizedErrors,
  getLocalizedOffices,
} from "../../redux/selectors/language-selectors";
import { OfficeDetailModel } from "../../types/models/offices/office-model";
import { disabled } from "../../utils/helpers";
import { GoogleMaps, MultiLanguageInput } from "../../components";

const DetailsForm = ({ data }: { data: OfficeDetailModel }) => {
  const strings = useSelector(getLocalizedOffices);
  const errorStrings = useSelector(getLocalizedErrors);

  return (
    <form>
      <Form
        key="id"
        defaultFormData={data}
        labelLocation="top"
        formData={data}
        width={"100%"}
        height={"100%"}
        showColonAfterLabel={false}
        validationGroup="officeValidationGroup"
      >
        <GroupItem colCount={1}>
          <SimpleItem>
            <MultiLanguageInput
              disabled
              data={data}
              languageObject={{
                mainKey: "OfficeTranslates",
                objectKeys: [
                  {
                    title: strings.getString("office_name"),
                    key: "OfficeName",
                  },
                  {
                    title: strings.getString("address"),
                    key: "Address",
                  },
                ],
              }}
              getLanguageObject={{
                OfficeName: "",
                Address: "",
              }}
            />
          </SimpleItem>
          <SimpleItem
            colSpan={2}
            editorOptions={disabled}
            dataField={"Email"}
            label={{
              text: strings.getString("email"),
            }}
          />

          <SimpleItem
            editorOptions={disabled}
            dataField={"Phone"}
            label={{
              text: strings.getString("phone"),
            }}
          />
          <SimpleItem
            editorOptions={disabled}
            dataField={"Flag"}
            label={{
              text: strings.getString("flag"),
            }}
          />
          <SimpleItem
            colSpan={2}
            label={{
              text: strings.getString("coordinates"),
            }}
          >
            <div style={{ height: 300, width: "100%" }}>
              <GoogleMaps
                lat={parseFloat(data.Latitude.replace(",", "."))}
                lng={parseFloat(data.Longitude.replace(",", "."))}
                isDetail
              />
            </div>
          </SimpleItem>
        </GroupItem>
      </Form>
    </form>
  );
};

export default DetailsForm;
