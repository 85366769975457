import { createSelector } from "reselect";
import { RootState } from "../reducers/reducerTypes";

const userTokenState = (state: RootState) => state.auth.user?.Token;
export const getUserToken = createSelector([userTokenState], (token) => token);

const localeState = (state: RootState) => state.system.lang?.culture;
export const localeSelector = createSelector([localeState], (lang) => lang);

export const getApiParams = createSelector(
  [localeState, userTokenState],
  (lang, token) => {
    return {
      lang,
      token: token || "",
    };
  }
);

const userState = (state: RootState) => state.auth.user;
export const userSelector = createSelector([userState], (user) => user);

const loggedState = (state: RootState) => state.auth.user?.Email;
export const loggedSelector = createSelector([loggedState], (email) =>
  email !== undefined ? true : false
);

// const userTypeState = (state: RootState) => state.auth?.user?.;
// export const userTypeSelector = createSelector([userTypeState], (userType) => {
//   if (userType && userType === "0b0a6611-651e-4a24-9e28-db3881873b34")
//     return "admin";
//   else if (
//     (userType && userType === "267f36e4-4303-4d55-88ef-b7688240f39b") ||
//     (userType && userType === "621f0fb0-cf86-4597-8a39-b02b24feae07")
//   )
//     return "tenant";
//   else if (
//     (userType && userType === "5edb82a9-0c06-4dd1-b587-876517abe223") ||
//     (userType && userType === "58b0e014-a80b-43de-bfda-63a7a62205f3")
//   )
//     return "customer";
//   else return null;
// });
