import { useSelector } from "react-redux";
import { DataGrid } from "../../components";
import { getLocalizedArticles } from "../../redux/selectors/language-selectors";
import { languagesSelector } from "../../redux/selectors/system-selectors";
import { ArticleDetailsModel } from "../../types/models/articles/article-model";
import { getLanguageDtosValues } from "../../utils/helpers";
import {
  addArticleUrl,
  deleteArticleUrl,
  getArticleDetailsUrl,
  getArticlesUrl,
  updateArticleUrl,
} from "../../utils/urls";
import CreateArticleForm from "./create";
import DetailsForm from "./details";
import EditArticleForm from "./edit";

function ArticlesPage() {
  const strings = useSelector(getLocalizedArticles);
  const languages = useSelector(languagesSelector);
  const columns = [
    {
      dataField: "articlename",
      caption: strings.getString("articlename"),
    },
    {
      dataField: "title",
      caption: strings.getString("title"),
    },
  ];

  return (
    <DataGrid
      url={getArticlesUrl}
      columns={columns || []}
      refObject={
        {
          ArticleName: "",
          articleTranslates: getLanguageDtosValues(languages),
        } as ArticleDetailsModel
      }
      details={{
        height: "76%",
        getByIdUrl: getArticleDetailsUrl,
        title: strings.getString("article_details"),
        component: DetailsForm,
      }}
      edit={{
        height: "76%",
        getByIdUrl: getArticleDetailsUrl,
        editUrl: updateArticleUrl,
        title: strings.getString("edit_article"),
        component: EditArticleForm,

        validationGroup: "articleEditFormValidation",
      }}
      create={{
        height: "76%",
        createUrl: addArticleUrl,
        title: strings.getString("add_article"),
        component: CreateArticleForm,
        validationGroup: "articleCreateFormValidation",
      }}
      remove={{ url: deleteArticleUrl }}
    />
  );
}
export default ArticlesPage;
