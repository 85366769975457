import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import Flag from "react-flagkit";
import "../user-panel/user-panel.scss";
import {
  languageSelector,
  languagesSelector,
} from "../../redux/selectors/system-selectors";
import { setLanguage } from "../../redux/actions/system-actions";

export default function UserPanel({
  menuMode,
}: {
  menuMode: "context" | "list";
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector(languageSelector);

  const languages = useSelector(languagesSelector);

  const menuItems = languages?.map((language) => {
    return {
      text: language.language,
      // icon: language.locale,
      onClick: () => {
        dispatch(setLanguage(language));
        history.go(0);
      },
    };
  });

  return (
    <div className={"user-panel"}>
      <div className={"user-info"} style={{ paddingBlock: 2 }}>
        <div className={"user-name"}>
          <Flag country={lang?.flag?.toUpperCase()} />
        </div>
      </div>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".language-button"}
          showEvent={"dxclick"}
          width={100}
          cssClass={"user-menu"}
        >
          <Position my={"top center"} at={"bottom center"} />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
    </div>
  );
}
