import { forwardRef } from "react";
import Form, {
  GroupItem,
  RequiredRule,
  SimpleItem,
} from "devextreme-react/form";
import { SliderDetailModel } from "../../types/models/sliders/slider-object";
import { useSelector } from "react-redux";
import {
  getLocalizedErrors,
  getLocalizedSliders,
} from "../../redux/selectors/language-selectors";
import { MultiLanguageInput, FileUploader } from "../../components";

const CreateForm = forwardRef<any, { data: SliderDetailModel }>(
  ({ data }, ref) => {
    const strings = useSelector(getLocalizedSliders);
    const errorStrings = useSelector(getLocalizedErrors);

    return (
      <form>
        <Form
          ref={ref}
          key="id"
          labelLocation="top"
          formData={data}
          showColonAfterLabel={true}
          validationGroup="sliderCreateFormValidation"
        >
          <GroupItem>
            <SimpleItem
              dataField="SliderImage"
              label={{
                text: strings.getString("slider_image"),
              }}
            >
              <FileUploader
                buttonText={strings.getString("select_photo")}
                onValueChanged={(values) => (data.SliderImage = values[0])}
              />

              <RequiredRule message={errorStrings.getString("required")} />
            </SimpleItem>
            <SimpleItem>
              <MultiLanguageInput
                data={data}
                languageObject={{
                  mainKey: "SliderTranslates",
                  objectKeys: [
                    {
                      title: strings.getString("title"),
                      key: "Title",
                      required: true,
                    },
                    {
                      title: strings.getString("description"),
                      key: "Description",
                      required: true,
                    },
                  ],
                }}
              />
            </SimpleItem>
          </GroupItem>
        </Form>
      </form>
    );
  }
);

export default CreateForm;
