import Form, {
  GroupItem,
  RequiredRule,
  SimpleItem,
} from "devextreme-react/form";
import { useSelector } from "react-redux";
import {
  getLocalizedBlogs,
  getLocalizedErrors,
} from "../../redux/selectors/language-selectors";
import { BlogDetailModel } from "../../types/models/blogs/blog-model";
import { MultiLanguageInput, FileUploader, Modal } from "../../components";
import { baseUrl, imageUrl } from "../../utils/urls";
import { useState } from "react";

type EditArticleFromType = {
  data: BlogDetailModel;
};

const EditForm = ({ data }: EditArticleFromType) => {
  const strings = useSelector(getLocalizedBlogs);
  const errorStrings = useSelector(getLocalizedErrors);
  const [showImage, setShowImage] = useState<boolean>(false);
  const [showBigImage, setShowBigImage] = useState<boolean>(false);

  return (
    <form>
      <Form
        key="id"
        labelLocation="top"
        formData={data}
        showColonAfterLabel={true}
        validationGroup="blogEditFormValidation"
      >
        <GroupItem>
          <SimpleItem
            dataField="SmallImage"
            label={{
              text: strings.getString("small_image"),
            }}
          >
            <div className="centerer-div">
              <FileUploader
                buttonText={strings.getString("select_photo")}
                onValueChanged={(values) => (data.SmallImage = values[0])}
              />
              <img
                src={imageUrl + data.SmallImage}
                width="25%"
                alt=""
                onClick={() => setShowImage(true)}
              />
              <Modal
                visible={showImage}
                onHiding={() => setShowImage(false)}
                title={strings.getString("small_image")}
                height={"80%"}
                width={"95%"}
                onSubmitClick={() => console.log("")}
                showButton={false}
                coverModal
              >
                <div className="centerer-div full-element">
                  <img
                    src={imageUrl + data.SmallImage}
                    className="image-style"
                    alt=""
                    onClick={() => setShowBigImage(true)}
                  />
                </div>
              </Modal>
            </div>
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>
          <SimpleItem
            dataField="BigImage"
            label={{
              text: strings.getString("big_image"),
            }}
          >
            <div className="centerer-div">
              <FileUploader
                buttonText={strings.getString("select_photo")}
                onValueChanged={(values) => (data.BigImage = values[0])}
              />
              <img
                src={baseUrl + data.BigImage}
                width="25%"
                alt=""
                onClick={() => setShowBigImage(true)}
              />
              <Modal
                visible={showBigImage}
                onHiding={() => setShowBigImage(false)}
                title={strings.getString("big_image")}
                height={"80%"}
                width={"95%"}
                onSubmitClick={() => console.log("")}
                showButton={false}
                coverModal
              >
                <div className="centerer-div full-element">
                  <img
                    src={imageUrl + data.BigImage}
                    className="image-style"
                    alt=""
                  />
                </div>
              </Modal>
            </div>

            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>
          <SimpleItem
            dataField="Owner"
            label={{
              text: strings.getString("owner"),
            }}
          >
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>
          <SimpleItem
            dataField="CreateDate"
            label={{
              text: strings.getString("date"),
            }}
            editorType="dxDateBox"
            editorOptions={{ dateSerializationFormat: "yyyy-MM-dd" }}
          >
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>
          <SimpleItem>
            <MultiLanguageInput
              data={data}
              languageObject={{
                mainKey: "BlogTranslates",
                objectKeys: [
                  {
                    title: strings.getString("title"),
                    key: "Title",
                    required: true,
                  },
                  {
                    title: strings.getString("short_description"),
                    key: "ShortDescription",
                    required: true,
                  },
                  {
                    title: strings.getString("description"),
                    key: "Description",
                    editorType: "dxHtmlEditor",
                    required: true,
                  },
                ],
              }}
              getLanguageObject={{
                Description: "",
                ShortDescription: "",
                Title: "",
              }}
            />
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>
        </GroupItem>
      </Form>
    </form>
  );
};

export default EditForm;
