/**
 * OTOPLAN
 * https://komutteknolojisi.com
 *
 * @author Mehmet Polat KOÇAK <mehmet@komutteknolojisi.com>
 * @format
 * @flow strict-local
 */

import { createAction } from "redux-smart-actions";
import { LoginResponseModel } from "../../types/models/auth/login-response";
import * as SagaParams from "../sagas/auth-saga";

export const loginAction = createAction(
  "LOGIN_ACTION",
  (payload: SagaParams.LoginInputParams) => ({
    payload,
  })
);
export const logoutAction = createAction("LOGOUT_ACTION");

export const setUser = createAction(
  "SET_USER",
  (payload: LoginResponseModel) => ({
    payload,
  })
);
