import Form, { GroupItem, SimpleItem } from "devextreme-react/form";
import { SliderDetailModel } from "../../types/models/sliders/slider-object";
import { useSelector } from "react-redux";
import { getLocalizedSliders } from "../../redux/selectors/language-selectors";
import { MultiLanguageInput, FileUploader, Modal } from "../../components";
import { baseUrl } from "../../utils/urls";
import { useState } from "react";

const EditForm = ({ data }: { data: SliderDetailModel }) => {
  const strings = useSelector(getLocalizedSliders);
  const [showImage, setShowImage] = useState<boolean>(false);

  return (
    <form>
      <Form
        key="id"
        labelLocation="top"
        formData={data}
        showColonAfterLabel={true}
        validationGroup="sliderEditFormValidation"
      >
        <GroupItem>
          <SimpleItem
            dataField="SliderImage"
            label={{
              text: strings.getString("slider_image"),
            }}
          >
            <div className="centerer-div">
              <FileUploader
                buttonText={strings.getString("select_photo")}
                onValueChanged={(values) => (data.SliderImage = values[0])}
              />

              <img
                src={baseUrl + data.SliderImage}
                width="30%"
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => setShowImage(true)}
              />
              <Modal
                visible={showImage}
                onHiding={() => setShowImage(false)}
                title={strings.getString("slider_image")}
                height={"80%"}
                width={"95%"}
                onSubmitClick={() => console.log("")}
                showButton={false}
                coverModal
              >
                <div className="centerer-div full-element">
                  <img
                    src={baseUrl + "/" + data.SliderImage}
                    className="image-style"
                    alt=""
                  />
                </div>
              </Modal>
            </div>
          </SimpleItem>
          <SimpleItem>
            <MultiLanguageInput
              data={data}
              languageObject={{
                mainKey: "SliderTranslates",
                objectKeys: [
                  {
                    title: strings.getString("title"),
                    key: "Title",
                    required: true,
                  },
                  {
                    title: strings.getString("description"),
                    key: "Description",
                    required: true,
                  },
                ],
              }}
            />
          </SimpleItem>
        </GroupItem>
      </Form>
    </form>
  );
};

export default EditForm;
