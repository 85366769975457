import { call } from "@redux-saga/core/effects";

import { createRequestSaga } from "./request";
import { ApiCallback, ApiResponse, verfiyResponse } from "../types/saga";

export interface SagaGenericParams<Type> {
  type: string;
  payload: ApiCallback<Type>;
  url?: string;
  id?: string;
}

export function* getListSaga<Type>({ payload, url }: SagaGenericParams<Type>) {
  // yield put(loader.actions.showLoader());
  try {
    const response2: ApiResponse<Type> = yield call(createRequestSaga, {
      method: "GET",
      url: url || "",
    }); //@ts-ignore
    const response = JSON.parse(response2);
    if (verfiyResponse(response))
      payload.onSuccess(
        response.Message,
        response.Result,
        response.SubResult,
        response.Translates,
        response
      );
  } catch (e) {
    console.log("ERROR getListSaga", e);
    console.log("PAYLOADDDDDDDDD", "aasdasd");
  } finally {
    //   yield put(loader.actions.hideLoader());
  }
}
