import { Switch, Route, Redirect } from "react-router-dom";
import { SingleCard } from "./layouts";
import { LoginForm, ResetPasswordForm, ChangePasswordForm } from "./components";
import icons from "./assets/images";
import { getLocalizedAuth } from "./redux/selectors/language-selectors";
import { useSelector } from "react-redux";

export default function UnauthenticatedContent() {
  const strings = useSelector(getLocalizedAuth);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        style={{ width: 150, marginBlockEnd: 30 }}
        src={icons.logoBackgroundEmpty}
        alt=""
      />
      <Switch>
        <Route exact path="/login">
          <SingleCard title={strings.getString("signin")}>
            <LoginForm />
          </SingleCard>
        </Route>
        {/* <Route exact path="/create-account">
          <SingleCard title="Sign Up">
            <CreateAccountForm />
          </SingleCard>
        </Route> */}
        <Route exact path="/reset-password">
          <SingleCard
            title={strings.getString("reset_password")}
            description={strings.getString("reset_password_description")}
          >
            <ResetPasswordForm />
          </SingleCard>
        </Route>
        <Route exact path="/change-password/:recoveryCode">
          <SingleCard title="Change Password">
            <ChangePasswordForm />
          </SingleCard>
        </Route>
        <Redirect to={"/login"} />
      </Switch>
    </div>
  );
}
