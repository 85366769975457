import Form, {
  GroupItem,
  SimpleItem,
  RequiredRule,
} from "devextreme-react/form";
import { useSelector } from "react-redux";
import {
  getLocalizedErrors,
  getLocalizedUsers,
} from "../../redux/selectors/language-selectors";
import {
  RapidAdvisorDto,
  UserModel,
} from "../../types/models/users/user-model";
import { FileUploader, Modal } from "../../components";
import BaseService from "../../services";
import { getApiParams } from "../../redux/selectors/auth-selectors";
import { useEffect, useState } from "react";
import { BodyType } from "../../redux/actions";
import { ILookupOptions } from "devextreme-react/lookup";
import { DropDownOptions } from "devextreme-react/autocomplete";
import { baseUrl, imageUrl } from "../../utils/urls";

const EditUserForm = ({ data }: { data: UserModel }) => {
  const strings = useSelector(getLocalizedUsers);
  const userTypes = [
    { id: "MT" },
    { id: "AT" },
    { id: "MT-AT" },
    { id: "Yönetici" },
  ];
  const baseService = new BaseService();
  const [lookup, setLookup] = useState<RapidAdvisorDto[]>([]);
  const [showImage, setShowImage] = useState(false);
  const { token, lang } = useSelector(getApiParams);
  const errorStrings = useSelector(getLocalizedErrors);
  const getLookupdata = async () => {
    const response: any | null = await baseService.sendRequest(
      "/Portal/GetAdvisorList",
      "GET",
      token,
      lang,
      BodyType.formdata
    );
    response && setLookup(response);
  };
  useEffect(() => {
    getLookupdata(); // eslint-disable-next-line
  }, []);
  return (
    <form>
      <Form
        key="id"
        defaultFormData={data}
        labelLocation="top"
        formData={data}
        width={"100%"}
        height={"100%"}
        showColonAfterLabel={false}
        validationGroup="userEditFormValidation"
      >
        <GroupItem>
          <SimpleItem
            dataField={"NameSurname"}
            label={{
              text: strings.getString("name_surname"),
            }}
          >
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>
          <SimpleItem
            dataField={"Email"}
            label={{
              text: strings.getString("Email"),
            }}
          />
          <SimpleItem
            dataField={"Password"}
            label={{
              text: strings.getString("Password"),
            }}
          />
          <SimpleItem
            dataField="AdvisorId"
            editorType="dxLookup"
            label={{
              text: "Advisor",
            }}
            editorOptions={
              {
                searchEnabled: true,
                items: lookup,
                displayExpr: "name",
                valueExpr: "sfid",
              } as ILookupOptions
            }
          >
            <RequiredRule message={"Zorunlu"} />
            <DropDownOptions closeOnOutsideClick showTitle={false} />
          </SimpleItem>
          <SimpleItem
            dataField="UserType"
            editorType="dxLookup"
            label={{
              text: "User Type",
            }}
            editorOptions={
              {
                searchEnabled: true,
                items: userTypes,
                displayExpr: "id",
                valueExpr: "id",
              } as ILookupOptions
            }
          >
            <RequiredRule message={"Zorunlu"} />
            <DropDownOptions closeOnOutsideClick showTitle={false} />
          </SimpleItem>
          <SimpleItem
            dataField="ProfiileImage"
            label={{
              text: strings.getString("profile_image"),
            }}
          >
            <FileUploader
              buttonText={strings.getString("select_photo")}
              //@ts-ignore
              onValueChanged={(values) => (data.ProfiileImage = values[0])}
            />
          </SimpleItem>
        </GroupItem>
      </Form>{" "}
      <p>{strings.getString("profile_image")}:</p>
      <div>
        <img
          src={imageUrl + data.profileimage}
          width={"20%"}
          alt=""
          style={{ cursor: "pointer" }}
          onClick={() => setShowImage(true)}
        />
        <Modal
          visible={showImage}
          onHiding={() => setShowImage(false)}
          title={strings.getString("image")}
          height={"80%"}
          width={"95%"}
          onSubmitClick={() => console.log("")}
          showButton={false}
          coverModal
        >
          <div className="centerer-div full-element">
            <img
              src={baseUrl + data.profileimage}
              className="image-style"
              alt=""
              onClick={() => setShowImage(true)}
            />
          </div>
        </Modal>
      </div>
    </form>
  );
};

export default EditUserForm;
