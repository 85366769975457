import { ScrollView } from "devextreme-react";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { getLocalizedTenders } from "../../../redux/selectors/language-selectors";
import { IncDescAuctionDetailDto } from "../../../redux/tenders/type";

const DetailTab = ({ auction }: { auction: IncDescAuctionDetailDto }) => {
  const t = useSelector(getLocalizedTenders);
  const adjustForTimezone = (currentdate?: string, datetime?: boolean) => {
    if (currentdate && currentdate != null) {
      return moment(currentdate + "+00:00").format(
        datetime ? "DD/MM/yyyy HH:mm" : "DD/MM/yyyy"
      );
    } else {
      return "-";
    }
  };
  const table = [
    {
      title: t.getString("Tender Type"),
      value: "Hedef İhalesi",
    },
    {
      title: t.getString("Tender Contract Start Date"),
      value: adjustForTimezone(auction.tenderstartdatetime__c, true),
    },
    {
      title: t.getString("Tender Contract End Date"),
      value: adjustForTimezone(auction.tenderenddatetime__c, true),
    },

    {
      title: t.getString("Tender Contract Period Start Date"),
      value: adjustForTimezone(auction.tender_contractstartdate__c),
    },
    {
      title: t.getString("Tender Contract Period End Date"),
      value: adjustForTimezone(auction.tender_contractenddate__c),
    },
  ];

  return (
    <React.Fragment>
      <div className="row ">
        <div className="col2">
          <div
            className=" auctiondetailMenuItem"
            style={{ textAlign: "center" }}
          >
            <span className="auctionMenuItemTitle">
              {t.getString("Descriptions")}
            </span>
          </div>
          <div className=" auctiondetailMenuItemDescription minHeigth230">
            <ScrollView>
              <div
                dangerouslySetInnerHTML={{
                  __html: auction.Tender_Description__c ?? " ",
                }}
              ></div>
            </ScrollView>
          </div>
        </div>
        <div className=" col2 auctionDetailItem">
          <div
            className="auctiondetailMenuItem "
            style={{ textAlign: "center" }}
          >
            <span className="auctionMenuItemTitle">
              {t.getString("Informations")}
            </span>
          </div>
          <div className="auctiondetailMenuItemDescription minHeigth230">
            <table className="auctionTable">
              {table.map((x) => (
                <tr>
                  <th>{x.title}</th>
                  <td>{x.value}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default DetailTab;
