import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import CustomStore from "devextreme/data/custom_store";
import DGrid, {
  Column,
  Pager,
  Paging,
  HeaderFilter,
  FilterRow,
  FilterPanel,
  FilterBuilderPopup,
  Button,
  ColumnChooser,
} from "devextreme-react/data-grid";
import { confirm } from "devextreme/ui/dialog";
import { BodyType } from "../../redux/actions";
import { getApiParams } from "../../redux/selectors/auth-selectors";
import BaseService from "../../services";
import Modal from "../modal";

import "./style.scss";

import ButtonGroup from "./button-group";
import { getLocalizedComponents } from "../../redux/selectors/language-selectors";
import { GridButtonType } from "./type";

const filterBuilderPopupPosition = {
  of: window,
  at: "top",
  my: "top",
  offset: { y: 10 },
};
const visible = { visible: true };
const allowedPageSizes = [10, 15, 20, "all"];

type DataGridProps = {
  keyField?: string;
  url: string | undefined;
  columns: any[];
  gridButtons?: GridButtonType[];
  refObject: any;
  create?: {
    width?: string | number;
    component: any;
    createUrl: string;
    title: string;
    height?: string;
    validationGroup?: string;
  };
  edit?: {
    width?: string | number;
    component: any;
    getByIdUrl: string;
    editUrl: string;
    title: string;
    height?: string;
    validationGroup?: string;
  };
  remove?: {
    url?: string;
  };
  details?: {
    width?: string | number;
    component: any;
    getByIdUrl: string;
    title: string;
    height?: string;
  };
  clone?: boolean;
};

const DataGrid = ({
  keyField,
  url,
  gridButtons,
  columns,
  refObject,
  create,
  edit,
  remove,
  details,
  clone = false,
}: DataGridProps) => {
  const strings = useSelector(getLocalizedComponents);
  const baseService = new BaseService();
  const tableRef = useRef<any>();
  const formRef = useRef<any>();
  const multiRef = useRef<any>();
  const data = useRef<any>(refObject);
  const [createVisible, setCreateVisible] = useState<boolean>(false);
  const [editVisible, setEditVisible] = useState<undefined | string>();
  const [detailsVisible, setDetailsVisible] = useState<undefined | string>();
  console.log(keyField);
  const customStore = new CustomStore({
    key: keyField ? keyField : "id",
    //@ts-ignore
    load: () => baseService.sendRequest(url, "GET", token, lang),
  });

  const { token, lang } = useSelector(getApiParams);

  const onModalClose = (type: "create" | "edit" | "details" = "edit") => {
    formRef.current._instance.resetValues();

    if (type === "create") {
      if (multiRef.current) {
        multiRef.current._instance.resetValues();
      }
      setCreateVisible(false);
    } else if (type === "edit") {
      setEditVisible(undefined);
    } else {
      setDetailsVisible(undefined);
    }
  };

  const onClone = async (id: string) => {
    let confirmResult = confirm(strings.getString("cloneques"), "");
    confirmResult.then(async (dialogResult) => {
      if (dialogResult) {
        if (edit) {
          const response: any | null = await baseService.sendRequest(
            edit.getByIdUrl + "/" + id,
            "GET",
            token,
            lang,
            BodyType.formdata
          );
          data.current = response;
          data.current.ID = undefined;
          if (response && create?.createUrl) {
            await baseService.sendRequest(
              create?.createUrl,
              "POST",
              token,
              lang,
              data.current,
              BodyType.formdata
            );
            tableRef.current.instance.refresh();
          }
        }
      }
    });
  };

  /** GET DETAIL REQUEST **/
  const onGetDetailClicked = async (
    id: string,
    type: "edit" | "details" = "edit",
    e?: any
  ) => {
    if (edit) {
      if (edit.getByIdUrl !== "undefined") {
        const response: any | null = await baseService.sendRequest(
          edit.getByIdUrl + "/" + id,
          "GET",
          token,
          lang,
          BodyType.formdata
        );
        data.current = response;

        if (response)
          type === "edit" ? setEditVisible(id) : setDetailsVisible(id);
      } else {
        console.log(e.row.data, "e.row.data");
        //@ts-ignore
        data.current = {
          NameSurname: e.row.data.namesurname,
          Email: e.row.data.email,
          Password: e.row.data.password,
          ProfileImage: e.row.data.profileimage,
          AdvisorId: e.row.data.advisorid,
          ID: e.row.key,
          profileimage: e.row.data.profileimage,
          UserType: e.row.data.usertype,
        };
        type === "edit" ? setEditVisible(id) : setDetailsVisible(id);
      }
    }
  };

  /** ADD REQUEST **/
  const onAddSuccess = async (data: any) => {
    if (create?.createUrl) {
      await baseService.sendRequest(
        create?.createUrl,
        "POST",
        token,
        lang,
        data,
        BodyType.formdata
      );
      onModalClose("create");
      tableRef.current.instance.refresh();
    }
  };
  /** EDIT REQUEST **/
  const onEditSuccess = async (data: any) => {
    if (edit?.editUrl === "Portal/UpdatePortalUser") {
      if (edit?.editUrl) {
        await baseService.sendRequest(
          edit?.editUrl + "?UserID=" + data.ID,
          "PUT",
          token,
          lang,
          data,
          BodyType.formdata
        );
        onModalClose();
        tableRef.current.instance.refresh();
      }
    } else {
      if (edit?.editUrl) {
        await baseService.sendRequest(
          edit?.editUrl,
          "PUT",
          token,
          lang,
          data,
          BodyType.formdata
        );
        onModalClose();
        tableRef.current.instance.refresh();
      }
    }
  };

  /** DELETE REQUEST **/
  const onDeleteClicked = (id: string) => {
    let confirmResult = confirm("Are you sure?", "Delete item");
    confirmResult.then(async (dialogResult) => {
      if (dialogResult && remove?.url) {
        await baseService.sendRequest(
          remove.url + "/" + id,
          "DELETE",
          token,
          lang
        );
        tableRef.current.instance.refresh();
      }
    });
  };

  return (
    <>
      <ButtonGroup
        gridButtons={gridButtons}
        gridInstance={tableRef?.current?._instance}
        create={create?.component}
        onCreate={() => {
          data.current = refObject;
          setCreateVisible(true);
        }}
        refresh
        exportExcel
        columnChooser
        clearFilters
      />
      <DGrid
        ref={tableRef}
        key={keyField ? keyField : "id"}
        className={"custom-data-grid wide-card"}
        dataSource={customStore}
        showBorders
        showRowLines
        showColumnLines={false}
        columnAutoWidth
        columnHidingEnabled
        rowAlternationEnabled
        allowColumnResizing
        allowColumnReordering
        focusedRowEnabled
        searchPanel={visible}
        groupPanel={visible}
      >
        <Paging defaultPageSize={15} />
        <Pager
          visible
          showPageSizeSelector
          showInfo
          showNavigationButtons
          allowedPageSizes={allowedPageSizes}
        />
        <HeaderFilter visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible={true} />
        <FilterBuilderPopup position={filterBuilderPopupPosition} />
        <ColumnChooser mode="select" />
        {columns.map((column, i) => (
          <Column key={i} {...column} />
        ))}
        <Column type="buttons">
          <Button
            visible={details !== undefined}
            icon="info"
            onClick={(e: any) => onGetDetailClicked(e.row.key, "details")}
          />
          <Button
            visible={clone}
            icon="copy"
            onClick={(e: any) => onClone(e.row.key)}
          />
          <Button
            visible={edit !== undefined}
            icon="edit"
            onClick={(e: any) => onGetDetailClicked(e.row.key, "edit", e)}
          />
          <Button
            visible={remove !== undefined}
            icon="trash"
            onClick={(e: any) => onDeleteClicked(e.row.key)}
          />
        </Column>
      </DGrid>
      {details && (
        <Modal
          width={details.width ? details.width : 900}
          visible={detailsVisible !== undefined}
          onHiding={() => onModalClose("details")}
          title={details.title}
          height={details.height}
          onSubmitClick={() => onEditSuccess(data.current)}
          showButton={false}
        >
          <details.component data={data.current} />
        </Modal>
      )}
      {edit && (
        <Modal
          width={edit.width ? edit.width : 900}
          visible={editVisible !== undefined}
          onHiding={() => onModalClose("edit")}
          validationGroup={edit.validationGroup}
          title={edit.title}
          height={edit.height}
          buttonTitle={strings.getString("update")}
          onSubmitClick={() => onEditSuccess(data.current)}
        >
          <edit.component data={data.current} />
        </Modal>
      )}
      {create && (
        <Modal
          width={create.width ? create.width : 900}
          visible={createVisible}
          onHiding={() => onModalClose("create")}
          validationGroup={create.validationGroup}
          title={create.title}
          height={create.height}
          buttonTitle={strings.getString("add")}
          onSubmitClick={() => onAddSuccess(data.current)}
        >
          <create.component
            ref={formRef}
            data={data.current}
            multiRef={multiRef}
          />
        </Modal>
      )}
    </>
  );
};
export default DataGrid;
