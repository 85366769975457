import { useSelector } from "react-redux";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { getLocalizedTenders } from "../../../redux/selectors/language-selectors";
import { AuctionDetailDto, AuctionPriceDto } from "../../../redux/tenders/type";

import "../style.scss";

const TenderPrices = ({
  auction,
  prices,
}: {
  auction: AuctionDetailDto;
  prices: AuctionPriceDto[];
}) => {
  const t = useSelector(getLocalizedTenders);
  const truckString = (
    week: number | undefined,
    month: number | undefined,
    year: number | undefined
  ) => {
    if (week && week != null) {
      return t.getString("Weekly");
    } else if (month && month != null) {
      return t.getString("Monthly");
    } else if (year && year != null) {
      return t.getString("Yearly");
    } else {
      return "-";
    }
  };
  const truckCount = (
    count: number | undefined,
    week: number | undefined,
    month: number | undefined,
    year: number | undefined
  ) => {
    if (count && count != null) {
      return count;
    } else if (week && week != null) {
      return week;
    } else if (month && month != null) {
      return month;
    } else if (year && year != null) {
      return month;
    } else {
      return 0;
    }
  };
  return (
    <div>
      <Table className="borderedTable">
        <Thead>
          <Tr>
            <Th>{t.getString("Loading Location")}</Th>
            <Th>{t.getString("UnLoading Location")}</Th>
            <Th>{t.getString("Transit Time")}</Th>
            <Th>{t.getString("Load Type")}</Th>
            <Th>{t.getString("Navlun")}</Th>
            <Th>{t.getString("Vehicle Count")}</Th>
            <Th>{t.getString("Express Navlun")}</Th>
            <Th>{t.getString("Express Transit Time")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {prices.map((x, i) => {
            return (
              <Tr key={i}>
                <Td>{x.loadpoint ?? "-"}</Td>
                <Td>{x.unloadpoint ?? "-"}</Td>
                <Td>{x.transittime ?? "-"}</Td>
                <Td>{x.loadtype ?? "-"}</Td>
                <Td>
                  {x.shipperofferprice ?? "-"} {x.currencyisocode}
                </Td>
                <Td>
                  {truckCount(
                    x.numberofvehiclesoftheshipper__c,
                    x.NumberOfTrucksPerWeek__c,
                    x.NumberOfTrucksPerMonth__c,
                    x.NumberOfTrucksPerYear__c
                  )}
                  <div style={{ fontSize: 10 }}>
                    (
                    {truckString(
                      x.NumberOfTrucksPerWeek__c,
                      x.NumberOfTrucksPerMonth__c,
                      x.NumberOfTrucksPerYear__c
                    )}
                    )
                  </div>
                </Td>

                <Td>
                  {x.expressfreight ?? "-"} {x.currencyisocode}
                </Td>
                <Td>{x.expresstransittime ?? "-"}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </div>
  );
};
export default TenderPrices;
