import { LoadPanel, ScrollView } from "devextreme-react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { images } from "../../assets";
import "./style.scss";
import moment from "moment";
import { Formik } from "formik";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  getApiParams,
  userSelector,
} from "../../redux/selectors/auth-selectors";
import { useDispatch, useSelector } from "react-redux";
import "./chat.scss";
import {
  ContractMessage,
  formatChatMessageReceive,
  TenderChatMessageReceiveObject,
  TenderChatSendObject,
} from "./type";
import { chatUrl } from "../../utils/urls";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { getLocalizedAuth } from "../../redux/selectors/language-selectors";

import {
  getTenderChatDetails,
  getTenderChatDetailswithSfid,
  getTenderChats,
} from "../../redux/tenders/saga";
import {
  AuctionDetailDto,
  IncDescAuctionDetailDto,
  TenderChatDto,
} from "../../redux/tenders/type";
import TenderChatCard from "./chat-card";
import { color } from "../../utils/default-user";
import IncDescTenderChatCard from "./chat-card-incdesc";
const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};

const HUB_ENDPOINT = chatUrl + "api/tenderchat";
const HUB_ENDPOINT2 = chatUrl + "api/raisereductiontenderchat";
const TenderChat = () => {
  const strings = useSelector(getLocalizedAuth);
  const { token, lang } = useSelector(getApiParams);
  const [loader, setLoader] = useState(false);
  const [loaderMain, setLoaderMain] = useState(false);
  const chatActiveRef = useRef(false);
  const [messages, setMessages] = useState<ContractMessage[]>();
  const user = useSelector(userSelector);
  const connectionRef = useRef<HubConnection | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [userInfo, setUserInfo] = useState<any>();
  const [tender, setTender] = useState<AuctionDetailDto>();
  const [tenderDetails, setTenderDetails] = useState<IncDescAuctionDetailDto>();
  const [selected, setSelected] = useState<TenderChatDto>();
  const [chatList, setChatList] = useState<TenderChatDto[]>([]);
  const [connectionStatus, setConnectionStatus] = useState<
    "offline" | "connecting" | "connected" | "failed"
  >("offline");

  useLayoutEffect(() => {
    !location.search.includes("refresh") && setLoaderMain(true);
    dispatch(
      getTenderChats({
        payload: {
          onSuccess: (msg, payload) => {
            setChatList(payload);
            setLoaderMain(false);
          },
          onError: () => {
            setLoaderMain(false);
          },
        },
      })
    ); // eslint-disable-next-line
  }, [dispatch]);

  const connectSignal = (id: string, current: TenderChatDto) => {
    console.log("CHATACTIVE START", chatActiveRef.current);
    if (connectionRef.current !== undefined && token && lang && current) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(HUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => token,
        })
        .configureLogging(LogLevel.Debug)
        .build();
      connection.serverTimeoutInMilliseconds = 3600000;
      console.log(`Trying to connect to ${HUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`CHAT Connected to ${HUB_ENDPOINT}`);
          setConnectionStatus("connected");
          connectionRef.current = connection;
          chatActiveRef.current = false;
          setLoader(false);
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(`Disconnected from ${HUB_ENDPOINT}`);
      });
      connection.on(
        current.load_opportunity__c + current.contact__c,
        function (payload: TenderChatMessageReceiveObject) {
          console.log("ReceiveMessage", id, payload);
          setMessages((state) => [
            //@ts-ignore
            ...state,
            formatChatMessageReceive(payload),
          ]);
        }
      );
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
  };
  const connectChatIncDesc = (current: TenderChatDto) => {
    console.log("TESTTTTTTT");
    if (connectionRef.current === null && token && lang) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(HUB_ENDPOINT2, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => token,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();
      connection.serverTimeoutInMilliseconds = 25000;
      console.log(`Trying to connect to ${HUB_ENDPOINT2}`);
      connection
        .start()
        .then(() => {
          console.log(`CHAT Connected to ${HUB_ENDPOINT2}`);
          setConnectionStatus("connected");
          setLoader(false);
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the CHAT connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(`Disconnected from ${HUB_ENDPOINT2}`);
        // connectionRef.current = null;
        // !chatActive.current && connectChat();
      });
      connection.on(
        current.opportunity_destination__c + current.contact__c,
        function (payload: any) {
          console.log("ReceiveMessage", payload);
          //@ts-ignore
          setMessages((state) => [...state, formatChatMessageReceive(payload)]);
        }
      );
      connectionRef.current = connection;
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
  };
  const onMessageSend = async (
    values: any,
    { resetForm }: { resetForm: any }
  ) => {
    if (tenderDetails && !tender) {
      onMessageSendInc(values, { resetForm });
    } else {
      if (connectionRef.current && selected) {
        try {
          const serverObject: TenderChatSendObject = {
            opportunity__c: selected.load_opportunity__c,
            contact__c: selected.contact__c,
            user__c: selected.user__c,
            Message: values.message,
            id: selected.id,
          };
          console.log(serverObject, "Object");
          const result = await connectionRef.current.invoke(
            "SendMessage",
            serverObject
          );
          console.log("SendMessage", result);
          resetForm();
        } catch (err: any) {
          console.log(selected, "selected chat");
          console.log(
            `Error sending ${values.message} from${user?.NameSurname} : ${err.message} ${connectionStatus}`
          );
        }
      }
    }
  };
  useEffect(() => {
    if (location.search.includes("refresh")) {
      dispatch(
        getTenderChats({
          payload: {
            onSuccess: (msg, payload) => {
              let url = location.search.replace("refresh", "");
              url = url.replace("&&", "");
              history.push(url);
              setChatList(payload);
            },
            onError: () => {
              setLoader(false);
            },
          },
        })
      );
    } // eslint-disable-next-line
  }, [location.search, dispatch]);
  const onMessageSendInc = async (
    values: any,
    { resetForm }: { resetForm: any }
  ) => {
    if (connectionRef.current && selected) {
      try {
        const serverObject: any = {
          opportunity_destination__c: selected.opportunity_destination__c,
          contact__c: selected.contact__c,
          user__c: selected.user__c,
          Message: values.message,
          id: selected.id,
        };
        console.log(serverObject, "Object");
        const result = await connectionRef.current.invoke(
          "SendMessage",
          serverObject
        );
        console.log("SendMessage", result);
        resetForm();
      } catch (err: any) {
        console.log(selected, "selected chat");
        console.log(
          `Error sending ${values.message} from${user?.NameSurname} : ${err.message} ${connectionStatus}`
        );
      }
    }
  };
  const SelectChat = (chatId: string) => {
    setLoader(true);
    try {
      connectionRef.current?.stop().then(() => {
        console.log("CONNECTION STOPPED", selected?.id);
        connectionRef.current = null;
      });
    } catch (e) {
      console.log("ERROR UNMOUNT", e);
    }
    dispatch(
      getTenderChatDetails({
        payload: {
          onSuccess: (msg, payload, subResult, t, res) => {
            console.log(payload);
            if (payload && payload.length > 0) {
              setSelected(payload[0]);
              setMessages(JSON.parse(payload[0].tendermessagejson__c));
              if (subResult && subResult.Tender) {
                setTender(subResult.Tender);
                connectSignal(payload[0].sfid, payload[0]);
              } else {
                setTender(undefined);
              }
              if (subResult && subResult.TenderDetail) {
                console.log(subResult.TenderDetail, "AAAAAAAAAAAAAAA");
                setTenderDetails(subResult.TenderDetail);
                connectChatIncDesc(payload[0]);
              } else {
                setTenderDetails(undefined);
              }
              if (subResult && subResult.UserInfo) {
                setUserInfo(subResult.UserInfo);
              } else {
                setUserInfo(undefined);
              }
            }

            setLoader(false);
          },
          onError: () => {
            setLoader(false);
          },
        },
        id: chatId,
      })
    );
  };
  useEffect(() => {
    const url = new URLSearchParams(location.search);
    const chatId = url.get("chatId");
    const sfid = url.get("sfid");
    if (chatId && location.search.includes("chatId")) {
      SelectChat(chatId);
    } // eslint-disable-next-line
    if (sfid && location.search.includes("sfid")) {
      setLoader(true);
      dispatch(
        getTenderChatDetailswithSfid({
          payload: {
            onSuccess: (msg, payload, subResult) => {
              history.push("?chatId=" + payload[0].uniqid);
              SelectChat(payload[0].uniqid);
            },
            onError: () => {
              setLoader(false);
            },
          },
          id: sfid,
        })
      );
    } // eslint-disable-next-line
  }, [dispatch]);
  return (
    <div>
      <div className="chat">
        <div className="chat-list-outer-div">
          <div className="chat-list-header">
            <img src={images.profile5} width={45} height={45} alt="" />
            <p>
              <p>Rapid Admin Chat</p>
              <p style={{ color: "#74788d7a" }}>{user?.NameSurname}</p>
            </p>
          </div>
          <div className="chat-title">
            <p className="chat-title-nav">Chat</p>
          </div>
          <div style={{ width: "100%" }}>
            <ScrollView className="scroll-list">
              <ul className="list-unstyled chat-list">
                {chatList?.map((chat) => {
                  let messages: ContractMessage[] = JSON.parse(
                    chat.tendermessagejson__c
                  );
                  const lastMessageFilter = messages.filter(
                    (x) => x.UserId !== user?.AdvisorId
                  );
                  const lastMessage = messages[messages.length - 1];
                  const lastMessageOwner =
                    lastMessageFilter[lastMessageFilter.length - 1];
                  return (
                    <li
                      key={chat.id}
                      onClick={() => {
                        history.push("?chatId=" + chat.uniqid);
                        SelectChat(chat.uniqid);
                      }}
                      className={
                        selected?.uniqid === chat.uniqid ? "active-item" : ""
                      }
                    >
                      <Link className="link" to="#">
                        <div className="chat-card-outer">
                          <div className="chat-card-img flex-shrink-0 user-img online align-self-center me-3">
                            <img
                              src={images.profile1}
                              className="avatar-sm"
                              alt=""
                              style={{ marginBlockStart: 3 }}
                            />
                          </div>
                          <div className="chat-card-info flex-grow-1 overflow-hidden">
                            <h5
                              className="text-truncate"
                              style={{ fontSize: 12, fontWeight: 600 }}
                            >
                              {chat.name} / {lastMessageOwner?.UserName}
                            </h5>

                            <p
                              className="text-truncate"
                              style={{ fontSize: 11, color: "#7a788d" }}
                            >
                              {lastMessage?.Message}{" "}
                              {!lastMessage.Read &&
                                selected?.id !== chat.id &&
                                lastMessage.UserId !== user?.AdvisorId && (
                                  <>
                                    <br />
                                    <span
                                      style={{
                                        backgroundColor: " #37A75B",
                                        color: "white",
                                        paddingInline: 3,
                                        border: "1px solid #37A75B",
                                        borderRadius: 6,
                                      }}
                                    >
                                      {strings.getString("New")}
                                    </span>
                                  </>
                                )}
                            </p>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: 12,
                              color: "#7a788d",
                            }}
                          >
                            {moment(lastMessage?.CreateTime).format(
                              "DD/MM/YY HH:mm:ss"
                            )}
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </ScrollView>
          </div>
        </div>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          onHiding={() => setLoaderMain(false)}
          visible={loaderMain}
        />
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          onHiding={() => setLoader(false)}
          visible={loader}
        />
        {!loader && (
          <div className="chat-content">
            {selected ? (
              <>
                <div
                  style={{
                    borderBottom: "1px solid",
                    borderColor: color.bgSecondary,
                    padding: 5,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 700,
                  }}
                >
                  {tenderDetails && userInfo && (
                    <IncDescTenderChatCard
                      chatDto={selected}
                      userInfo={userInfo}
                      tender={tenderDetails}
                    />
                  )}
                  {tender && userInfo && (
                    <TenderChatCard
                      chatDto={selected}
                      userInfo={userInfo}
                      tender={tender}
                    />
                  )}
                </div>
                <ScrollView
                  className="scroll-list-chat"
                  style={{ marginBlock: 10 }}
                >
                  <ul className="message-list">
                    {messages?.map((message, i) => {
                      let messagePosition =
                        message.UserId === user?.AdvisorId
                          ? "chatApp__convMessageItem--right"
                          : "chatApp__convMessageItem--left";
                      return (
                        <div style={{ marginBlock: 20 }}>
                          <div
                            className={
                              "chatApp__convMessageItem " +
                              messagePosition +
                              " clearfix"
                            }
                          >
                            <div className="chatApp__convMessageValue">
                              {message.Message}
                              <p
                                style={{
                                  width: "100%",
                                  textAlign: "right",
                                  fontSize: 10,
                                }}
                              >
                                {moment(message?.CreateTime).format(
                                  "DD/MM/YY HH:mm:ss"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </ul>
                  <AlwaysScrollToBottom />
                </ScrollView>
                <Formik
                  initialValues={{
                    message: "",
                  }}
                  onSubmit={onMessageSend}
                >
                  {({ handleSubmit, setFieldValue, values }) => {
                    return (
                      <>
                        <input
                          value={values.message}
                          type="text"
                          className={"chatApp__convInput"}
                          tabIndex={0}
                          onChange={(e) =>
                            setFieldValue("message", e.target.value)
                          }
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              handleSubmit();
                            }
                          }}
                        />
                        <div
                          className={"chatApp__convButton"}
                          onClick={(e) => {
                            handleSubmit();
                          }}
                        >
                          <img
                            src={images.send}
                            alt=""
                            width={10}
                            style={{ marginBlockStart: 4 }}
                          />
                        </div>
                      </>
                    );
                  }}
                </Formik>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {strings.getString("Select User to start Chat")}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default TenderChat;
