import Flag from "react-flagkit";
import { Lookup, DropDownOptions } from "devextreme-react/lookup";

export interface FlagCountryDto {
  id: string;
  description: string;
}
const FlagSelectLookup = ({
  data,
  flag,
  onValueChanged,
}: {
  data: FlagCountryDto[];
  flag?: string;
  onValueChanged: (flag: string) => void;
}) => {
  return (
    <Lookup
      items={data}
      valueExpr="id"
      displayExpr="description"
      defaultValue={flag}
      onValueChanged={(e) => onValueChanged(e.value.toUpperCase())}
      itemRender={(e: { id: string; description: string }) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Flag
              country={e.id.toUpperCase()}
              style={{ marginInlineEnd: 10 }}
            />
            <span>{e.description}</span>
          </div>
        );
      }}
    >
      <DropDownOptions closeOnOutsideClick showTitle={false} />
    </Lookup>
  );
};

export default FlagSelectLookup;
