import Form, {
  GroupItem,
  SimpleItem,
  RequiredRule,
} from "devextreme-react/form";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  FlagSelectLookup,
  GoogleMaps,
  MultiLanguageInput,
} from "../../components";
import { FlagCountryDto } from "../../components/flag-select-lookup";
import { BodyType } from "../../redux/actions";
import { getApiParams } from "../../redux/selectors/auth-selectors";
import {
  getLocalizedErrors,
  getLocalizedOffices,
} from "../../redux/selectors/language-selectors";
import { languageSelector } from "../../redux/selectors/system-selectors";
import BaseService from "../../services";
import { OfficeDetailModel } from "../../types/models/offices/office-model";
import { localizer } from "../../utils/helpers";

const EditForm = ({ data }: { data: OfficeDetailModel }) => {
  const strings = useSelector(getLocalizedOffices);
  const errorStrings = useSelector(getLocalizedErrors);
  const culture = useSelector(languageSelector).culture;
  const baseService = new BaseService();
  const [lookup, setLookup] = useState<FlagCountryDto[]>([]);
  const { token, lang } = useSelector(getApiParams);
  const getLookupdata = async () => {
    const response: any | null = await baseService.sendRequest(
      "/System/GetPicklistCountryList",
      "GET",
      token,
      lang,
      BodyType.formdata
    );
    response && setLookup(response);
  };
  useEffect(() => {
    getLookupdata(); // eslint-disable-next-line
  }, []);
  return (
    <form>
      <Form
        key="id"
        defaultFormData={data}
        labelLocation="top"
        formData={data}
        width={"100%"}
        height={"100%"}
        showColonAfterLabel={false}
        validationGroup="officeEditFormValidationGroup"
      >
        <GroupItem colCount={1}>
          <SimpleItem>
            <MultiLanguageInput
              data={data}
              languageObject={{
                mainKey: "OfficeTranslates",
                objectKeys: [
                  {
                    title: strings.getString("office_name"),
                    key: "OfficeName",
                    required: true,
                  },
                  {
                    title: strings.getString("address"),
                    key: "Address",
                    required: true,
                  },
                ],
              }}
              getLanguageObject={{
                OfficeName: "",
                Address: "",
              }}
            />
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>

          <SimpleItem
            colSpan={2}
            dataField={"Email"}
            label={{
              text: strings.getString("email"),
            }}
          >
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>

          <SimpleItem
            dataField={"Phone"}
            label={{
              text: strings.getString("phone"),
            }}
          >
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>
          <SimpleItem
            dataField={"Flag"}
            label={{
              text: strings.getString("flag"),
            }}
          >
            <FlagSelectLookup
              data={lookup}
              flag={data.Flag}
              onValueChanged={(flag) => (data.Flag = flag)}
            />
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>
          <SimpleItem
            colSpan={2}
            label={{
              text: strings.getString("coordinates"),
            }}
          >
            <RequiredRule message={errorStrings.getString("required")} />
            <GoogleMaps
              lat={parseFloat(data.Latitude.replace(",", "."))}
              lng={parseFloat(data.Longitude.replace(",", "."))}
              setLat={(lat) => (data.Latitude = localizer(lat, culture))}
              setLng={(lng) => (data.Longitude = localizer(lng, culture))}
            />
          </SimpleItem>
        </GroupItem>
      </Form>
    </form>
  );
};

export default EditForm;
