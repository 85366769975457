import { useEffect, useRef, useCallback, useMemo, LegacyRef } from "react";
import TreeView from "devextreme-react/tree-view";
import {
  ATMTnavigation,
  Atnavigation,
  MTnavigation,
  navigation,
} from "../../app-navigation";
import { useNavigation } from "../../contexts/navigation";
import { useScreenSize } from "../../utils/media-query";
import "./side-navigation-menu.scss";

import * as events from "devextreme/events";
import { useSelector } from "react-redux";
import { getLocalizedMenu } from "../../redux/selectors/language-selectors";
import { userSelector } from "../../redux/selectors/auth-selectors";

const SideNavigationMenu = (props: any) => {
  const strings = useSelector(getLocalizedMenu);
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } =
    props;
  const user = useSelector(userSelector);
  const { isLarge } = useScreenSize();
  function normalizePath() {
    if (user?.UserType === "Yönetici") {
      return navigation(strings).map((item) => {
        if (item.path && !/^\//.test(item.path)) {
          item.path = `/${item.path}`;
        }
        return { ...item, expanded: isLarge };
      });
    } else if (user?.UserType === "MT-AT") {
      return ATMTnavigation(strings).map((item) => {
        if (item.path && !/^\//.test(item.path)) {
          item.path = `/${item.path}`;
        }
        return { ...item, expanded: isLarge };
      });
    } else if (user?.UserType === "AT") {
      return Atnavigation(strings).map((item) => {
        if (item.path && !/^\//.test(item.path)) {
          item.path = `/${item.path}`;
        }
        return { ...item, expanded: isLarge };
      });
    } else if (user?.UserType === "MT") {
      return MTnavigation(strings).map((item) => {
        if (item.path && !/^\//.test(item.path)) {
          item.path = `/${item.path}`;
        }
        return { ...item, expanded: isLarge };
      });
    }
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const treeViewRef = useRef() as LegacyRef<TreeView> | undefined;
  const wrapperRef = useRef();
  const getWrapperRef = useCallback(
    (element) => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, "dxclick");
      }

      wrapperRef.current = element;
      events.on(element, "dxclick", (e: any) => {
        openMenu(e);
      });
    },
    [openMenu]
  );

  useEffect(() => {
    //@ts-ignore
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  return (
    <div
      className={"dx-swatch-additional side-navigation-menu"}
      ref={getWrapperRef}
    >
      {children}
      <div className={"menu-container"}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={"path"}
          selectionMode={"single"}
          focusStateEnabled={false}
          expandEvent={"click"}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={"100%"}
        />
      </div>
    </div>
  );
};

export default SideNavigationMenu;
