import { Button, Form, LoadPanel } from "devextreme-react";
import { GroupItem, RequiredRule, SimpleItem } from "devextreme-react/form";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { BodyType } from "../../redux/actions";
import { getApiParams } from "../../redux/selectors/auth-selectors";
import BaseService from "../../services";
import { SocialMedia } from "./types";
const SocialMediaPage = () => {
  const dataRef = useRef<SocialMedia>({
    Facebook: "",
    Instagram: "",
    Linkedin: "",
    Twitter: "",
    Youtube: "",
  });

  const { token, lang } = useSelector(getApiParams);
  const baseService = new BaseService();
  const [loader, setLoader] = useState(false);
  const getData = async () => {
    setLoader(true);
    const response: any | null = await baseService.sendRequest(
      "/HomePage/GetSocialMediaLinks",
      "GET",
      token,
      lang,
      BodyType.formdata
    );
    dataRef.current = response;
    setLoader(false);
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);
  return (
    <div
      style={{
        marginBlockStart: 5,
        marginInlineEnd: 50,
        padding: "50px",
        backgroundColor: "#f7f7f7",
      }}
    >
      <LoadPanel visible={loader} />
      <form>
        <Form
          key="id"
          labelLocation="top"
          formData={dataRef.current}
          width={"100%"}
          height={"100%"}
          showColonAfterLabel={false}
          validationGroup="officeEditFormValidationGroup"
        >
          <GroupItem colCount={2}>
            <SimpleItem
              dataField={"Facebook"}
              label={{
                text: "Facebook",
              }}
            >
              <RequiredRule message={"Zorunlu"} />
            </SimpleItem>
            <SimpleItem
              dataField={"Instagram"}
              label={{
                text: "Instagram",
              }}
            >
              <RequiredRule message={"Zorunlu"} />
            </SimpleItem>
            <SimpleItem
              dataField={"Linkedin"}
              label={{
                text: "Linkedin",
              }}
            >
              <RequiredRule message={"Zorunlu"} />
            </SimpleItem>
            <SimpleItem
              dataField={"Twitter"}
              label={{
                text: "Twitter",
              }}
            >
              <RequiredRule message={"Zorunlu"} />
            </SimpleItem>
            <SimpleItem
              dataField={"Youtube"}
              label={{
                text: "Youtube",
              }}
            >
              <RequiredRule message={"Zorunlu"} />
            </SimpleItem>
          </GroupItem>
        </Form>
      </form>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          marginBlock: 20,
        }}
      >
        <Button
          className="datagrid-action-button"
          type="default"
          text={"Kaydet"}
          stylingMode="contained"
          onClick={async () => {
            await baseService.sendRequestWithNotify(
              "/Portal/UpdateSocialmediaLinks",
              "PUT",
              token,
              lang,
              dataRef.current,
              BodyType.formdata
            );
          }}
        />
      </div>
    </div>
  );
};
export default SocialMediaPage;
