import Form, { GroupItem, SimpleItem } from "devextreme-react/form";
import { useSelector } from "react-redux";
import { MultiLanguageInput } from "../../components";
import { getLocalizedComponents } from "../../redux/selectors/language-selectors";
import { OfficeDetailModel } from "../../types/models/offices/office-model";
const EditForm = ({ data }: { data: OfficeDetailModel }) => {
  const strings = useSelector(getLocalizedComponents);

  return (
    <form>
      <Form
        key="id"
        defaultFormData={data}
        labelLocation="top"
        formData={data}
        width={"100%"}
        height={"100%"}
        showColonAfterLabel={false}
        validationGroup="editSSSvalidation"
      >
        <GroupItem>
          <SimpleItem>
            <MultiLanguageInput
              data={data}
              languageObject={{
                mainKey: "SSSTranslates",
                objectKeys: [
                  {
                    title: strings.getString("question"),
                    key: "Question",
                    required: true,
                  },
                  {
                    title: strings.getString("reply"),
                    key: "Reply",
                    required: true,
                  },
                ],
              }}
              getLanguageObject={{
                Question: "",
                Reply: "",
              }}
            />
          </SimpleItem>
        </GroupItem>
      </Form>
    </form>
  );
};

export default EditForm;
