import React from "react";
import { useSelector } from "react-redux";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { getLocalizedTenders } from "../../../redux/selectors/language-selectors";
import {
  IncDescAuctionDetailDto,
  IncDescTenderPriceDto,
} from "../../../redux/tenders/type";
const IncPriceTab = ({
  auction,
  prices,
}: {
  auction: IncDescAuctionDetailDto;
  prices: IncDescTenderPriceDto[];
}) => {
  const t = useSelector(getLocalizedTenders);
  const truckString = (
    week: number | undefined,
    month: number | undefined,
    year: number | undefined
  ) => {
    if (week && week != null) {
      return t.getString("Weekly");
    } else if (month && month != null) {
      return t.getString("Monthly");
    } else if (year && year != null) {
      return t.getString("Yearly");
    } else {
      return "-";
    }
  };
  const truckCount = (
    count: number | undefined,
    week: number | undefined,
    month: number | undefined,
    year: number | undefined
  ) => {
    if (count && count != null) {
      return count;
    } else if (week && week != null) {
      return week;
    } else if (month && month != null) {
      return month;
    } else if (year && year != null) {
      return month;
    } else {
      return 0;
    }
  };
  return (
    <React.Fragment>
      <div className="row-price">
        <div className="col-price">
          <div> {t.getString("Loading Location")}</div>
          <div>{auction.loadpoint}</div>
        </div>
        <div className="col-price">
          <div>{t.getString("UnLoading Location")}</div>
          <div>{auction.unloadpoint}</div>
        </div>
        <div className="col-price">
          <div>{t.getString("Transit Time")}</div>
          <div>{auction.transittime ?? "-"}</div>
        </div>
        <div className="col-price">
          <div>{t.getString("Load Type")}</div>
          <div>{auction.loadtype}</div>
        </div>
        <div className="col-price">
          <div>{t.getString("Vehicle Count")}</div>
          <div>
            <span style={{ fontSize: 10 }}>
              (
              {truckString(
                auction.NumberOfTrucksPerWeek__c,
                auction.NumberOfTrucksPerMonth__c,
                auction.NumberOfTrucksPerYear__c
              )}
              )
            </span>
          </div>
          <div>
            {truckCount(
              undefined,
              auction.NumberOfTrucksPerWeek__c,
              auction.NumberOfTrucksPerMonth__c,
              auction.NumberOfTrucksPerYear__c
            )}
            {t.getString("Vehicle")}
          </div>{" "}
          {/* (
          {auction.VehicleTypeList.map((x) => (
            <span key={x.id} style={{ fontSize: "12px" }}>
              {x.vehicleType}
            </span>
          ))}
          ) */}
        </div>
      </div>

      <Table className="borderedTable">
        <Thead>
          <Tr>
            <Th>{t.getString("Account Name")}</Th>
            <Th>{t.getString("Loading Location")}</Th>
            <Th>{t.getString("UnLoading Location")}</Th>
            <Th>{t.getString("Transit Time")}</Th>
            <Th>{t.getString("Navlun")}</Th>
            <Th>{t.getString("Vehicle Count")}</Th>
            <Th>{t.getString("Express Navlun")}</Th>
            <Th>{t.getString("Express Transit Time")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {prices.map((x, i) => {
            return (
              <Tr key={i}>
                <Td>{x.accountname ?? "-"}</Td>
                <Td>{auction.loadpoint ?? "-"}</Td>
                <Td>{auction.unloadpoint ?? "-"}</Td>
                <Td>{x.tender_transittime__c ?? "-"}</Td>
                <Td>
                  {x.pricefromshipper__c ?? "-"} {x.currencyisocode}
                </Td>
                <Td>
                  {truckCount(
                    x.numberofvehiclesoftheshipper__c,
                    x.NumberOfTrucksPerWeek__c,
                    x.NumberOfTrucksPerMonth__c,
                    x.NumberOfTrucksPerYear__c
                  )}
                  <div style={{ fontSize: 10 }}>
                    (
                    {truckString(
                      x.NumberOfTrucksPerWeek__c,
                      x.NumberOfTrucksPerMonth__c,
                      x.NumberOfTrucksPerYear__c
                    )}
                    )
                  </div>
                </Td>

                <Td>
                  {x.tender_expressfreight__c ?? "-"} {x.currencyisocode}
                </Td>
                <Td>{x.tender_expresstransittime__c ?? "-"}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </React.Fragment>
  );
};
export default IncPriceTab;
