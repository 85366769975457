import { all } from "redux-saga/effects";
import authnewSaga from "../auth/saga";
import auctionSaga from "../tenders/saga";

import authSaga from "./auth-saga";
import genericSaga from "./generic-saga";

export function* rootSaga() {
  yield all([...authSaga, ...genericSaga, ...auctionSaga, ...authnewSaga]);
}
