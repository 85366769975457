/**
 * OTOPLAN
 * https://komutteknolojisi.com
 *
 * @author Mehmet Polat KOÇAK <mehmet@komutteknolojisi.com>
 * @format
 * @flow strict-local
 */

import { produce } from "immer";
import { LanguageReducer } from "./reducerTypes/language-reducer";

const initialState: LanguageReducer = require("../../utils/localization.json");
const languageReducer = produce((draft: LanguageReducer, action: any) => {},
initialState);

export default languageReducer;
