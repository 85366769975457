import Form, {
  GroupItem,
  RequiredRule,
  SimpleItem,
} from "devextreme-react/form";
import { useSelector } from "react-redux";
import { ArticleDetailsModel } from "../../types/models/articles/article-model";
import {
  getLocalizedArticles,
  getLocalizedErrors,
} from "../../redux/selectors/language-selectors";
import { MultiLanguageInput } from "../../components";

const EditForm = ({ data }: { data: ArticleDetailsModel }) => {
  const strings = useSelector(getLocalizedArticles);
  const errorStrings = useSelector(getLocalizedErrors);

  return (
    <form>
      <Form
        labelLocation="top"
        formData={data}
        showColonAfterLabel={true}
        validationGroup="articleEditFormValidation"
      >
        <GroupItem>
          <SimpleItem
            dataField={"ArticleName"}
            label={{
              text: strings.getString("articlename"),
            }}
          >
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>
          <SimpleItem>
            <MultiLanguageInput
              data={data}
              languageObject={{
                mainKey: "articleTranslates",
                objectKeys: [
                  {
                    title: strings.getString("title"),
                    key: "Title",
                    required: true,
                  },
                  {
                    title: strings.getString("description"),
                    key: "Description",
                    editorType: "dxHtmlEditor",
                    required: true,
                  },
                ],
              }}
            />
          </SimpleItem>
        </GroupItem>
      </Form>
    </form>
  );
};

export default EditForm;
