import { DashboardCardObject } from "../../types/components/dashboard-card-object";

export const dealData: DashboardCardObject[] = [
  { id: "1", number: 221, title: "Deal Count" },
  { id: "2", number: 98, title: "Deal Proposed" },
  { id: "3", number: 2, title: "Deal Approved" },
  { id: "4", number: 2, title: "On Rent" },
  { id: "5", number: 32, title: "Deal Completed" },
  { id: "6", number: 7, title: "Rejected" },
  { id: "7", number: 70, title: "Cancelled" },
];

export const customerActivitiesData: DashboardCardObject[] = [
  { id: "1", number: 568, title: "Active User", icon: "group" },
  { id: "2", number: 3183, title: "Not Registered User", icon: "key" },
  { id: "3", number: 0, title: "Pending Approval", icon: "checklist" },
  { id: "4", number: 102, title: "Active Ads", icon: "doc", color: "#17a2b8" },
  {
    id: "5",
    number: 0,
    title: "Complaint Count",
    icon: "orderedlist",
    color: "#28a745",
  },
  {
    id: "6",
    number: 846,
    title: "Messages Count",
    icon: "message",
    color: "#9F55FF",
  },
];

type ChartObject = {
  name: string;
  value: number;
};

export const totalDealReports: ChartObject[] = [
  { name: "January", value: 4 },
  { name: "February", value: 6 },
  { name: "March", value: 3 },
  { name: "April", value: 8 },
  { name: "May", value: 9 },
  { name: "June", value: 1 },
  { name: "July", value: 12 },
  { name: "August", value: 37 },
  { name: "September", value: 8 },
  { name: "October", value: 21 },
  { name: "November", value: 20 },
  { name: "December", value: 31 },
];

export const totalAmountReports: ChartObject[] = [
  { name: "January", value: 4000 },
  { name: "February", value: 2000 },
  { name: "March", value: 3500 },
  { name: "April", value: 88000 },
  { name: "May", value: 12000 },
  { name: "June", value: 19000 },
  { name: "July", value: 75500 },
  { name: "August", value: 9000 },
  { name: "September", value: 100 },
  { name: "October", value: 21000 },
  { name: "November", value: 2000 },
  { name: "December", value: 31000 },
];

export const totalCommissionReports: ChartObject[] = [
  { name: "January", value: 12 },
  { name: "February", value: 3 },
  { name: "March", value: 8 },
  { name: "April", value: 9 },
  { name: "May", value: 9 },
  { name: "June", value: 10 },
  { name: "July", value: 2 },
  { name: "August", value: 3 },
  { name: "September", value: 6 },
  { name: "October", value: 20 },
  { name: "November", value: 11 },
  { name: "December", value: 17 },
];
