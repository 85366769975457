import { Button, LoadPanel } from "devextreme-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLocalizedTenders } from "../../../redux/selectors/language-selectors";
import {
  getAuctionDetails,
  getAuctionDetailsPriceList,
  sendTransferTender,
} from "../../../redux/tenders/saga";
import { AuctionDetailDto, AuctionPriceDto } from "../../../redux/tenders/type";
import DetailTab from "./Detail";
import { confirm } from "devextreme/ui/dialog";
import TenderPrices from "./PriceTab";
import notify from "devextreme/ui/notify";

const AuctionDetailPage = () => {
  const dispatch = useDispatch();
  const t = useSelector(getLocalizedTenders);
  const [loader, setLoader] = useState(false);
  const { id }: { id: string } = useParams();
  const [active, setActive] = useState("1");
  const [auction, setAuction] = useState<AuctionDetailDto>();
  const [prices, setPrices] = useState<AuctionPriceDto[]>();
  const toggleMenu = (tab: string) => {
    setActive(tab);
  };
  useEffect(() => {
    setLoader(true);
    dispatch(
      getAuctionDetails({
        payload: {
          onSuccess: (m, payload) => {
            setLoader(false);
            payload && payload.length > 0 && setAuction(payload[0]);
          },
          onError: () => {
            setLoader(false);
          },
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  useEffect(() => {
    active === "2" &&
      dispatch(
        getAuctionDetailsPriceList({
          payload: {
            onSuccess: (m, payload) => setPrices(payload),
            onError: () => {},
          },
          id: id,
        })
      );
  }, [dispatch, active, id]);

  return (
    <React.Fragment>
      <LoadPanel visible={loader} />
      {auction && (
        <div style={{ backgroundColor: "white", padding: "10px 30px" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {auction.Transfer && (
              <Button
                icon="redo"
                type="success"
                stylingMode="contained"
                text={t.getString("Transfer")}
                onClick={() => {
                  var confirmResp = confirm(
                    t.getString("Transfer Question"),
                    t.getString("Transfer")
                  );
                  confirmResp.then((result) => {
                    console.log(result, "RESULT");
                    if (result) {
                      setLoader(true);
                      dispatch(
                        sendTransferTender({
                          payload: {
                            onSuccess: (m, payload) => {
                              notify(
                                t.getString("Transfer successed"),
                                "success",
                                5000
                              );
                              dispatch(
                                getAuctionDetails({
                                  payload: {
                                    onSuccess: (m, payload) => {
                                      setLoader(false);
                                      payload &&
                                        payload.length > 0 &&
                                        setAuction(payload[0]);
                                    },
                                    onError: () => {
                                      setLoader(false);
                                    },
                                  },
                                  id: id,
                                })
                              );
                            },
                            onError: () => {},
                          },
                          body: {
                            sfid: auction.sfid,
                          },
                        })
                      );
                    } else {
                      setLoader(false);
                    }
                  });
                }}
              />
            )}
          </div>
          <div className="mainWidth auctionPage">
            <div className="">
              <div className="headingStyle" style={{ marginBlockEnd: 5 }}>
                {auction.tender_no__c} /{" "}
                {t.getString(auction.Opportunity_Tender_Type__c)}
              </div>
            </div>
            <div className="row">
              <div className="col2">
                <div
                  className={
                    " auctionMenuItem" +
                    (active === "1" ? " activeMenuItem" : "")
                  }
                  onClick={() => toggleMenu("1")}
                  style={{ textAlign: "center" }}
                >
                  <span className="auctionMenuItemTitle">
                    {t.getString("Tender Informations")}
                  </span>
                </div>
              </div>
              <div className="col2">
                <div
                  className={
                    "auctionMenuItem" +
                    (active === "2" ? " activeMenuItem" : "")
                  }
                  onClick={() => toggleMenu("2")}
                  style={{ textAlign: "center" }}
                >
                  <span className="auctionMenuItemTitle">
                    {t.getString("Price Table")}
                  </span>
                </div>
              </div>
            </div>

            {active === "1" && <DetailTab auction={auction} />}
            {active === "2" && prices && (
              <TenderPrices auction={auction} prices={prices} />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default AuctionDetailPage;
