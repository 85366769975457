const defaultUser = {
  email: "sandra@example.com",
  avatarUrl:
    "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png",
};

export default defaultUser;
export const color = {
  primary: "#0B5CAB",
  primarySofter: "#095197",
  secondary: "#F7F7F7",
  side: "#00A3E0",
  side2: "#005EA8",
  side3: "#0B827C",
  accent: "#FFFFFF",
  black: "#181818",
  blackFifty: "#18181880",
  pageBgMainColor: "#ffffff",
  pageBgColor: "#fcfcfc",
  bgColor: "#181818",
  bgPrimary: "#0B5CAB",
  bgPrimarySoft: "#FEEBEB",
  bgSecondary: "#B7B7B733",
  bgSoft: "#F7F7F7",
  borderColor: "#F0F0F0",
  borderSecondary: "#D8D8D8",
  borderSide: "#E4E8EE",
  error: "#C63235",
  success: "#37A75B",
  warning: "#fccf00",
  warningSoft: "#FFE56E",
  primarySoft: "#FFF7EB",
  errorSoft: "#F7E2E1",
  successSoft: "#F2FFF6",
  textHeader: "#032D60",
  textPrimary: "#181818",
  textSecondary: "#757575",
  textSemi: "#e5e5e5",
  textAccent: "#FFFFFF",
  textSide: "#009FD7",
  transparent: "transparent",
  backdropTransparency: "CC",
  fiftyOpacity: "80",
  thirtyOpacity: "48",
  available: "#0B827C",
  lessAvailable: "#FFF03F",
  notAvailable: "#C63235",
};
