import { useMemo } from "react";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import "./user-panel.scss";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../redux/selectors/auth-selectors";
import { logoutAction } from "../../redux/actions/auth-actions";
import { imageUrl } from "../../utils/urls";

export default function UserPanel({
  menuMode,
}: {
  menuMode: "context" | "list";
}) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  // function navigateToProfile() {
  //   history.push("/profile");
  // }
  const menuItems = useMemo(
    () => [
      // {
      //   text: "Profile",
      //   icon: "user",
      //   onClick: navigateToProfile,
      // },
      {
        text: "Logout",
        icon: "runner",
        onClick: () => dispatch(logoutAction()),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logoutAction]
  );

  return (
    <div className={"user-panel"}>
      <div className={"user-info"}>
        <div className={"image-container"}>
          {/* <div
            style={{
              background: `url(${user?.profilePicture}) no-repeat #fff`,
              backgroundSize: "cover",
            }}
            className={"user-image"}
          /> */}
          <img
            className={"user-image"}
            src={imageUrl + user?.ProfileImage}
            alt=""
          />
        </div>
        <div className={"user-name"}>{user?.NameSurname}</div>
      </div>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          cssClass={"user-menu"}
        >
          <Position my={"top center"} at={"bottom center"} />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
    </div>
  );
}
