export interface VehicleChatMessageReceiveObject {
  id: string;
  message: string;
  userId: string;
  createTime: string;
  profileImage: string;
  read: boolean;
  userName: string;
  vehicle_opp_destination__c: string;
}
export interface ChatDto {
  messageData: ContractMessage[];
  vehicle_opp_destination__c: string;
  destinationname: string;
  contact__c: string;
  recordtypeid?: any;
  name?: any;
  messagetext__c?: any;
  isdeleted?: any;
  opportunity__c?: any;
  systemmodstamp?: any;
  messagejson__c: string;
  createddate?: any;
  user__c: string;
  sfid?: any;
  id: string;
  _hc_lastop: string;
  _hc_err?: any;
}
export const formatChatMessageReceive = (
  m: VehicleChatMessageReceiveObject
): ContractMessage => {
  return {
    Message: m.message,
    CreateTime: m.createTime,
    ProfileImage: m.profileImage,
    UserId: m.userId,
    UserName: m.userName,
    Read: m.read,
  };
};
export interface ContractMessage {
  Message: string;
  CreateTime: string;
  ProfileImage: string;
  UserId: string;
  UserName: string;
  Read: boolean;
}
export interface VehicleChatSendObject {
  Message: string;
  vehicle_opp_destination__c?: string;
  user__c?: string;
  contact__c?: string;
  id: string;
}

export interface VehicleOpportunityDto {
  id: string;
  currencyisocode: string;
  parentsfid: string;
  opdfilenumber: string;
  destination_vehicletype_vehicleopp__c: string;
  accountid: string;
  rapidadvisorname: string;
  rapidadvisorId: string;
  filenumber: string;
  trucktypeid: string;
  trucktypename: string;
  loadcountrycode: string;
  tocountrycode: string;
  estimatedamount?: any;
  lastofferdate: Date;
  estimatedarrivaltime: string;
  distanceloadpoint?: any;
  weight?: any;
  volume?: any;
  vehiclelength?: any;
  vehiclewidth?: any;
  vehicleheight?: any;
  equipments?: any;
  loadingtype?: any;
  unloadingtype?: any;
  thirdcountrycertificate: boolean;
  thirdcountryname?: any;
  trucktransitdeclarationtype?: any;
  seconddrivercheckbox: boolean;
  loadpoint: string;
  unloadpoint: string;
  shipperofferprice?: any;
  RouteTab?: any;
  Owner?: any;
}
export interface VehicleUserInfoDto {
  _localizer: Localizer;
  UserID: string;
  FirstName: string;
  LastName: string;
  Email: string;
  MobilePhone: string;
  Phone: string;
  AccountId: string;
  Account: Account;
  RapidAdvisor?: any;
  Role: string;
  CommunicationLanguage: string;
  ProposalResponsible: boolean;
  Token?: any;
  OwnerId: string;
  AccountAdmin: boolean;
  AccountApproved: boolean;
  WebApprovalStatus: string;
  ReasonForRejected: string;
  profile_information_completed__c: boolean;
  AccountClassPoint: number;
  AccountClass: string;
  ProfilePercentage: number;
  AccountType: AccountType;
}

interface AccountType {
  Code: string;
  Name: string;
}

interface Account {
  web_approval_status__c: string;
  companyemail__c: string;
  recordtypeid: string;
  billingcity?: any;
  name: string;
  availabletruck__c: boolean;
  phone: string;
  ownerid: string;
  taxnumber__c: string;
  isdeleted: boolean;
  systemmodstamp: string;
  billingpostalcode?: any;
  external_id__c: string;
  createddate: string;
  website: string;
  billingcountry?: any;
  taxoffice__c: string;
  billingstreet?: any;
  sfid: string;
  id: number;
  _hc_lastop: string;
  _hc_err?: any;
  accountsource: string;
  communicationpreference__c: string;
  origin__c?: any;
  industry: string;
  originpicklist__c: string;
  shippingstate: string;
  shippingpostalcode: string;
  shippingcountry: string;
  shippingstreet: string;
  shippingcity: string;
  accountclass__c?: any;
  reason_for_rejected__c?: any;
  profile_information_completed__c: boolean;
  accountscore__c?: any;
  reg_from_web__c: boolean;
}

interface Localizer {}
