/**
 * OTOPLAN
 * https://komutteknolojisi.com
 *
 * @author Mehmet Polat KOÇAK <mehmet@komutteknolojisi.com>
 * @format
 * @flow strict-local
 */

import { createAction } from "redux-smart-actions";
import { LanguageModel } from "../../types/models/system/system-models";

/**
 * GETTERS
 */

/**
 * SETTERS
 */
export const setLanguage = createAction(
  "SET_LANGUAGE",
  (payload: LanguageModel) => ({ payload })
);

export const setLanguages = createAction(
  "SET_LANGUAGES",
  (payload: LanguageModel[]) => ({
    payload,
  })
);
