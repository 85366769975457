import { put, takeLatest } from "@redux-saga/core/effects";
import { ApiCallback } from "../../types/models/base-model";
import { hideLoader, showLoader } from "../actions/app-actions";
import * as A from "../actions/auth-actions";
import { LoginInputs } from "../../types/models/auth/login-input";
import { LoginResponseModel } from "../../types/models/auth/login-response";

export interface LoginInputParams extends ApiCallback<LoginResponseModel> {
  params: LoginInputs;
}

function* loginSaga({ payload }: { type: string; payload: LoginInputParams }) {
  yield put(showLoader());

  yield put(hideLoader());
}

function* watcher() {
  yield takeLatest(A.loginAction.toString(), loginSaga);
}

const authSaga = [watcher()];

export default authSaga;
