import { forwardRef } from "react";
import Form, {
  GroupItem,
  RequiredRule,
  SimpleItem,
} from "devextreme-react/form";
import { useSelector } from "react-redux";
import {
  getLocalizedBlogs,
  getLocalizedErrors,
} from "../../redux/selectors/language-selectors";
import { BlogDetailModel } from "../../types/models/blogs/blog-model";
import { FileUploader } from "../../components";
import { MultiLanguageInput } from "../../components";

const EditForm = forwardRef<any, { data: BlogDetailModel }>(({ data }, ref) => {
  const strings = useSelector(getLocalizedBlogs);
  const errorStrings = useSelector(getLocalizedErrors);

  return (
    <form>
      <Form
        ref={ref}
        key="id"
        labelLocation="top"
        formData={data}
        showColonAfterLabel={true}
        validationGroup="blogCreateFormValidation"
      >
        <GroupItem>
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="SmallImage"
              label={{
                text: strings.getString("small_image"),
              }}
            >
              <FileUploader
                buttonText={strings.getString("select_photo")}
                onValueChanged={(values) => (data.SmallImage = values[0])}
              />
              <RequiredRule message={errorStrings.getString("required")} />
            </SimpleItem>
            <SimpleItem
              dataField="BigImage"
              label={{
                text: strings.getString("big_image"),
              }}
            >
              <FileUploader
                buttonText={strings.getString("select_photo")}
                onValueChanged={(values) => (data.BigImage = values[0])}
              />
              <RequiredRule message={errorStrings.getString("required")} />
            </SimpleItem>
          </GroupItem>
          <SimpleItem
            dataField="Owner"
            label={{
              text: strings.getString("owner"),
            }}
          >
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>
          <SimpleItem
            dataField="CreateDate"
            label={{
              text: strings.getString("date"),
            }}
            editorType="dxDateBox"
            editorOptions={{ dateSerializationFormat: "yyyy-MM-dd" }}
          >
            <RequiredRule message={errorStrings.getString("required")} />
          </SimpleItem>
          <SimpleItem>
            <MultiLanguageInput
              data={data}
              languageObject={{
                mainKey: "BlogTranslates",
                objectKeys: [
                  {
                    title: strings.getString("title"),
                    key: "Title",
                    required: true,
                  },
                  {
                    title: strings.getString("short_description"),
                    key: "ShortDescription",
                    required: true,
                  },
                  {
                    title: strings.getString("description"),
                    key: "Description",
                    editorType: "dxHtmlEditor",
                    required: true,
                  },
                ],
              }}
              getLanguageObject={{
                Description: "",
                ShortDescription: "",
                Title: "",
              }}
            />
          </SimpleItem>
        </GroupItem>
      </Form>
    </form>
  );
});

export default EditForm;
