import moment from "moment";
import { useSelector } from "react-redux";
import {
  getLocalizedComponents,
  getLocalizedTenders,
} from "../../redux/selectors/language-selectors";
import { AuctionDetailDto, TenderChatDto } from "../../redux/tenders/type";
import { color } from "../../utils/default-user";
import { salesforceUrl } from "../../utils/urls";
import { OpportunityUserInfoDto } from "./type";

const TenderChatCard = ({
  tender,
  userInfo,
  chatDto,
}: {
  tender: AuctionDetailDto;
  userInfo: OpportunityUserInfoDto;
  chatDto: TenderChatDto;
}) => {
  const strings = useSelector(getLocalizedComponents);
  const tenderstrings = useSelector(getLocalizedTenders);

  return (
    <div
      style={{
        width: `100% `,
        border: "1px solid",
        borderColor: color.borderSide,
        borderRadius: 6,
        boxShadow: "20px 20px 50px #0047A412",
        paddingInline: 30,
        paddingBlock: 10,
        fontSize: 12,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: 50,
          width: "100%",
        }}
      >
        <div
          className="smallHeadingStyle"
          style={{
            width: "100%",
            fontWeight: 700,
            color: color.textHeader,
            marginBlockEnd: 5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p
              className="mediumHeadingStyle"
              style={{ color: color.textHeader }}
            >
              {userInfo.FirstName} {userInfo.LastName} / {userInfo.Account.name}
            </p>
            <div
              onClick={() => {
                window.open(salesforceUrl + tender.sfid + "/view");
              }}
              style={{
                color: color.primary,
                backgroundColor: color.bgSoft,
                textDecoration: "none",
                cursor: "pointer",
                fontSize: 12,
                fontWeight: 700,
                borderColor: color.bgSoft,
                padding: 5,
                borderRadius: 10,
              }}
            >
              Salesforce↗
            </div>
          </div>
          <span
            className="smallText"
            style={{ marginBlockEnd: 7, marginBlockStart: -5 }}
          >
            <span
              style={{
                color: color.textHeader + color.fiftyOpacity,
              }}
            >
              {strings.getString("Communication Language")}:{" "}
            </span>
            {userInfo.CommunicationLanguage}
          </span>
          <div style={{ width: "100%", height: 40, display: "flex" }}>
            <div>
              <span
                className="smallText"
                style={{
                  color: color.textHeader + color.fiftyOpacity,
                }}
              >
                {tenderstrings.getString("Tender No")}
              </span>
              <p>{tender.tender_no__c}</p>
            </div>
            <div
              style={{
                height: "100%",
                width: 1,
                marginInline: 10,
                backgroundColor: color.bgSecondary,
              }}
            />
            <div>
              <span
                className="smallText"
                style={{
                  color: color.textHeader + color.fiftyOpacity,
                }}
              >
                {tenderstrings.getString("Tender Type")}
              </span>
              <p>{tender.Opportunity_Tender_Type__c}</p>
            </div>
            <div
              style={{
                height: "100%",
                width: 1,
                marginInline: 10,
                backgroundColor: color.bgSecondary,
              }}
            />
            <div>
              <span
                className="smallText"
                style={{
                  color: color.textHeader + color.fiftyOpacity,
                }}
              >
                {tenderstrings.getString("Tender Start Date")}
              </span>
              <p>
                <span>
                  <p>
                    {tender.Tender_Start_Date__c
                      ? moment
                          .utc(tender.Tender_Start_Date__c)
                          .format("DD/MM/yyyy HH:mm")
                      : "-"}
                  </p>
                </span>
              </p>
            </div>
            <div
              style={{
                height: "100%",
                width: 1,
                marginInline: 10,
                backgroundColor: color.bgSecondary,
              }}
            />
            <div>
              <span
                className="smallText"
                style={{
                  color: color.textHeader + color.fiftyOpacity,
                }}
              >
                {tenderstrings.getString("Tender End Date")}
              </span>
              <p>
                {tender.Tender_End_Date__c
                  ? moment
                      .utc(tender.Tender_End_Date__c)
                      .format("DD/MM/yyyy HH:mm")
                  : "-"}
              </p>
            </div>
            <div
              style={{
                height: "100%",
                width: 1,
                marginInline: 10,
                backgroundColor: color.bgSecondary,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default TenderChatCard;
