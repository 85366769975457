import {
  customerActivitiesData,
  dealData,
  totalAmountReports,
  totalCommissionReports,
  totalDealReports,
} from "./data";
import "./style.scss";

import { Chart } from "../../components";

function Dashboard() {
  return (
    <div style={{ paddingBlock: 20 }}>
      <div className={"content-flex"}>
        {dealData.map((data) => (
          <div key={data.id} className={"dx-card small-responsive-paddings"}>
            <p>{data.title}</p>
            <p className="h4">{data.number}</p>
          </div>
        ))}
      </div>
      <div className={"content-flex"}>
        {customerActivitiesData.map((data) => (
          <div
            key={data.id}
            className={"dx-card small-responsive-paddings"}
            style={{ color: data.color }}
          >
            <i
              className={`dx-icon dx-icon-${data.icon}`}
              style={{ fontSize: 40 }}
            />
            <p style={{ marginBlockEnd: 10 }}>{data.title}</p>
            <p className="h4">{data.number}</p>
          </div>
        ))}
      </div>
      <div className={"content-flex"}>
        <div className={"dx-card small-responsive-paddings"}>
          {<Chart color="#709cc1" dataSource={totalDealReports} />}
        </div>
        <div className={"dx-card small-responsive-paddings"}>
          {<Chart color="#ff5722" dataSource={totalAmountReports} />}
        </div>
        <div className={"dx-card small-responsive-paddings"}>
          {<Chart color="#709cc1" dataSource={totalCommissionReports} />}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
