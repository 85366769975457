import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataGrid } from "../../components";
import { getLocalizedTenders } from "../../redux/selectors/language-selectors";

function IncDescWaitingTenderPage() {
  const history = useHistory();
  const strings = useSelector(getLocalizedTenders);
  const columns = [
    {
      dataField: "opdtender_no__c",
      caption: strings.getString("Tender No"),
    },
    {
      dataField: "accountname",
      caption: strings.getString("Account Name"),
    },
    {
      dataField: "transportertendertype__c",
      caption: strings.getString("Tender Type"),
    },
    {
      dataField: "tenderstartdatetime__c",
      caption: strings.getString("Tender Start Date"),
      dataType: "datetime",
    },
    {
      dataField: "tenderenddatetime__c",
      caption: strings.getString("Tender End Date"),
      dataType: "datetime",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: (e: any) => {
            history.push("/inc-desc-tender/" + e.row.data.id);
          },
        },
      ],
    },
  ];
  return (
    <DataGrid
      keyField="id"
      url={"Portal/GetTransferWaitingRaiseReductionTenders?_skip=0&&_take=1000"}
      columns={columns || []}
      refObject={{}}
    />
  );
}
export default IncDescWaitingTenderPage;
