import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutAction } from "../../redux/actions/auth-actions";

const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(logoutAction());
    setTimeout(() => {
      history.push("/#/login");
    }, 200);
  });
  return <div></div>;
};
export default Logout;
