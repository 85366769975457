/**
 * OTOPLAN
 * https://komutteknolojisi.com
 *
 * @author Mehmet Polat KOÇAK <mehmet@komutteknolojisi.com>
 * @format
 * @flow strict-local
 */

import produce from "immer";
import { SystemReducer } from "./reducerTypes/system-reducer";
import { setLanguage, setLanguages } from "../actions/system-actions";

const initialState: SystemReducer = {
  lang: {
    guid: "C8A6619F-C78F-4505-9344-2A516A395B92",
    language: "Türkçe",
    culture: "tr-TR",
    flag: "tr",
  },
  languages: undefined,
};
const systemReducer = produce((draft: SystemReducer, action) => {
  switch (action.type) {
    case setLanguage.toString():
      draft.lang = action.payload;
      break;
    case setLanguages.toString():
      draft.languages = action.payload;
      break;
  }
}, initialState);

export default systemReducer;
