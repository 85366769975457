import { Popup, ToolbarItem } from "devextreme-react/popup";
import { IButtonOptions } from "devextreme-react/button";
import ScrollView from "devextreme-react/scroll-view";
import "./style.scss";
import { useSelector } from "react-redux";
import { getLocalizedComponents } from "../../redux/selectors/language-selectors";

const confirmButtonOptions = {
  type: "default",
  useSubmitBehavior: true,
};

type ModalProps = {
  visible: boolean;
  onHiding: () => void;
  title: string;
  children: any;
  onSubmitClick: () => void;
  height?: string | number;
  width?: string | number;
  validationGroup?: string;
  buttonTitle?: string;
  showButton?: boolean;
  coverModal?: boolean;
};

const Modal = ({
  visible = false,
  onHiding,
  title,
  children,
  onSubmitClick,
  height,
  width = 900,
  validationGroup,
  buttonTitle,
  showButton = true,
  coverModal = false,
}: ModalProps) => {
  const strings = useSelector(getLocalizedComponents);

  const validateGroup = (e: any) => {
    if (validationGroup) {
      const res = e.validationGroup?.validate();
      if (res) res.status === "valid" && onSubmitClick();
    } else {
      onSubmitClick();
    }
  };

  return (
    <Popup
      className={coverModal ? "cover-modal" : ""}
      visible={visible}
      onHiding={onHiding}
      dragEnabled={true}
      closeOnOutsideClick={false}
      showCloseButton={true}
      showTitle={true}
      title={title}
      width={width}
      height={height}
    >
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={
          {
            text: strings.getString("close"),
            onClick: onHiding,
          } as IButtonOptions
        }
      />
      <ToolbarItem
        visible={showButton}
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={
          {
            ...confirmButtonOptions,
            text: buttonTitle || strings.getString("save"),
            onClick: validateGroup,
            validationGroup: validationGroup || "",
          } as IButtonOptions
        }
      />
      {coverModal ? children : <ScrollView>{children}</ScrollView>}
    </Popup>
  );
};

export default Modal;
