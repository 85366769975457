import { Fragment, useEffect, useRef, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import appInfo from "./app-info";
import routes from "./app-routes";
import { SideNavInnerToolbar as SideNavBarLayout } from "./layouts";
import { useSelector } from "react-redux";
import { getLocalizedMenu } from "./redux/selectors/language-selectors";
import { toast, ToastContainer } from "react-toastify";
import { getApiParams, userSelector } from "./redux/selectors/auth-selectors";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { chatUrl } from "./utils/urls";
import { ChatMessageReceiveObject } from "./pages/chat/type";
import { VehicleChatMessageReceiveObject } from "./pages/vehicle-chat/type";
import Error404 from "./components/error";
import { TenderChatMessageReceiveObject } from "./pages/tender-chat/type";

const VEHICLEHUB_ENDPOINT = chatUrl + "api/vehiclechat";
const tenderHUB_ENDPOINT = chatUrl + "api/tenderchat";
const INCTENDERHUB_ENDPOINT = chatUrl + "api/raisereductiontenderchat";
const HUB_ENDPOINT = chatUrl + "api/chat";
export default function Content() {
  const location = useLocation();
  const history = useHistory();
  const { lang, token } = useSelector(getApiParams);
  const user = useSelector(userSelector);
  const idRef = useRef<string>();
  const tenderRef = useRef<string>();
  const inctenderRef = useRef<string>();
  const vehicleidRef = useRef<string>();
  const connectionRef = useRef<HubConnection | null>(null); // eslint-disable-next-line
  const [connectionStatus, setConnectionStatus] = useState<
    "offline" | "connecting" | "connected" | "failed"
  >("offline");
  const strings = useSelector(getLocalizedMenu);
  const connectChat = (userId: string, tokenRes: string) => {
    if (connectionRef.current !== undefined) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(HUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => tokenRes,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();
      connection.serverTimeoutInMilliseconds = 3600000;
      console.log(`Trying to connect to ${HUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`
          NOTIFICATION Connected to ${HUB_ENDPOINT}`);
          setConnectionStatus("connected");
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(`Disconnected from ${HUB_ENDPOINT}`);
        // connectionRef.current = null;
        // console.log(`Trying reconnect from ${HUB_ENDPOINT}`);
        // connectChat(userId, tokenRes);
      });
      connection.on(userId, function (payload: ChatMessageReceiveObject) {
        const message = strings.formatString(
          strings.getString("messagealert"),
          payload.userName
        );
        console.log(window.location.hash, "WİNDOW");
        if (!window.location.hash.includes("chat")) {
          tenderRef.current = undefined;
          vehicleidRef.current = undefined;
          idRef.current = payload.opportunity_destination__c;
          toast.info(message, {
            theme: "colored",
            toastId: payload.opportunity_destination__c,
          });
        } else {
          const search = location.search;
          search.includes("chatId=")
            ? history.push(search + "&&refresh")
            : history.push("?refresh");
        }
      });
      connectionRef.current = connection;
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
  };
  const connectVehicleChat = (userId: string, tokenRes: string) => {
    if (connectionRef.current !== undefined) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(VEHICLEHUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => tokenRes,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();

      connection.serverTimeoutInMilliseconds = 3600000;
      console.log(`Trying to connect to ${VEHICLEHUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`
          NOTIFICATION Connected to ${VEHICLEHUB_ENDPOINT}`);
          setConnectionStatus("connected");
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(`Disconnected from ${VEHICLEHUB_ENDPOINT}`);
        // connectionRef.current = null;
        // console.log(`Trying reconnect from ${VEHICLEHUB_ENDPOINT}`);
        // connectChat(userId, tokenRes);
      });
      connection.on(
        userId,
        function (payload: VehicleChatMessageReceiveObject) {
          const message = strings.formatString(
            strings.getString("messagealert"),
            payload.userName
          );
          if (!window.location.hash.includes("/vehiclechat")) {
            idRef.current = undefined;
            tenderRef.current = undefined;
            vehicleidRef.current = payload.vehicle_opp_destination__c;
            toast.info(message, {
              theme: "colored",
              toastId: payload.vehicle_opp_destination__c,
            });
          } else {
            const search = location.search;
            search.includes("chatId=")
              ? history.push(search + "&&refresh")
              : history.push("?refresh");
          }
        }
      );
      connectionRef.current = connection;
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
  };

  const connectTenderChat = (userId: string, tokenRes: string) => {
    if (connectionRef.current !== undefined) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(tenderHUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => tokenRes,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();

      connection.serverTimeoutInMilliseconds = 3600000;
      console.log(`Trying to connect to ${tenderHUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`
         TENDER NOTIFICATION Connected to ${tenderHUB_ENDPOINT}`);
          setConnectionStatus("connected");
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(`Disconnected from ${tenderHUB_ENDPOINT}`);
        // connectionRef.current = null;
        // console.log(`Trying reconnect from ${VEHICLEHUB_ENDPOINT}`);
        // connectChat(userId, tokenRes);
      });
      connection.on(userId, function (payload: TenderChatMessageReceiveObject) {
        const message = strings.formatString(
          strings.getString("messagealert"),
          payload.userName
        );
        if (!window.location.hash.includes("/tender-chat")) {
          idRef.current = undefined;
          vehicleidRef.current = undefined;
          tenderRef.current = payload.uniqId;
          toast.info(message, {
            theme: "colored",
            toastId: payload.uniqId,
          });
        } else {
          let chatId = "";
          const urlparams = window.location.hash.split("?");
          let searchparams = "";
          if (urlparams.length > 0) {
            searchparams = "?" + urlparams[1];
            const Id = new URLSearchParams("?" + urlparams[1]).get("chatId");
            chatId = Id ? Id : "";
          }
          if (chatId && payload.uniqId === chatId) {
          } else {
            if (searchparams.includes("chatId")) {
              history.push(searchparams + "&&refresh");
            } else {
              history.push("?refresh");
            }
          }
        }
      });
      connectionRef.current = connection;
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
  };
  const connectIncTenderChat = (userId: string, tokenRes: string) => {
    if (connectionRef.current !== undefined) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(INCTENDERHUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => tokenRes,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();

      connection.serverTimeoutInMilliseconds = 3600000;
      console.log(`Trying to connect to ${INCTENDERHUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`
         INC TENDER NOTIFICATION Connected to ${INCTENDERHUB_ENDPOINT}`);
          setConnectionStatus("connected");
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(`Disconnected from ${INCTENDERHUB_ENDPOINT}`);
        // connectionRef.current = null;
        // console.log(`Trying reconnect from ${VEHICLEHUB_ENDPOINT}`);
        // connectChat(userId, tokenRes);
      });
      connection.on(userId, function (payload: TenderChatMessageReceiveObject) {
        const message = strings.formatString(
          strings.getString("messagealert"),
          payload.userName
        );
        console.log(payload, "PAYLOAD");
        if (!window.location.hash.includes("/tender-chat")) {
          idRef.current = undefined;
          vehicleidRef.current = undefined;
          tenderRef.current = undefined;
          inctenderRef.current = payload.uniqId;
          toast.info(message, {
            theme: "colored",
            toastId: payload.uniqId,
          });
        } else {
          let chatId = "";
          const urlparams = window.location.hash.split("?");
          let searchparams = "";
          if (urlparams.length > 0) {
            searchparams = "?" + urlparams[1];
            const Id = new URLSearchParams("?" + urlparams[1]).get("chatId");
            chatId = Id ? Id : "";
          }
          if (chatId && payload.uniqId === chatId) {
          } else {
            if (searchparams.includes("chatId")) {
              history.push(searchparams + "&&refresh");
            } else {
              history.push("?refresh");
            }
          }
        }
      });
      connectionRef.current = connection;
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
  };
  useEffect(() => {
    user &&
      token &&
      (user.UserType === "Yönetici" ||
        user.UserType === "MT-AT" ||
        user.UserType === "MT") &&
      connectChat(user.AdvisorId, token); // eslint-disable-next-line
    user &&
      token &&
      (user.UserType === "Yönetici" ||
        user.UserType === "MT-AT" ||
        user.UserType === "MT") &&
      connectTenderChat(user.AdvisorId, token); // eslint-disable-next-line
    user &&
      token &&
      (user.UserType === "Yönetici" ||
        user.UserType === "MT-AT" ||
        user.UserType === "MT") &&
      connectIncTenderChat(user.AdvisorId, token); // eslint-disable-next-line
    user &&
      token &&
      (user.UserType === "Yönetici" ||
        user.UserType === "MT-AT" ||
        user.UserType === "AT") &&
      connectVehicleChat(user.AdvisorId, token); // eslint-disable-next-line
  }, []);

  return (
    <SideNavBarLayout title={appInfo.title}>
      <Switch>
        <Fragment>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            onClick={(e) => {
              idRef.current && window.open("/#/chat?chatId=" + idRef.current);
              vehicleidRef.current &&
                window.open("/#/vehiclechat?chatId=" + vehicleidRef.current);
              tenderRef.current &&
                window.open("/#/tender-chat?chatId=" + tenderRef.current);
              inctenderRef.current &&
                window.open("/#/tender-chat?chatId=" + inctenderRef.current);
            }}
          />
          <h2 className={"content-block"}>
            {location.pathname.substr(1).length > 0
              ? strings.getString(location.pathname.substr(1))
              : ""}
          </h2>
          <div style={{ paddingInline: 40 }}>
            {routes.map(({ auth, path, component }) => {
              if (auth) {
                return auth.some((x) => x === user?.UserType) ? (
                  <Route exact key={path} path={path} component={component} />
                ) : (
                  <Route exact key={path} path={path} component={Error404} />
                );
              } else {
                return (
                  <Route exact key={path} path={path} component={component} />
                );
              }
            })}
          </div>
        </Fragment>
        <Redirect to={"/"} />
      </Switch>
      {/* <Footer>
        Copyright © 2011-{new Date().getFullYear()} {appInfo.title} Inc.
        <br />
        All trademarks or registered trademarks are property of their respective
        owners.
      </Footer> */}
    </SideNavBarLayout>
  );
}
