import DFileUploader from "devextreme-react/file-uploader";

type FileUploaderProps = {
  buttonText: string;
  onValueChanged: (values: File[]) => void;
};

const FileUploader = ({ buttonText, onValueChanged }: FileUploaderProps) => {
  return (
    <DFileUploader
      accept="image/*"
      uploadMode="useForm"
      selectButtonText={buttonText}
      onValueChanged={(e) => e?.value && onValueChanged(e.value)}
    />
  );
};

export default FileUploader;
