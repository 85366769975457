import { Button, Form, LoadPanel } from "devextreme-react";
import { GroupItem, RequiredRule, SimpleItem } from "devextreme-react/form";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { BodyType } from "../../redux/actions";
import { getApiParams } from "../../redux/selectors/auth-selectors";
import {
  getLocalizedComponents,
  getLocalizedErrors,
  getLocalizedOffices,
} from "../../redux/selectors/language-selectors";
import BaseService from "../../services";
import { CounterObject } from "./type";
const Counters = () => {
  const dataRef = useRef<CounterObject>({
    availableloads: 0,
    availabletrucks: 0,
    trucks: 0,
    clients: 0,
    posts: 0,
  });

  const { token, lang } = useSelector(getApiParams);
  const baseService = new BaseService();
  const strings = useSelector(getLocalizedOffices);
  const errorStrings = useSelector(getLocalizedErrors);
  const compstrings = useSelector(getLocalizedComponents);
  const [loader, setLoader] = useState(false);
  const getData = async () => {
    setLoader(true);
    const response: any | null = await baseService.sendRequest(
      "System/GetCounters",
      "GET",
      token,
      lang,
      BodyType.formdata
    );
    dataRef.current = response;
    setLoader(false);
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);
  return (
    <div style={{}}>
      <LoadPanel visible={loader} />
      <form>
        <Form
          key="id"
          labelLocation="top"
          formData={dataRef.current}
          width={"100%"}
          height={"100%"}
          showColonAfterLabel={false}
          validationGroup="officeEditFormValidationGroup"
        >
          <GroupItem colCount={2}>
            <SimpleItem
              editorType={"dxNumberBox"}
              dataField={"availabletrucks"}
              label={{
                text: strings.getString("availabletrucks"),
              }}
            >
              <RequiredRule message={errorStrings.getString("required")} />
            </SimpleItem>
            <SimpleItem
              editorType={"dxNumberBox"}
              dataField={"availableloads"}
              label={{
                text: strings.getString("availableloads"),
              }}
            >
              <RequiredRule message={errorStrings.getString("required")} />
            </SimpleItem>
            <SimpleItem
              dataField={"clients"}
              editorType={"dxNumberBox"}
              label={{
                text: strings.getString("clients"),
              }}
            >
              <RequiredRule message={errorStrings.getString("required")} />
            </SimpleItem>
            <SimpleItem
              editorType={"dxNumberBox"}
              dataField={"posts"}
              label={{
                text: strings.getString("posts"),
              }}
            >
              <RequiredRule message={errorStrings.getString("required")} />
            </SimpleItem>
            <SimpleItem
              dataField={"trucks"}
              editorType={"dxNumberBox"}
              label={{
                text: strings.getString("trucks"),
              }}
            >
              <RequiredRule message={errorStrings.getString("required")} />
            </SimpleItem>
          </GroupItem>
        </Form>
      </form>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          marginBlock: 20,
        }}
      >
        <Button
          className="datagrid-action-button"
          type="default"
          text={compstrings.getString("save")}
          stylingMode="contained"
          onClick={async () => {
            await baseService.sendRequestWithNotify(
              "System/SetCounters",
              "POST",
              token,
              lang,
              {
                _availabletrucks: dataRef.current.availabletrucks,
                _availableloads: dataRef.current.availableloads,
                _trucks: dataRef.current.trucks,
                _clients: dataRef.current.clients,
                _posts: dataRef.current.posts,
              },
              BodyType.formdata
            );
          }}
        />
      </div>
    </div>
  );
};
export default Counters;
