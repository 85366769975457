import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataGrid } from "../../components";
import { getLocalizedTenders } from "../../redux/selectors/language-selectors";

function CompletedTenderPage() {
  const history = useHistory();
  const strings = useSelector(getLocalizedTenders);
  const columns = [
    {
      dataField: "tender_no__c",
      caption: strings.getString("Tender No"),
    },
    {
      dataField: "Opportunity_Tender_Type__c",
      caption: strings.getString("Tender Type"),
    },
    {
      dataField: "Tender_Start_Date__c",
      caption: strings.getString("Tender Start Date"),
      dataType: "date",
    },
    {
      dataField: "Tender_End_Date__c",
      caption: strings.getString("Tender End Date"),
      dataType: "date",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: (e: any) => {
            history.push("/tender/" + e.row.data.sfid);
          },
        },
      ],
    },
  ];
  return (
    <DataGrid
      keyField="sfid"
      url={"Portal/GetTransferCompletedTenders?_skip=0&&_take=1000"}
      columns={columns || []}
      refObject={{}}
    />
  );
}
export default CompletedTenderPage;
