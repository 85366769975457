import getSymbolFromCurrency from "currency-symbol-map";
import { IHtmlEditorOptions } from "devextreme-react/html-editor";
import {
  LanguageDto,
  LanguageModel,
} from "../types/models/system/system-models";

export const disabled = { disabled: true };

export const localizer = (data: number, culture: string): any => {
  const returnData = new Intl.NumberFormat(culture, {
    minimumSignificantDigits: 11,
  }).format(data);
  console.log(returnData);
  return returnData;
};
export const getCurrency = (
  description: string | number | undefined | null,
  currencyCode: string | undefined
): string => {
  if (description && currencyCode)
    return description + " (" + getSymbolFromCurrency(currencyCode) + ")";
  else if (currencyCode)
    return " -(" + getSymbolFromCurrency(currencyCode) + ")";
  else return " -";
};

export function getLanguageDtosValues<Type>(
  languages?: LanguageModel[],
  languageObject: any = {
    Description: "",
    Title: "",
  }
): Type[] | LanguageDto[] {
  let languagesArr: Type[] | LanguageDto[] = [];
  languages?.forEach((language, i) =>
    languagesArr.push({ LanguageID: language.guid, ...languageObject })
  );
  return languagesArr;
}

export const textEditorOptions = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "separator",
      {
        name: "size",
        acceptedValues: ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"],
      },
      {
        name: "font",
        acceptedValues: [
          "Arial",
          "Courier New",
          "Georgia",
          "Impact",
          "Lucida Console",
          "Tahoma",
          "Times New Roman",
          "Verdana",
        ],
      },
      "separator",
      "bold",
      "italic",
      "strike",
      "underline",
      "separator",
      "alignLeft",
      "alignCenter",
      "alignRight",
      "alignJustify",
      "separator",
      "orderedList",
      "bulletList",
      "separator",
      {
        name: "header",
        acceptedValues: [false, 1, 2, 3, 4, 5],
      },
      "separator",
      "color",
      "background",
      "separator",
      "link",
      "image",
      "separator",
      "clear",
      "codeBlock",
      "blockquote",
      "separator",
      "insertTable",
      "deleteTable",
      "insertRowAbove",
      "insertRowBelow",
      "deleteRow",
      "insertColumnLeft",
      "insertColumnRight",
      "deleteColumn",
    ],
  },
  height: 300,
} as IHtmlEditorOptions;
