import { combineReducers } from "redux";

import appReducer from "./app-reducer";
import authReducer from "./auth-reducer";
import languageReducer from "./language-reducer";
import systemReducer from "./system-reducer";

const reducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  locale: languageReducer,
  system: systemReducer,
});

export default reducers;
