import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import { CUSTOM_MAP, GOOGLE_MAPS_API_KEY } from "../../utils/properties";
import { Marker } from "../../types/maps";
import { MapsSearchBox } from "..";
import { getLocalizedComponents } from "../../redux/selectors/language-selectors";

type GoogleMapsProps = {
  lat: number;
  lng: number;
  setLat?: (lat: number) => void;
  setLng?: (lng: number) => void;
  isDetail?: boolean;
};

const GoogleMaps = ({
  lat,
  lng,
  setLat,
  setLng,
  isDetail = false,
}: GoogleMapsProps) => {
  const strings = useSelector(getLocalizedComponents);
  const markerRef = useRef<Marker>();
  const googleMap = useRef<google.maps.Map>();
  const [googleMaps, setGoogleMaps] = useState<any>(null);
  const [apiReady, setApiReady] = useState<boolean>(false);

  const handleApiLoaded = ({
    map,
    maps,
  }: {
    map: any;
    maps: any;
    ref: Element | null;
  }) => {
    if (!isDetail) {
      if (lat && lng) {
        let myLatlng = new maps.LatLng(lat, lng);
        addMarker(myLatlng, map, maps);
      }
      map.addListener("click", function (event: any) {
        markerRef.current?.setMap(null);
        setLat && setLat(event.latLng.lat());
        setLng && setLng(event.latLng.lng());
        addMarker(event.latLng, map, maps);
      });
    } else {
      let myLatlng = new maps.LatLng(lat, lng);
      addMarker(myLatlng, map, maps);
    }
    googleMap.current = map;
    setGoogleMaps(maps);
    setApiReady(true);
  };

  function handleEvent(event: any) {
    setLat && setLat(event.latLng.lat());
    setLng && setLng(event.latLng.lng());
  }

  function addMarker(latlng: any, map: any, maps: any) {
    const newMarker = new maps.Marker({
      position: latlng,
      map: map,
      draggable: true,
    });
    markerRef.current = newMarker;
    if (!isDetail) {
      newMarker.addListener("drag", handleEvent);
      newMarker.addListener("dragend", handleEvent);
    }
  }

  return (
    <>
      <div
        style={{
          width: 250,
          position: "absolute",
          zIndex: 1,
          marginBlockStart: 10,
          marginInlineStart: 10,
        }}
      >
        {apiReady && (
          <MapsSearchBox
            placeholder={strings.getString("search")}
            onPlacesChanged={(e: any) => {
              e &&
                e[0] &&
                googleMap?.current?.panTo({
                  lat: e[0].geometry.location.lat(),
                  lng: e[0].geometry.location.lng(),
                });
            }}
            maps={googleMaps}
          />
        )}
      </div>
      <div style={{ height: 400, width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: GOOGLE_MAPS_API_KEY,
            libraries: ["places"],
          }}
          //@ts-ignore
          options={{ styles: CUSTOM_MAP }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded}
          center={
            lat && lng
              ? {
                  lat: parseFloat(lat.toString().replace(",", ".")),
                  lng: parseFloat(lng.toString().replace(",", ".")),
                }
              : { lat: 41, lng: 29 }
          }
          zoom={12}
        />
      </div>
    </>
  );
};

export default GoogleMaps;
