import Form, { GroupItem, SimpleItem } from "devextreme-react/form";
import { useSelector } from "react-redux";
import { getLocalizedBlogs } from "../../redux/selectors/language-selectors";
import { BlogDetailModel } from "../../types/models/blogs/blog-model";
import { disabled } from "../../utils/helpers";
import { MultiLanguageInput } from "../../components";
import { imageUrl } from "../../utils/urls";
import Modal from "../../components/modal";
import { useState } from "react";

type EditArticleFromType = {
  data: BlogDetailModel;
};

const DetailsForm = ({ data }: EditArticleFromType) => {
  const strings = useSelector(getLocalizedBlogs);
  const [showImage, setShowImage] = useState<boolean>(false);
  const [showBigImage, setShowBigImage] = useState<boolean>(false);
  return (
    <form>
      <Form
        key="id"
        labelLocation="top"
        formData={data}
        showColonAfterLabel={true}
        validationGroup="editionFormValidation"
      >
        <GroupItem>
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="SmallImage"
              label={{
                text: strings.getString("small_image"),
              }}
            >
              <img
                src={imageUrl + data.SmallImage}
                width="100%"
                alt=""
                onClick={() => setShowImage(true)}
              />
              <Modal
                visible={showImage}
                onHiding={() => setShowImage(false)}
                title={strings.getString("small_image")}
                height={"80%"}
                width={"95%"}
                onSubmitClick={() => console.log("")}
                showButton={false}
                coverModal
              >
                <div className="centerer-div full-element">
                  <img
                    src={imageUrl + data.SmallImage}
                    className="image-style"
                    alt=""
                    onClick={() => setShowBigImage(true)}
                  />
                </div>
              </Modal>
            </SimpleItem>
            <SimpleItem
              dataField="BigImage"
              label={{
                text: strings.getString("big_image"),
              }}
            >
              <img
                src={imageUrl + data.BigImage}
                width="100%"
                alt=""
                onClick={() => setShowBigImage(true)}
              />
              <Modal
                visible={showBigImage}
                onHiding={() => setShowBigImage(false)}
                title={strings.getString("big_image")}
                height={"80%"}
                width={"95%"}
                onSubmitClick={() => console.log("")}
                showButton={false}
                coverModal
              >
                <div className="centerer-div full-element">
                  <img
                    src={imageUrl + data.BigImage}
                    className="image-style"
                    alt=""
                  />
                </div>
              </Modal>
            </SimpleItem>
          </GroupItem>
          <SimpleItem
            editorOptions={disabled}
            dataField="Owner"
            label={{
              text: strings.getString("owner"),
            }}
          />
          <SimpleItem
            editorOptions={disabled}
            dataField="CreateDate"
            label={{
              text: strings.getString("date"),
            }}
            editorType="dxDateBox"
          />
          <SimpleItem>
            <MultiLanguageInput
              disabled
              data={data}
              languageObject={{
                mainKey: "BlogTranslates",
                objectKeys: [
                  { title: strings.getString("title"), key: "Title" },
                  {
                    title: strings.getString("short_description"),
                    key: "ShortDescription",
                  },
                  {
                    title: strings.getString("description"),
                    key: "Description",
                    editorType: "dxHtmlEditor",
                  },
                ],
              }}
              getLanguageObject={{
                Description: "",
                ShortDescription: "",
                Title: "",
              }}
            />
          </SimpleItem>
        </GroupItem>
      </Form>
    </form>
  );
};

export default DetailsForm;
