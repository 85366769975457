export interface SagaGenericParams<Type> {
  type: string;
  payload: Type;
  url?: string;
}

export interface ApiResponse<ResultType = null, SubResultType = null> {
  Result: ResultType;
  SubResult: SubResultType;
  ResultStatus: number;
  UniqueID: number;
  DetailID: number;
  Message: string;
  ProcessTime: string;
  StatusCode: number;
}
export interface ApiCallback<PayloadType = never>
  extends ApiSuccess<PayloadType>,
    ApiError {}
export interface ApiSuccess<PayloadType = never> {
  onSuccess: (message: string, payload?: PayloadType) => void;
}
export interface ApiError {
  onError: (message: string) => void;
}

export const verfiyResponse = (response?: ApiResponse<any, any>): boolean => {
  switch (response?.ResultStatus) {
    case 0: // NoRecord = 0
    case 1: //NewRecordAdded = 1
    case 2: //RecordUpdated = 2
    case 3: //RecordDeleted = 3
    case 9: //QuerySuccess = 9
    case 17: //ExpiredLoginAllowed = 17
    case 19: //PasswordChanged = 19
      return true;
    case 4: //ErrorOccured = 4
    case 5: //WrongLogin = 5
    case 6: //SystemErrorOccured = 6
    case 7: //SameRecordNotAdded = 7
    case 8: //CitizenUINValidationError = 8
    case 10: //QueryError = 10
    case 11: //Unauthorized = 11
    case 12: //CantDeleteHasSubRows = 12
    case 13: //CantDeleteImage = 13
    case 14: //CantUpdateImage = 14
    case 15: //SessionTimeout = 15
    case 16: //PackageExpired = 16
    default:
      return false;
  }
};
