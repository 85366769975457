import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  ApiCallback,
  ApiResponse,
  SagaGenericParams,
  verfiyResponse,
} from "../../types/models/base-model";
import { SliderModel } from "../../types/models/sliders/slider-object";
import { createRequestSaga } from "../actions";
import { hideLoader, showLoader } from "../actions/app-actions";
import * as A from "../actions/static-actions/slider-actions";

function* getListSaga<Type>({
  payload,
  url,
}: SagaGenericParams<ApiCallback<Type>>) {
  console.log("generic saga", payload, url);
  yield put(showLoader());
  try {
    const response: ApiResponse<Type> = yield call(createRequestSaga, {
      method: "GET",
      url: url || "",
    });
    if (verfiyResponse(response))
      payload.onSuccess(response.Message, response.Result);
    else payload.onError(response.Message);
  } catch (e) {
    console.log("ERROR getListSaga", e);
  } finally {
    yield put(hideLoader());
  }
}

const genericSaga = [
  takeLatest(A.getSliders.toString(), (params: any) =>
    getListSaga<SliderModel[]>(params)
  ),
];

export default genericSaga;
