import { useSelector } from "react-redux";
import { DataGrid } from "../../components";
import { getLocalizedComponents } from "../../redux/selectors/language-selectors";
import { languagesSelector } from "../../redux/selectors/system-selectors";
import { getLanguageDtosValues } from "../../utils/helpers";
import {
  addSSSUrl,
  deleteSSSUrl,
  getSSSDetailsUrl,
  getSSSUrl,
  updateSSSUrl,
} from "../../utils/urls";
import CreateForm from "./create";
import DetailsForm from "./details";
import EditForm from "./edit";

function SSSPage() {
  const strings = useSelector(getLocalizedComponents);
  const languages = useSelector(languagesSelector);
  const columns = [
    {
      dataField: "question",
      caption: strings.getString("question"),
    },
    {
      dataField: "reply",
      caption: strings.getString("reply"),
    },
  ];
  return (
    <DataGrid
      url={getSSSUrl}
      columns={columns || []}
      refObject={{
        SSSTranslates: getLanguageDtosValues(languages, {
          Question: "",
          Reply: "",
        }),
      }}
      details={{
        height: "40%",
        width: "30%",
        getByIdUrl: getSSSDetailsUrl,
        title: strings.getString("sssdetails"),
        component: DetailsForm,
      }}
      create={{
        height: "40%",
        width: "30%",
        createUrl: addSSSUrl,
        title: strings.getString("addsss"),
        component: CreateForm,
        validationGroup: "createSSSvalidation",
      }}
      edit={{
        height: "40%",
        width: "30%",
        getByIdUrl: getSSSDetailsUrl,
        editUrl: updateSSSUrl,
        title: strings.getString("editsss"),
        component: EditForm,
        validationGroup: "editSSSvalidation",
      }}
      remove={{ url: deleteSSSUrl }}
    />
  );
}
export default SSSPage;
