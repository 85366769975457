//import "devextreme/dist/css/dx.carmine.compact.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import { HashRouter as Router, useLocation } from "react-router-dom";
import "./dx-styles.scss";
import LoadPanel from "devextreme-react/load-panel";
import { NavigationProvider } from "./contexts/navigation";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import UnauthenticatedContent from "./UnauthenticatedContent";

import { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import reduxStore from "./redux/index";
import { PersistGate } from "redux-persist/integration/react";
import { isLoading } from "./redux/selectors/app-selectors";
import { loggedSelector } from "./redux/selectors/auth-selectors";
import "react-toastify/dist/ReactToastify.css";
import enMessages from "devextreme/localization/messages/en.json";
import trMessages from "devextreme/localization/messages/tr.json";
import { locale as devexLocale, loadMessages } from "devextreme/localization";
import {
  languageSelector,
  languagesSelector,
} from "./redux/selectors/system-selectors";
import { setLanguage } from "./redux/actions/system-actions";

const { store, persistor } = reduxStore();

function App() {
  const language = useSelector(languageSelector);
  const languages = useSelector(languagesSelector);
  const loading = useSelector(isLoading);
  const location = useLocation();
  const dispatch = useDispatch();
  const loggedIn = useSelector(loggedSelector);
  const getLanguageFunc = async () => {
    const array = new URLSearchParams(location.search);
    const lang = array.get("lang");
    if (lang && languages) {
      const currentlang = languages.find((x) => x.culture.includes(lang));
      currentlang && (await dispatch(setLanguage(currentlang)));
    }
  };
  useEffect(() => {
    loadMessages(enMessages);
    loadMessages(trMessages);
    getLanguageFunc();
    if (language) devexLocale(language.flag); // eslint-disable-next-line
  }, [language]);

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (loggedIn) {
    return (
      <>
        <Content />
      </>
    );
  }

  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <NavigationProvider>
            <div className={`app ${screenSizeClass}`}>
              <App />
            </div>
          </NavigationProvider>
        </PersistGate>
      </Provider>
    </Router>
  );
}
