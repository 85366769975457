export const baseUrl = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "https://api.rapidloadboard.com/";
export const imageUrl = process.env.REACT_APP_IMAGE_URL
  ? process.env.REACT_APP_IMAGE_URL
  : "https://api.rapidloadboard.com/";

export const salesforceUrl = process.env.REACT_APP_SALEFORCE_URL
  ? process.env.REACT_APP_SALEFORCE_URL
  : "https://rapid.lightning.force.com/lightning/r/Account/";

export const chatUrl = "https://api.rapidloadboard.com/";
/** ARTICLES **/
export const getArticlesUrl = "Portal/GetArticles";
export const getArticleDetailsUrl = "Portal/GetArticleDetails";
export const addArticleUrl = "Portal/AddArticle";
export const updateArticleUrl = "Portal/UpdateArticle";
export const deleteArticleUrl = "Portal/DeleteArticle";

/**BLOGS **/
export const getBlogsUrl = "Portal/GetBlogs";
export const getBlogsDetailsUrl = "Portal/GetBlogDetails";
export const addBlogsUrl = "Portal/AddBlog";
export const updateBlogsUrl = "Portal/UpdateBlog";
export const deleteBlogsUrl = "Portal/DeleteBlog";

/* OFFICES*/
export const getOfficesUrl = "Portal/GetOffices";
export const getOfficeDetailsUrl = "Portal/GetOfficeDetails";
export const addOfficeUrl = "Portal/AddOffice";
export const updateOfficeUrl = "Portal/UpdateOffice";
export const deleteOfficeUrl = "Portal/DeleteOffice";

/** SLIDERS **/
export const getSlidersUrl = "Portal/GetSliders";
export const getSliderDetailsUrl = "Portal/GetSliderDetails";
export const addSliderUrl = "Portal/AddSlider";
export const updateSliderUrl = "Portal/UpdateSlider";
export const deleteSliderUrl = "Portal/DeleteSlider";

/** USERS **/
export const getPortalUsersUrl = "Portal/GetPortalUsers";
export const addPortalUsersUrl = "Portal/AddPortalUser";
export const updatePortalUsersUrl = "Portal/UpdatePortalUser";
export const deletePortalUsersUrl = "Portal/DeletePortalUser";

/* SSS*/
export const getSSSUrl = "Portal/GetSSS";
export const getSSSDetailsUrl = "Portal/GetSSSDetails";
export const addSSSUrl = "Portal/AddSSS";
export const updateSSSUrl = "Portal/UpdateSSS";
export const deleteSSSUrl = "Portal/DeleteSSS";
