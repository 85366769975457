import { withNavigationWatcher } from "./contexts/navigation";
import {
  HomePage,
  TasksPage,
  DashboardPage,
  SlidersPage,
  ArticlesPage,
  CounterPage,
} from "./pages";
import BlogsPage from "./pages/blogs";
import Chat from "./pages/chat";
import IncDescCompletedTenderPage from "./pages/inc-desc-tenders/CompletedTenders";
import IncDescAuctionDetailPage from "./pages/inc-desc-tenders/Details";
import IncDescWaitingTenderPage from "./pages/inc-desc-tenders/WaitingTenders";
import Logout from "./pages/logout";
import OfficesPage from "./pages/offices";
import SocialMediaPage from "./pages/socialmedia";
import SSSPage from "./pages/sss";
import TenderChat from "./pages/tender-chat";
import CompletedTenderPage from "./pages/tenders/CompletedTenders";
import AuctionDetailPage from "./pages/tenders/Details";
import WaitingTenderPage from "./pages/tenders/WaitingTenders";
import UsersPage from "./pages/users";
import VehicleChat from "./pages/vehicle-chat";

const routes = [
  {
    path: "/",
    component: DashboardPage,
  },
  {
    auth: ["Yönetici"],
    path: "/sliders",
    component: SlidersPage,
  },
  {
    auth: ["MT", "MT-AT", "Yönetici"],
    path: "/chat",
    component: Chat,
  },
  {
    auth: ["MT", "MT-AT", "Yönetici"],
    path: "/tender-chat",
    component: TenderChat,
  },
  {
    auth: ["AT", "MT-AT", "Yönetici"],
    path: "/vehiclechat",
    component: VehicleChat,
  },
  {
    auth: ["Yönetici"],
    path: "/counters",
    component: CounterPage,
  },
  {
    auth: ["Yönetici"],
    path: "/#/logout",
    component: Logout,
  },
  {
    auth: ["Yönetici"],
    path: "/socialmedia",
    component: SocialMediaPage,
  },
  {
    auth: ["Yönetici"],
    path: "/blogs",
    component: BlogsPage,
  },
  {
    auth: ["Yönetici"],
    path: "/sss",
    component: SSSPage,
  },
  {
    auth: ["Yönetici"],
    path: "/offices",
    component: OfficesPage,
  },
  {
    auth: ["Yönetici"],
    path: "/articles",
    component: ArticlesPage,
  },
  {
    auth: ["Yönetici"],
    path: "/users",
    component: UsersPage,
  },
  {
    auth: ["Yönetici"],
    path: "/tasks",
    component: TasksPage,
  },
  {
    auth: ["Yönetici"],
    path: "/home",
    component: HomePage,
  },
  {
    auth: ["Yönetici"],
    path: "/inc-desc-waiting-tenders",
    component: IncDescWaitingTenderPage,
  },
  {
    auth: ["Yönetici"],
    path: "/inc-desc-completed-tenders",
    component: IncDescCompletedTenderPage,
  },
  {
    auth: ["Yönetici"],
    path: "/waiting-tenders",
    component: WaitingTenderPage,
  },
  {
    auth: ["Yönetici"],
    path: "/completed-tenders",
    component: CompletedTenderPage,
  },
  {
    auth: ["Yönetici"],
    path: "/tender/:id",
    component: AuctionDetailPage,
  },
  {
    auth: ["Yönetici"],
    path: "/inc-desc-tender/:id",
    component: IncDescAuctionDetailPage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
