import { useSelector } from "react-redux";
import { DataGrid } from "../../components";
import { getLocalizedBlogs } from "../../redux/selectors/language-selectors";
import { languagesSelector } from "../../redux/selectors/system-selectors";
import { BlogDetailModel } from "../../types/models/blogs/blog-model";
import { getLanguageDtosValues } from "../../utils/helpers";
import {
  addBlogsUrl,
  deleteBlogsUrl,
  getBlogsDetailsUrl,
  getBlogsUrl,
  updateBlogsUrl,
} from "../../utils/urls";

import CreateBlogForm from "./create";
import DetailsForm from "./details";
import EditBlogForm from "./edit";

function BlogsPage() {
  const strings = useSelector(getLocalizedBlogs);
  const languages = useSelector(languagesSelector);

  const columns = [
    {
      dataField: "owner",
      caption: strings.getString("owner"),
    },
    {
      dataField: "title",
      caption: strings.getString("title"),
    },
    {
      dataField: "date",
      caption: strings.getString("date"),
      dataType: "date",
    },
  ];

  return (
    <DataGrid
      url={getBlogsUrl}
      columns={columns || []}
      refObject={
        {
          SmallImage: "",
          Owner: "",
          BigImage: "",
          CreateDate: "",
          BlogTranslates: getLanguageDtosValues(languages, {
            Description: "",
            ShortDescription: "",
            Title: "",
          }),
        } as BlogDetailModel
      }
      details={{
        height: "95%",
        getByIdUrl: getBlogsDetailsUrl,
        title: strings.getString("blog_details"),
        component: DetailsForm,
      }}
      create={{
        height: "95%",
        createUrl: addBlogsUrl,
        title: strings.getString("add_blog"),
        component: CreateBlogForm,
        validationGroup: "blogCreateFormValidation",
      }}
      edit={{
        height: "95%",
        getByIdUrl: getBlogsDetailsUrl,
        editUrl: updateBlogsUrl,
        title: strings.getString("edit_blog"),
        component: EditBlogForm,
        validationGroup: "blogEditFormValidation",
      }}
      remove={{ url: deleteBlogsUrl }}
    />
  );
}
export default BlogsPage;
