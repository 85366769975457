import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import UserPanel from "../user-panel/user-panel";
import LanguageSelector from "../language-selector";
import "./header.scss";
import { Template } from "devextreme-react/core/template";
import { ClickEvent } from "devextreme/ui/button";

type HeaderProps = {
  menuToggleEnabled: boolean;
  title?: string;
  className?: string;
  toggleMenu: (e: ClickEvent) => void;
};

export default function Header({
  menuToggleEnabled,
  title,
  toggleMenu,
}: HeaderProps) {
  return (
    <header className={"header-component"}>
      <Toolbar className={"header-toolbar"} style={{ paddingBlock: 10 }}>
        <Item
          visible={menuToggleEnabled}
          location={"before"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item
          location={"before"}
          cssClass={"header-title"}
          text={title}
          visible={!!title}
        />
        <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"languageSelectorTemplate"}
        >
          <Button
            className={"language-button authorization"}
            width={100}
            height={"100%"}
            stylingMode={"text"}
          >
            <LanguageSelector menuMode={"context"} />
          </Button>
        </Item>
        <Template name={"languageSelectorTemplate"}>
          <LanguageSelector menuMode={"list"} />
        </Template>
        <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"userPanelTemplate"}
        >
          <Button
            className={"user-button authorization"}
            width={150}
            height={"100%"}
            stylingMode={"text"}
          >
            <UserPanel menuMode={"context"} />
          </Button>
        </Item>
      </Toolbar>
    </header>
  );
}
