import { createSelector } from "reselect";
import { RootState } from "../reducers/reducerTypes";

const languageState = (state: RootState) => state.system.lang;
export const languageSelector = createSelector(
  [languageState],
  (state) => state
);

const languagesState = (state: RootState) => state.system.languages;
export const languagesSelector = createSelector(
  [languagesState],
  (state) => state
);
