import LocalizedStrings, {
  GlobalStrings,
  LocalizedStringsMethods,
} from "react-localization";
import { createSelector } from "reselect";
import { RootState } from "../reducers/reducerTypes";

const getUserLanguageState = (state: RootState) => state.system.lang.culture;
export const userLanguageState = createSelector(
  [getUserLanguageState],
  (lang) => lang
);

const returnLocolaziedString = (
  locale: GlobalStrings<string>,
  lang?: string
): LocalizedStringsMethods => {
  const l = new LocalizedStrings(locale);
  if (lang) l.setLanguage(lang === "us" ? "en" : lang);
  return l;
};

const getLocaleArticles = (state: RootState) => state.locale.articles;
export const getLocalizedArticles = createSelector(
  [getLocaleArticles, getUserLanguageState],
  (locale, lang) => returnLocolaziedString(locale, lang)
);
const getLocaleTenders = (state: RootState) => state.locale.tenders;
export const getLocalizedTenders = createSelector(
  [getLocaleTenders, getUserLanguageState],
  (locale, lang) => returnLocolaziedString(locale, lang)
);

const getLocaleAuth = (state: RootState) => state.locale.auth;
export const getLocalizedAuth = createSelector(
  [getLocaleAuth, getUserLanguageState],
  (locale, lang) => returnLocolaziedString(locale, lang)
);

const getLocaleBlogs = (state: RootState) => state.locale.blogs;
export const getLocalizedBlogs = createSelector(
  [getLocaleBlogs, getUserLanguageState],
  (locale, lang) => returnLocolaziedString(locale, lang)
);

const getLocaleComponents = (state: RootState) => state.locale.components;
export const getLocalizedComponents = createSelector(
  [getLocaleComponents, getUserLanguageState],
  (locale, lang) => returnLocolaziedString(locale, lang)
);

const getLocaleErrors = (state: RootState) => state.locale.errors;
export const getLocalizedErrors = createSelector(
  [getLocaleErrors, getUserLanguageState],
  (locale, lang) => returnLocolaziedString(locale, lang)
);

const getLocaleMenu = (state: RootState) => state.locale.menu;
export const getLocalizedMenu = createSelector(
  [getLocaleMenu, getUserLanguageState],
  (locale, lang) => returnLocolaziedString(locale, lang)
);

const getLocaleOffices = (state: RootState) => state.locale.offices;
export const getLocalizedOffices = createSelector(
  [getLocaleOffices, getUserLanguageState],
  (locale, lang) => returnLocolaziedString(locale, lang)
);

const getLocaleSliders = (state: RootState) => state.locale.sliders;
export const getLocalizedSliders = createSelector(
  [getLocaleSliders, getUserLanguageState],
  (locale, lang) => returnLocolaziedString(locale, lang)
);

const getLocaleUsers = (state: RootState) => state.locale.users;
export const getLocalizedUsers = createSelector(
  [getLocaleUsers, getUserLanguageState],
  (locale, lang) => returnLocolaziedString(locale, lang)
);
